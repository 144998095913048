import React, { useState, useEffect, forwardRef, createRef } from 'react'
import { toast } from 'react-toastify'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Select from 'react-select'
import Chart from "react-google-charts"
import { useTranslation } from 'react-i18next'
import { FiCalendar, FiInfo } from 'react-icons/fi'
import { FaRegFilePdf } from 'react-icons/fa'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import ReactToPdf from 'react-to-pdf'

import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'

import api from '../../services/api'

import '../../styles/pages/app/Dashboard.css'

const Dashboard = () => {
   const [loading, setLoading] = useState(true)

   const [filterTasks, setFilterTasks] = useState('myTasks')
   const [filterTime, setFilterTime] = useState('today')

   const [startDate, setStartDate] = useState(new Date());
   const [chosenDate, setChosenDate] = useState(new Date());

   const [month, setMonth] = useState(moment(new Date()).month())
   const [year, setYear] = useState(moment(new Date()).year())

   const [dataStatus, setDataStatus] = useState([])
   const [dataStatusColor, setDataStatusColor] = useState([])
   const [status, setStatus] = useState('')

   const [percentageNow, setPercentageNow] = useState('')
   const [totalTasksNow, setTotalTasksNow] = useState('')
   const [totalTasksInGoGoGoNow, setTotalTasksInGoGoGoNow] = useState('')
   const [percentageImportant, setPercentageImportant] = useState('')
   const [totalTasksImportant, setTotalTasksImportant] = useState('')
   const [totalTasksInGoGoGoImportant, setTotalTasksInGoGoGoImportant] = useState('')
   const [percentageRegular, setPercentageRegular] = useState('')
   const [totalTasksRegular, setTotalTasksRegular] = useState('')
   const [totalTasksInGoGoGoRegular, setTotalTasksInGoGoGoRegular] = useState('')

   const [totalTasksCompletedDay, setTotalTasksCompletedDay] = useState('')
   const [totalTasksCompletedMonth, setTotalTasksCompletedMonth] = useState('')

   const [t] = useTranslation()

   const printRef = createRef()

   const chosenDateFormated = moment(chosenDate).format('YYYY-MM-DD')
   const chosenMonthFormated = month < 10 ? `0${month + 1}` : month + 1

   const dataChart = [
      ['status', 'result'],
      ...dataStatus
   ]

   const months = [
      t('dashboard.months.jan'),
      t('dashboard.months.feb'),
      t('dashboard.months.mar'),
      t('dashboard.months.apr'),
      t('dashboard.months.may'),
      t('dashboard.months.jun'),
      t('dashboard.months.jul'),
      t('dashboard.months.aug'),
      t('dashboard.months.sep'),
      t('dashboard.months.oct'),
      t('dashboard.months.nov'),
      t('dashboard.months.dec')
   ]

   const filterTasksItems = [
      { label: t('dashboard.myTasks'), value: 'myTasks' },
      { label: t('dashboard.myPrivateTasks'), value: 'myPrivateTasks' },
      { label: t('dashboard.myTasksOnTeams'), value: 'myTasksOnTeams' }
   ]

   const customSelectStyles = {
      option: (provided, state) => ({
         ...provided,
         padding: 20,
         color: state.isSelected ? '#FFF' : '#000',
         backgroundColor: state.isSelected ? '#6B2722' : '#FFF',
         borderColor: state.isSelected ? '#6B2722' : '#FFF',
         borderWidth: 2,
         borderStyle: 'solid',
         "&:hover": {
            borderColor: state.isFocused ? '#6B2722' : '#FFF',
            borderWidth: state.isFocused ? 2 : 2,
         },
      }),
      control: () => ({
         width: "100%",
      }),
      singleValue: (provided, state) => {
         const opacity = state.isDisabled ? 0.5 : 1;
         const transition = 'opacity 300ms';

         return { ...provided, opacity, transition };
      }
   }

   useEffect(() => {
      async function loadDataAllMyStatus() {
         if (filterTime === 'today' && chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasks') {
            const res = await api.get(`myStatusDaily`)

            setStatus(res.data[0].name)

         } else if (filterTime === 'today' && chosenDateFormated !== moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasks') {
            try {
               const res = await api.get(`myStatusPerPeriod?specificDate=${chosenDateFormated}`)

               setStatus(res.data[0].name)

            } catch (e) {
               setStatus('-')

               e.response.data.errors.forEach((error => toast.error(error)))
            }

         } else if (filterTime !== 'today' && filterTasks === 'myTasks') {
            const res = await api.get(`myStatusPerPeriod?${filterTime}=${month}&year=${year}`)

            setDataStatusColor(res.data.map(color => color.backgroundColor))
            setDataStatus(res.data.map(status => {
               return [status.name, status.result]
            }))

         } else if (filterTime === 'today' && chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myPrivateTasks') {
            const res = await api.get(`myStatusPrivateDaily`)

            setStatus(res.data[0].name)

         } else if (filterTime === 'today' && chosenDateFormated !== moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myPrivateTasks') {
            try {
               const res = await api.get(`myStatusPrivatePerPeriod?specificDate=${chosenDateFormated}`)

               setStatus(res.data[0].name)

            } catch (e) {
               setStatus('-')

               e.response.data.errors.forEach((error => toast.error(error)))
            }

         } else if (filterTime !== 'today' && filterTasks === 'myPrivateTasks') {
            const res = await api.get(`myStatusPrivatePerPeriod?${filterTime}=${month}&year=${year}`)

            setDataStatusColor(res.data.map(color => color.backgroundColor))
            setDataStatus(res.data.map(status => {
               return [status.name, status.result]
            }))

         } else if (filterTime === 'today' && chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`myStatusNotPrivateDaily`)

            setStatus(res.data[0].name)

         } else if (filterTime === 'today' && chosenDateFormated !== moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasksOnTeams') {
            try {
               const res = await api.get(`myStatusNotPrivatePerPeriod?specificDate=${chosenDateFormated}`)

               setStatus(res.data[0].name)

            } catch (e) {
               setStatus('-')

               e.response.data.errors.forEach((error => toast.error(error)))
            }

         } else if (filterTime !== 'today' && filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`myStatusNotPrivatePerPeriod?${filterTime}=${month}&year=${year}`)

            setDataStatusColor(res.data.map(color => color.backgroundColor))
            setDataStatus(res.data.map(status => {
               return [status.name, status.result]
            }))
         }

         setLoading(false)
      }

      async function loadDataAllMyTasks() {
         if (filterTime === 'today' && chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasks') {
            const res = await api.get(`myTasksToday`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime === 'today' && chosenDateFormated !== moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasks') {
            const res = await api.get(`myTasksPerPeriod?specificDate=${chosenDateFormated}`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime !== 'today' && filterTasks === 'myTasks') {
            const res = await api.get(`myTasksPerPeriod?${filterTime}=${month}&year=${year}`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime === 'today' && chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myPrivateTasks') {
            const res = await api.get(`myTasksPrivateToday`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime === 'today' && chosenDateFormated !== moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myPrivateTasks') {
            const res = await api.get(`myTasksPrivatePerPeriod?specificDate=${chosenDateFormated}`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime !== 'today' && filterTasks === 'myPrivateTasks') {
            const res = await api.get(`myTasksPrivatePerPeriod?${filterTime}=${month}&year=${year}`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime === 'today' && chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`myTasksTeamsToday`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime === 'today' && chosenDateFormated !== moment(new Date()).format('YYYY-MM-DD') && filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`myTasksTeamsPerPeriod?specificDate=${chosenDateFormated}`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)

         } else if (filterTime !== 'today' && filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`myTasksTeamsPerPeriod?${filterTime}=${month}&year=${year}`)

            setPercentageNow(res.data.percentageNow)
            setTotalTasksNow(res.data.totalTasksNow)
            setTotalTasksInGoGoGoNow(res.data.totalTasksInGoGoGoNow)
            setPercentageImportant(res.data.percentageImportant)
            setTotalTasksImportant(res.data.totalTasksImportant)
            setTotalTasksInGoGoGoImportant(res.data.totalTasksInGoGoGoImportant)
            setPercentageRegular(res.data.percentageRegular)
            setTotalTasksRegular(res.data.totalTasksRegular)
            setTotalTasksInGoGoGoRegular(res.data.totalTasksInGoGoGoRegular)
         }

         setLoading(false)
      }

      loadDataAllMyTasks()
      loadDataAllMyStatus()

   }, [filterTime, filterTasks, chosenDateFormated, month, year])

   useEffect(() => {
      async function loadQuantityTasksCompletedDays() {

         if (filterTasks === 'myTasks') {
            const res = await api.get(`tasksCompletedQuantityDays?completedDate=${chosenDateFormated}`)

            setTotalTasksCompletedDay(res.data.total)

         } else if (filterTasks === 'myPrivateTasks') {
            const res = await api.get(`tasksCompletedQuantityDays?completedDate=${chosenDateFormated}&private=true`)

            setTotalTasksCompletedDay(res.data.total)

         } else if (filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`tasksCompletedQuantityDays?completedDate=${chosenDateFormated}&private=false`)

            setTotalTasksCompletedDay(res.data.total)
         }
      }

      loadQuantityTasksCompletedDays()

   }, [filterTasks, chosenDateFormated])

   useEffect(() => {
      async function loadQuantityTasksCompletedMonth() {
         if (filterTasks === 'myTasks') {
            const res = await api.get(`tasksCompletedQuantityMonth?completedDate=${chosenMonthFormated}`)

            setTotalTasksCompletedMonth(res.data.total)

         } else if (filterTasks === 'myPrivateTasks') {
            const res = await api.get(`tasksCompletedQuantityMonth?completedDate=${chosenMonthFormated}&private=true`)

            setTotalTasksCompletedMonth(res.data.total)

         } else if (filterTasks === 'myTasksOnTeams') {
            const res = await api.get(`tasksCompletedQuantityMonth?completedDate=${chosenMonthFormated}&private=false`)

            setTotalTasksCompletedMonth(res.data.total)
         }
      }

      loadQuantityTasksCompletedMonth()

   }, [filterTasks, chosenMonthFormated])


   function handleFilterToday() {
      setFilterTime('today')
   }

   function handleFilterMonth() {
      setFilterTime('month')
   }

   const onChangeDatePickerDay = (selectedDate) => {
      const currentDate = selectedDate || new Date()
      const dateMillis = currentDate.valueOf()

      setStartDate(selectedDate)
      setChosenDate(moment(dateMillis))
   }

   const DayCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            onClick={onClick}
            ref={ref}
         >
            <div onClick={handleFilterToday} className={filterTime === 'today' ? 'filter-period-selected' : 'filter-period'}>
               <FiCalendar color={filterTime === 'today' ? '#000' : '#BDBDBD'} size={15} />
               <span>{chosenDateFormated === moment(new Date()).format('YYYY-MM-DD') ? t('dashboard.today') : moment(chosenDateFormated).format('DD/MMM')}</span>
            </div>
         </button>
      )
   })


   const onChangeDatePickerMonth = (selectedDate) => {
      const currentDate = selectedDate || new Date()
      const dateMillis = currentDate.valueOf()

      setStartDate(selectedDate)
      setMonth(moment(dateMillis).month())
      setYear(moment(dateMillis).year())
   }

   const MonthCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            onClick={onClick}
            ref={ref}
         >
            <div onClick={handleFilterMonth} className={filterTime === 'month' ? 'filter-period-selected' : 'filter-period'}>
               <FiCalendar color={filterTime === 'month' ? '#000' : '#BDBDBD'} size={15} />
               <span>{t('dashboard.month')} - {months[month]}</span>
            </div>
         </button>
      )
   })

   return (
      <div id='page-dashboard'>
         <PageHeader />

         <div className='container'>
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <>
                  <div className="header">
                     <h1>{t('dashboard.title')}</h1>

                     <ReactToPdf
                        targetRef={printRef}
                        filename="my-dashboard.pdf"
                        x={25}
                        y={10}
                        scale={0.9}
                     >
                        {({ toPdf }) => (
                           <button className='pdf-button' onClick={toPdf}>
                              <FaRegFilePdf size={22} color='#6B2722' />
                           </button>
                        )}
                     </ReactToPdf>
                  </div>

                  <main ref={printRef}>
                     <div className='filters-container'>

                        <DatePicker
                           selected={startDate}
                           onChange={onChangeDatePickerDay}
                           customInput={<DayCustomButton />}
                           showPopperArrow={false}
                           todayButton={t('dashboard.today')}
                           maxDate={new Date()}
                        />

                        <DatePicker
                           selected={startDate}
                           onChange={onChangeDatePickerMonth}
                           customInput={<MonthCustomButton />}
                           showMonthYearPicker
                           showFullMonthYearPicker
                           showTwoColumnMonthYearPicker
                           showPopperArrow={false}
                           todayButton={t('dashboard.thisMonth')}
                           maxDate={new Date()}
                        />

                        <div className="select-tasks">
                           <Select
                              isSearchable={false}
                              styles={customSelectStyles}
                              options={filterTasksItems}
                              className='react-select-container'
                              classNamePrefix="react-select"
                              name="filterTasks"
                              id="filterTasks"
                              placeholder={
                                 filterTasks === 'myTasks' ? t('dashboard.myTasks') :
                                    filterTasks === 'myPrivateTasks' ? t('dashboard.myPrivateTasks') :
                                       filterTasks === 'myTasksOnTeams' && t('dashboard.myTasksOnTeams')
                              }
                              defaultValue={filterTasks}
                              onChange={e => setFilterTasks(e.value)}
                           />
                        </div>
                     </div>

                     {filterTime !== 'today' &&

                        <div className="chart-container">
                           <Chart
                              width={'50rem'}
                              height={'35rem'}
                              chartType="PieChart"
                              loader={
                                 <Loading
                                    type='spokes'
                                    height='100%'
                                 />
                              }
                              data={dataChart}
                              options={{
                                 colors: dataStatusColor,
                                 backgroundColor: 'transparent',
                                 chartArea: { left: 0, top: 15, right: 0, bottom: 15 },
                                 pieSliceText: 'label',
                                 fontSize: 16,
                                 fontName: 'DM Sans',
                                 pieSliceTextStyle: {
                                    fontSize: 16,
                                    fontName: 'DM Sans',
                                    color: '#000',
                                 },
                                 tooltip: {
                                    text: 'percentage',
                                    trigger: 'focus',
                                    // trigger: 'selection',
                                    textStyle: {
                                       color: '#000000',
                                       fontName: 'DM Sans',
                                       fontSize: 14,
                                    }
                                 },
                                 legend: 'none',
                                 // legend: {
                                 //    alignment: 'center',
                                 //    textStyle: {
                                 //       color: '#BDBDBD',
                                 //       fontName: 'DM Sans',
                                 //       fontSize: 12,
                                 //       bold: false,
                                 //       italic: false
                                 //    }
                                 // }
                                 // pieStartAngle: 100,
                                 // title: 'My Daily Activities',
                              }}
                              rootProps={{ 'data-testid': '1' }}
                           />
                        </div>
                     }

                     {filterTime === 'today' && (
                        <div className="status-container">
                           <span>{t('dashboard.youAre')}: <span className="status">{status}</span></span>
                        </div>
                     )}


                     {filterTime !== 'month' &&
                        <div className="progress-container">
                           <div className="progress-items">
                              <ProgressBar now={parseInt(percentageNow * 100).toFixed(0)} label={`${t('dashboard.now')} ${totalTasksInGoGoGoNow}/${totalTasksNow}`} />
                              <span>{parseInt(percentageNow * 100).toFixed(0)}%</span>
                           </div>

                           <div className="progress-items">
                              <ProgressBar now={parseInt(percentageImportant * 100).toFixed(0)} label={`${t('dashboard.important')} ${totalTasksInGoGoGoImportant}/${totalTasksImportant}`} />
                              <span>{parseInt(percentageImportant * 100).toFixed(0)}%</span>
                           </div>

                           <div className="progress-items">
                              <ProgressBar now={parseInt(percentageRegular * 100).toFixed(0)} label={`${t('dashboard.regular')} ${totalTasksInGoGoGoRegular}/${totalTasksRegular}`} />
                              <span>{parseInt(percentageRegular * 100).toFixed(0)}%</span>
                           </div>
                        </div>
                     }

                     <div className="task-completed-container">
                        <span className='task-completed-title'>{t('dashboard.taskCompleted')}</span>
                        {filterTime !== 'month' ?
                           <span className='task-completed-quantity'>{totalTasksCompletedDay}</span>
                           :
                           <span className='task-completed-quantity'>{totalTasksCompletedMonth}</span>
                        }
                     </div>

                     <div className="subtitles-container">
                        <div className="subtitles-title">
                           <FiInfo size={25} color="#676768" />
                           <span>{t('dashboard.subTitle')}</span>
                        </div>

                        <div className="subtitles-options">
                           <div className="option-container">
                              <span className="option">{t('dashboard.options.goLate')}</span>
                           </div>

                           <div className="option-explanation-container">
                              <span className='option-explanation'>{t('dashboard.optionsExplanation.goLate')}</span>
                           </div>
                        </div>

                        <div className="subtitles-options">
                           <div className="option-container">
                              <span className="option">{t('dashboard.options.goUpLate')}</span>
                           </div>

                           <div className="option-explanation-container">
                              <span className='option-explanation'>{t('dashboard.optionsExplanation.goUpLate')}</span>
                           </div>
                        </div>

                        <div className="subtitles-options">
                           <div className="option-container">
                              <span className="option">{t('dashboard.options.goTarget')}</span>
                           </div>

                           <div className="option-explanation-container">
                              <span className='option-explanation'>{t('dashboard.optionsExplanation.goTarget')}</span>
                           </div>
                        </div>

                        <div className="subtitles-options">
                           <div className="option-container">
                              <span className="option">{t('dashboard.options.goTop')}</span>
                           </div>

                           <div className="option-explanation-container">
                              <span className='option-explanation'>{t('dashboard.optionsExplanation.goTop')}</span>
                           </div>
                        </div>

                        <div className="subtitles-options">
                           <div className="option-container">
                              <span className="option">{t('dashboard.options.goUpTarget')}</span>
                           </div>

                           <div className="option-explanation-container">
                              <span className='option-explanation'>{t('dashboard.optionsExplanation.goUpTarget')}</span>
                           </div>
                        </div>

                        <div className="subtitles-options">
                           <div className="option-container">
                              <span className="option">{t('dashboard.options.goUpTop')}</span>
                           </div>

                           <div className="option-explanation-container">
                              <span className='option-explanation'>{t('dashboard.optionsExplanation.goUpTop')}</span>
                           </div>
                        </div>

                     </div>
                  </main>
               </>
            }
         </div>
      </div >
   )
}

export default Dashboard
