import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import api from '../../services/api'

import '../../styles/components/modal/ModalFutureTasksChangeType.css'

const ModalFutureTasksChangeType = (props) => {
   const [loadingButtonNow, setLoadingButtonNow] = useState('default')
   const [loadingButtonImportant, setLoadingButtonImportant] = useState('default')
   const [loadingButtonRegular, setLoadingButtonRegular] = useState('default')

   const [t] = useTranslation()

   async function handleEditTaskType(taskType) {
      if (taskType === 'now') {
         setLoadingButtonNow('processing')
      }

      if (taskType === 'important') {
         setLoadingButtonImportant('processing')
      }

      if (taskType === 'regular') {
         setLoadingButtonRegular('processing')
      }

      const data = { taskType }

      try {
         await api.put(`tasksType/${props.taskId}`, data)

         setLoadingButtonNow('default')
         setLoadingButtonImportant('default')
         setLoadingButtonRegular('default')

         props.loadFutureTasks()
         props.loadNotes()

         props.setModalChangeType(false)

      } catch (e) {
         setLoadingButtonNow('default')
         setLoadingButtonImportant('default')
         setLoadingButtonRegular('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalTasksChangeType'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle modal-body">
            <h2>{t('futureNotes.taskQuestion')}</h2>
            <p className='subtitle'>{t('futureNotes.subtitle')}</p>


            {loadingButtonNow === 'default' ? (
               <button
                  type='submit'
                  className='change-type-button'
                  onClick={() => handleEditTaskType('now')}
               >
                  Now
               </button>
            ) : (
               <button
                  className='change-type-button'
                  style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                  disabled
               >
                  <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                  <span className="sr-only"></span>
               </button>
            )}

            {loadingButtonImportant === 'default' ? (
               <button
                  type='submit'
                  className='change-type-button'
                  onClick={() => handleEditTaskType('important')}
               >
                  Important
               </button>
            ) : (
               <button
                  className='change-type-button'
                  style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                  disabled
               >
                  <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                  <span className="sr-only"></span>
               </button>
            )}

            {loadingButtonRegular === 'default' ? (
               <button
                  type='submit'
                  className='change-type-button'
                  onClick={() => handleEditTaskType('regular')}
               >
                  Regular
               </button>
            ) : (
               <button
                  className='change-type-button'
                  style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                  disabled
               >
                  <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                  <span className="sr-only"></span>
               </button>
            )}


         </ModalBody>

      </Modal>
   )
}

export default ModalFutureTasksChangeType
