import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Switch from "react-switch"
import { FiPlusSquare } from 'react-icons/fi'
import crypto from 'crypto-js'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { useAuth } from '../../services/contexts/auth'

import TaskCard from '../../components/TaskCard'
import PageHeaderTeamTask from '../../components/PageHeaderTeamTask'
import Loading from '../../components/Loading'
import RocketAnimation from '../../components/RocketAnimation'
import ModalTaskDetails from '../../components/modal/ModalTaskDetails'
import ModalAddTasks from '../../components/modal/ModalAddTasks'
import ModalTaskInGoGoGo from '../../components/modal/ModalTaskInGoGoGo'

import api from '../../services/api'

import '../../styles/pages/app/Tasks.css'

const TeamTasks = () => {
   const { id } = useParams()
   const team_id = crypto.DES.decrypt(decodeURIComponent(id), 'idTeam').toString(crypto.enc.Utf8)

   const [loading, setLoading] = useState(true)
   const [loadingButtonAdd, setLoadingButtonAdd] = useState('default')
   const [loadingButtonAddWithDetails, setLoadingButtonAddWithDetails] = useState('default')
   const [animation, setAnimation] = useState(false)

   const [teamId, setTeamId] = useState(team_id)
   const [teamName, setTeamName] = useState('')

   const [teamStatus, setTeamStatus] = useState('')
   const [teamStatusBackgroundColor, setTeamStatusBackgroundColor] = useState('')
   const [teamStatusColor, setTeamStatusColor] = useState('')

   const [modalTaskDetails, setModalTaskDetails] = useState(false)
   const [modalAddTasks, setModalAddTasks] = useState(false)
   const [modalGoGoGo, setModalGoGoGo] = useState(false)

   const [showColorNow, setShowColorNow] = useState(false)
   const [showColorImportant, setShowColorImportant] = useState(false)
   const [showColorRegular, setShowColorRegular] = useState(false)
   const [taskIndex, setTaskIndex] = useState('')

   const [showGogogo, setShowGogogo] = useState(false)

   const [idUser, setIdUser] = useState()
   const [taskId, setTaskId] = useState('')

   const [taskName, setTaskName] = useState('')
   const [taskType, setTaskType] = useState('')
   const [userIdSelected, setUserIdSelected] = useState()

   const [gogogo_comment_id, setGoGoGoCommentId] = useState('')
   const [additionalComment, setAdditionalComment] = useState('')

   const [teamTasksDataNow, setTeamTasksDataNow] = useState([])
   const [teamTasksDataImportant, setTeamTasksDataImportant] = useState([])
   const [teamTasksDataRegular, setTeamTasksDataRegular] = useState([])
   const [teamsData, setTeamsData] = useState([])

   const [quantityTasksNow, setQuantityTasksNow] = useState()
   const [quantityTasksNowGoGoGo, setQuantityTasksNowGoGoGo] = useState()
   const [quantityTasksImportant, setQuantityTasksImportant] = useState()
   const [quantityTasksImportantGoGoGo, setQuantityTasksImportantGoGoGo] = useState()
   const [quantityTasksRegular, setQuantityTasksRegular] = useState()
   const [quantityTasksRegularGoGoGo, setQuantityTasksRegularGoGoGo] = useState()

   const [commentContent, setCommentContent] = useState('')

   const { userId, idStorage } = useAuth()

   const dropdownColorRef = useRef(null)

   const [t] = useTranslation()

   useEffect(() => {
      if (userId) {
         setIdUser(userId)
         setUserIdSelected(userId)

      } else {
         setIdUser(idStorage)
         setUserIdSelected(idStorage)
      }

   }, [userId, idStorage])

   const loadTeamStatus = useCallback(async () => {
      const res = await api.get(`teamStatusDaily?team_id=${team_id}`)

      setTeamStatus(res.data[0].name)
      setTeamStatusBackgroundColor(res.data[0].backgroundColor)
      setTeamStatusColor(res.data[0].color)

   }, [team_id])


   useEffect(() => {
      async function loadTeamName() {
         const res = await api.get(`teams/${team_id}`)

         setTeamName(res.data.team_name)
      }

      loadTeamName()

   }, [team_id])

   const loadQuantityTasksNow = useCallback(async () => {
      const totalTasksNoGoGoGo = await api.get(`teamTasks/${team_id}?type=now&gogogo=false&completed=false`)
      const totalTasksInGoGoGo = await api.get(`teamTasks/${team_id}?type=now&gogogo=true&completed=false`)

      const totalTasks = totalTasksNoGoGoGo.data.length + totalTasksInGoGoGo.data.length

      setQuantityTasksNow(totalTasks)
      setQuantityTasksNowGoGoGo(totalTasksInGoGoGo.data.length)

   }, [team_id])

   const loadQuantityTasksImportant = useCallback(async () => {
      const totalTasksNoGoGoGo = await api.get(`teamTasks/${team_id}?type=important&gogogo=false&completed=false`)
      const totalTasksInGoGoGo = await api.get(`teamTasks/${team_id}?type=important&gogogo=true&completed=false`)

      const totalTasks = totalTasksNoGoGoGo.data.length + totalTasksInGoGoGo.data.length

      setQuantityTasksImportant(totalTasks)
      setQuantityTasksImportantGoGoGo(totalTasksInGoGoGo.data.length)

   }, [team_id])


   const loadQuantityTasksRegular = useCallback(async () => {
      const totalTasksNoGoGoGo = await api.get(`teamTasks/${team_id}?type=regular&gogogo=false&completed=false`)
      const totalTasksInGoGoGo = await api.get(`teamTasks/${team_id}?type=regular&gogogo=true&completed=false`)

      const totalTasks = totalTasksNoGoGoGo.data.length + totalTasksInGoGoGo.data.length

      setQuantityTasksRegular(totalTasks)
      setQuantityTasksRegularGoGoGo(totalTasksInGoGoGo.data.length)

   }, [team_id])


   const loadTasksNow = useCallback(async () => {
      const res = await api.get(`teamTasks/${team_id}?type=now&gogogo=${showGogogo}&completed=false`)

      setTeamTasksDataNow(res.data)
      await loadTeamStatus()
      await loadQuantityTasksNow()

   }, [team_id, showGogogo, loadTeamStatus, loadQuantityTasksNow])

   const loadTasksImportant = useCallback(async () => {
      const res = await api.get(`teamTasks/${team_id}?type=important&gogogo=${showGogogo}&completed=false`)

      setTeamTasksDataImportant(res.data)
      await loadTeamStatus()
      await loadQuantityTasksImportant()

   }, [team_id, showGogogo, loadTeamStatus, loadQuantityTasksImportant])

   const loadTasksRegular = useCallback(async () => {
      const res = await api.get(`teamTasks/${team_id}?type=regular&gogogo=${showGogogo}&completed=false`)

      setTeamTasksDataRegular(res.data)
      await loadTeamStatus()
      await loadQuantityTasksRegular()

   }, [team_id, showGogogo, loadTeamStatus, loadQuantityTasksRegular])

   const loadAll = useCallback(async () => {
      await loadTasksNow()
      await loadTasksImportant()
      await loadTasksRegular()

      setLoading(false)

   }, [loadTasksNow, loadTasksImportant, loadTasksRegular])

   useEffect(() => {
      loadAll()

   }, [loadAll])

   const updateOrderTeamTasksNow = useCallback(async () => {
      for (let i = 0; i < teamTasksDataNow.length; i++) {
         await api.put(`tasksOrder/${teamTasksDataNow[i].id}`, { taskOrder: i })
      }
   }, [teamTasksDataNow])

   const updateOrderTeamTasksImportant = useCallback(async () => {
      for (let i = 0; i < teamTasksDataImportant.length; i++) {
         await api.put(`tasksOrder/${teamTasksDataImportant[i].id}`, { taskOrder: i })
      }
   }, [teamTasksDataImportant])

   const updateOrderTeamTasksRegular = useCallback(async () => {
      for (let i = 0; i < teamTasksDataRegular.length; i++) {
         await api.put(`tasksOrder/${teamTasksDataRegular[i].id}`, { taskOrder: i })
      }
   }, [teamTasksDataRegular])

   useEffect(() => {
      updateOrderTeamTasksNow()
      updateOrderTeamTasksImportant()
      updateOrderTeamTasksRegular()

   }, [updateOrderTeamTasksNow, updateOrderTeamTasksImportant, updateOrderTeamTasksRegular])

   useEffect(() => {
      if (team_id !== null) {
         async function loadTeamName() {
            const res = await api.get(`teams/${team_id}`)

            setTeamName(res.data.team_name)
         }

         loadTeamName()
      }

   }, [team_id])

   const handleShowColorNow = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorNow) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorNow(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorNow) {
         setShowColorNow(false);
         setTaskIndex('');

      } else {
         setShowColorNow(true);
         setTaskIndex(index);
      }

   }, [showColorNow]);

   useEffect(() => {
      if (showColorNow) {
         document.addEventListener("click", handleShowColorNow);

         return () => document.removeEventListener("click", handleShowColorNow);
      }

   }, [showColorNow, handleShowColorNow]);

   const handleShowColorImportant = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorImportant) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorImportant(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorImportant) {
         setShowColorImportant(false);
         setTaskIndex('');

      } else {
         setShowColorImportant(true);
         setTaskIndex(index);
      }

   }, [showColorImportant]);

   useEffect(() => {
      if (showColorImportant) {
         document.addEventListener("click", handleShowColorImportant);

         return () => document.removeEventListener("click", handleShowColorImportant);
      }

   }, [showColorImportant, handleShowColorImportant]);

   const handleShowColorRegular = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorRegular) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorRegular(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorRegular) {
         setShowColorRegular(false);
         setTaskIndex('');

      } else {
         setShowColorRegular(true);
         setTaskIndex(index);
      }

   }, [showColorRegular]);

   useEffect(() => {
      if (showColorRegular) {
         document.addEventListener("click", handleShowColorRegular);

         return () => document.removeEventListener("click", handleShowColorRegular);
      }

   }, [showColorRegular, handleShowColorRegular]);

   async function loadTeams() {
      const res = await api.get('teams')

      setTeamsData(res.data)
   }

   async function handleAddTask(e) {
      e.preventDefault()

      setLoadingButtonAdd('processing')

      const data = { taskName, taskType, team_id: teamId, userIdSelected }

      try {
         const res = await api.post('tasks', data)

         toast.success(res.data.message)

         setTaskName('')
         setTeamId(team_id)
         setUserIdSelected(idUser)

         setModalAddTasks(false)

         if (taskType === 'now') {
            await loadTasksNow()
            setLoadingButtonAdd('default')

            return scrollToBottomNow()
         }

         if (taskType === 'important') {
            await loadTasksImportant()
            setLoadingButtonAdd('default')

            return scrollToBottomImportant()
         }

         if (taskType === 'regular') {
            await loadTasksRegular()
            setLoadingButtonAdd('default')

            return scrollToBottomRegular()
         }

      } catch (e) {
         setLoadingButtonAdd('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleAddTaskWithDetails(e) {
      e.preventDefault()

      setLoadingButtonAddWithDetails('processing')

      const data = { taskName, taskType, team_id: teamId, userIdSelected }

      try {
         const res = await api.post('tasks', data)

         toast.success(res.data.message)

         setTaskName('')
         setTeamId(null)
         setUserIdSelected(idUser)

         setModalAddTasks(false)

         if (taskType === 'now') {
            await loadTasksNow()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomNow()
         }

         if (taskType === 'important') {
            await loadTasksImportant()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomImportant()
         }

         if (taskType === 'regular') {
            await loadTasksRegular()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomRegular()
         }

         return handleOpenModalTaskDetails(res.data.taskCreated[0].id)

      } catch (e) {
         setLoadingButtonAddWithDetails('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleTaskGoGoGo(e, id) {
      e.preventDefault()
      setLoadingButtonAdd('processing')

      const data = { gogogo_comment_id, additionalComment }

      try {
         await api.put(`gogogo/${id}`, data)

         setGoGoGoCommentId('')
         setAdditionalComment('')

         loadTasksNow()
         loadTasksImportant()
         loadTasksRegular()

         setModalGoGoGo(false)
         setLoadingButtonAdd('default')
         setAnimation(true)

         setTimeout(() => {
            setAnimation(false)
         }, 1500)

      } catch (e) {
         setLoadingButtonAdd('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function toggleSwitch() {
      setShowGogogo(!showGogogo)
   }

   function handleOpenModalAddTasks(taskType) {
      setModalAddTasks(!modalAddTasks)

      setTaskType(taskType)
      setTaskName('')

      loadTeams()
   }

   function handleOpenModalGoGoGo(id) {
      setModalGoGoGo(!modalGoGoGo)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')
      }

   }

   function handleOpenModalTaskDetails(id) {
      setModalTaskDetails(!modalTaskDetails)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')

         setCommentContent('')

         loadTasksNow()
         loadTasksImportant()
         loadTasksRegular()
      }
   }

   function scrollToBottomNow() {
      const column = document.getElementById("columnNow")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   function scrollToBottomImportant() {
      const column = document.getElementById("columnImportant")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   function scrollToBottomRegular() {
      const column = document.getElementById("columnRegular")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   async function handleUpdateColor(e, taskId, cardColor) {
      e.preventDefault()

      const data = { card_color: cardColor }

      try {
         await api.put(`tasksColor/${taskId}`, data)

         loadTasksNow()
         loadTasksImportant()
         loadTasksRegular()

         setShowColorNow(false)
         setShowColorImportant(false)
         setShowColorRegular(false)
         setTaskIndex('')

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleEditTaskTypeOnDragDrop(taskId, taskType) {

      const data = { taskType }

      try {
         await api.put(`tasksType/${taskId}`, data)

         loadTeamStatus()

         await loadQuantityTasksNow()
         await loadQuantityTasksImportant()
         await loadQuantityTasksRegular()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const reorderSameList = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   const reorderOtherLists = (sourceList, destinationList, startIndex, endIndex) => {
      const source = Array.from(sourceList);
      const destination = Array.from(destinationList);

      const [removed] = source.splice(startIndex, 1);
      destination.splice(endIndex, 0, removed);

      return { source, destination };
   };

   const onDragEnd = async (result) => {
      const { source, destination, draggableId } = result;

      if (!destination) {
         return;
      }

      if (destination.index === source.index && destination.droppableId === source.droppableId) {
         return;
      }

      if (destination.droppableId === source.droppableId) {
         const tasksReorder = reorderSameList(
            destination.droppableId === 'now'
               ? teamTasksDataNow
               : destination.droppableId === 'important'
                  ? teamTasksDataImportant
                  : destination.droppableId === 'regular'
                  && teamTasksDataRegular,
            source.index,
            destination.index
         );

         if (destination.droppableId === 'now') {
            setTeamTasksDataNow(tasksReorder)

         } else if (destination.droppableId === 'important') {
            setTeamTasksDataImportant(tasksReorder)

         } else if (destination.droppableId === 'regular') {
            setTeamTasksDataRegular(tasksReorder)
         }

      } else {
         const tasksReorder = reorderOtherLists(
            source.droppableId === 'now'
               ? teamTasksDataNow
               : source.droppableId === 'important'
                  ? teamTasksDataImportant
                  : teamTasksDataRegular,
            destination.droppableId === 'now'
               ? teamTasksDataNow
               : destination.droppableId === 'important'
                  ? teamTasksDataImportant
                  : teamTasksDataRegular,
            source.index,
            destination.index
         )

         if (source.droppableId === 'now') {
            setTeamTasksDataNow(tasksReorder.source)

         } else if (source.droppableId === 'important') {
            setTeamTasksDataImportant(tasksReorder.source)

         } else if (source.droppableId === 'regular') {
            setTeamTasksDataRegular(tasksReorder.source)
         }


         if (destination.droppableId === 'now') {
            setTeamTasksDataNow(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'now')

         } else if (destination.droppableId === 'important') {
            setTeamTasksDataImportant(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'important')

         } else if (destination.droppableId === 'regular') {
            setTeamTasksDataRegular(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'regular')
         }
      }
   };



   return (
      <DragDropContext
         onDragEnd={onDragEnd}
      >
         <div id='page-tasks'>
            <PageHeaderTeamTask
               teamName={teamName}
            />

            <div className='container'>
               {loading ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <RocketAnimation
                        isOpen={animation}
                     />

                     <div className="header-container">
                        <div className="status-container" style={{ background: teamStatusBackgroundColor }}>
                           <span style={{ color: teamStatusColor }}>{teamName} {t('dashboard.yourTeamAre')}: {teamStatus}</span>
                        </div>

                        <div className="filter-container">
                           <div className="filter">
                              {showGogogo ?
                                 <span>{t('task.showNotGoGoGo')}</span>
                                 :
                                 <span>{t('task.showGoGoGo')}</span>
                              }
                              <Switch
                                 checked={showGogogo}
                                 onChange={toggleSwitch}
                                 offColor='#B1B1B1'
                                 onColor='#6B2722'
                                 offHandleColor='#6B2722'
                                 onHandleColor='#B1B1B1'
                                 uncheckedIcon={false}
                                 checkedIcon={false}
                                 height={16}
                                 width={39}
                              />
                           </div>
                        </div>
                     </div>

                     <main>

                        <div className={showGogogo ? "column-gogogo-teamTask" : "column"}>
                           <div className="types">
                              <span className='task-type'>{t('task.now')}</span>
                              <span className='counter'>{quantityTasksNowGoGoGo}/{quantityTasksNow}</span>
                           </div>
                           <Droppable
                              droppableId="now"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (

                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnNow'
                                    className="column-two-parts"
                                 >
                                    {teamTasksDataNow.map((task, index) => (
                                       <TaskCard
                                          key={task.id}
                                          task={task}
                                          index={index}
                                          handleOpenModalTaskDetails={handleOpenModalTaskDetails}
                                          handleShowColor={handleShowColorNow}
                                          dropdownColorRef={dropdownColorRef}
                                          showColor={showColorNow}
                                          taskIndex={taskIndex}
                                          handleUpdateColor={handleUpdateColor}
                                          handleOpenModalGoGoGo={handleOpenModalGoGoGo}
                                          taskType='now'
                                       />
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddTasks('now')}>
                              <FiPlusSquare size={20} color='#636363' />
                              <span>{t('task.buttonNewTask')}</span>
                           </button>
                        </div>

                        <div className={showGogogo ? "column-gogogo-teamTask" : "column"}>
                           <div className="types">
                              <span className='task-type'>{t('task.important')}</span>
                              <span className='counter'>{quantityTasksImportantGoGoGo}/{quantityTasksImportant}</span>
                           </div>

                           <Droppable
                              droppableId="important"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (

                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnImportant'
                                    className="column-two-parts"
                                 >
                                    {teamTasksDataImportant.map((task, index) => (
                                       <TaskCard
                                          key={task.id}
                                          task={task}
                                          index={index}
                                          handleOpenModalTaskDetails={handleOpenModalTaskDetails}
                                          handleShowColor={handleShowColorImportant}
                                          dropdownColorRef={dropdownColorRef}
                                          showColor={showColorImportant}
                                          taskIndex={taskIndex}
                                          handleUpdateColor={handleUpdateColor}
                                          handleOpenModalGoGoGo={handleOpenModalGoGoGo}
                                          taskType='important'
                                       />
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddTasks('important')}>
                              <FiPlusSquare size={20} color='#636363' />
                              <span>{t('task.buttonNewTask')}</span>
                           </button>
                        </div>

                        <div className={showGogogo ? "column-gogogo-teamTask" : "column"}>
                           <div className="types">
                              <span className='task-type'>{t('task.regular')}</span>
                              <span className='counter'>{quantityTasksRegularGoGoGo}/{quantityTasksRegular}</span>
                           </div>

                           <Droppable
                              droppableId="regular"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnRegular'
                                    className="column-two-parts"
                                 >
                                    {teamTasksDataRegular.map((task, index) => (
                                       <TaskCard
                                          key={task.id}
                                          task={task}
                                          index={index}
                                          handleOpenModalTaskDetails={handleOpenModalTaskDetails}
                                          handleShowColor={handleShowColorRegular}
                                          dropdownColorRef={dropdownColorRef}
                                          showColor={showColorRegular}
                                          taskIndex={taskIndex}
                                          handleUpdateColor={handleUpdateColor}
                                          handleOpenModalGoGoGo={handleOpenModalGoGoGo}
                                          taskType='regular'
                                       />
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddTasks('regular')}>
                              <FiPlusSquare size={20} color='#636363' />
                              <span>{t('task.buttonNewTask')}</span>
                           </button>
                        </div>
                     </main>

                     <ModalTaskDetails
                        isOpen={modalTaskDetails}
                        toggle={handleOpenModalTaskDetails}
                        taskId={taskId}
                        loadTasksNow={loadTasksNow}
                        loadTasksImportant={loadTasksImportant}
                        loadTasksRegular={loadTasksRegular}
                        commentContent={commentContent}
                        setCommentContent={setCommentContent}
                     />

                     <ModalAddTasks
                        isOpen={modalAddTasks}
                        toggle={handleOpenModalAddTasks}
                        taskName={taskName}
                        setTaskName={setTaskName}
                        taskType={taskType}
                        setTaskType={setTaskType}
                        team_id={teamId}
                        setTeamId={setTeamId}
                        teamName={teamName}
                        idUser={idUser}
                        setUserIdSelected={setUserIdSelected}
                        handleAddTask={handleAddTask}
                        handleAddTaskWithDetails={handleAddTaskWithDetails}
                        teamsData={teamsData}
                        loadingButtonAdd={loadingButtonAdd}
                        loadingButtonAddWithDetails={loadingButtonAddWithDetails}
                     />

                     <ModalTaskInGoGoGo
                        isOpen={modalGoGoGo}
                        toggle={handleOpenModalGoGoGo}
                        taskId={taskId}
                        gogogo_comment_id={gogogo_comment_id}
                        setGoGoGoCommentId={setGoGoGoCommentId}
                        additionalComment={additionalComment}
                        setAdditionalComment={setAdditionalComment}
                        handleTaskGoGoGo={handleTaskGoGoGo}
                        loadingButton={loadingButtonAdd}
                     />

                  </>
               }
            </div>
         </div >
      </DragDropContext>
   )
}

export default TeamTasks
