import React, { useState, useEffect, forwardRef, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { FiLock, FiCalendar } from 'react-icons/fi'
import { BiSearchAlt2 } from 'react-icons/bi'
import { MdFormatColorFill } from 'react-icons/md'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import ModalTaskDetails from '../../components/modal/ModalTaskDetails'
import DropdownColor from '../../components/DropdownColor'

import api from '../../services/api'

import '../../styles/pages/app/TasksCompleted.css'

const TasksCompleted = () => {
   const [loading, setLoading] = useState(true)

   const [modalTaskDetails, setModalTaskDetails] = useState(false)

   const [taskName, setTaskName] = useState('')

   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;

   const [showColorNow, setShowColorNow] = useState(false)
   const [showColorImportant, setShowColorImportant] = useState(false)
   const [showColorRegular, setShowColorRegular] = useState(false)
   const [taskIndex, setTaskIndex] = useState('')

   const [tasksCompletedNowData, setTasksCompletedNowData] = useState([])
   const [tasksCompletedImportantData, setTasksCompletedImportantData] = useState([])
   const [tasksCompletedRegularData, setTasksCompletedRegularData] = useState([])

   const [commentContent, setCommentContent] = useState('')

   const [taskId, setTaskId] = useState('')

   const [t] = useTranslation()

   const dropdownColorRef = useRef(null)

   const startDateSearch = startDate && moment(startDate).format('YYYY-MM-DD 00:00:01')
   const endDateSearch = endDate && moment(endDate).format('YYYY-MM-DD 23:59:59')

   useEffect(() => {
      if (endDate === null) {
         async function loadCompletedTasksNow() {
            const res = await api.get(`taskCompleted?type=now&taskName=${taskName}`)

            setTasksCompletedNowData(res.data)
            setLoading(false)
         }

         async function loadCompletedTasksImportant() {
            const res = await api.get(`taskCompleted?type=important&taskName=${taskName}`)

            setTasksCompletedImportantData(res.data)
            setLoading(false)
         }

         async function loadCompletedTasksRegular() {
            const res = await api.get(`taskCompleted?type=regular&taskName=${taskName}`)

            setTasksCompletedRegularData(res.data)
            setLoading(false)
         }

         loadCompletedTasksNow()
         loadCompletedTasksImportant()
         loadCompletedTasksRegular()

      } else {
         async function loadCompletedTasksNow() {
            const res = await api.get(`taskCompleted?type=now&taskName=${taskName}&initialDate=${startDateSearch}&finishDate=${endDateSearch}`)

            setTasksCompletedNowData(res.data)
            setLoading(false)
         }

         async function loadCompletedTasksImportant() {
            const res = await api.get(`taskCompleted?type=important&taskName=${taskName}&initialDate=${startDateSearch}&finishDate=${endDateSearch}`)

            setTasksCompletedImportantData(res.data)
            setLoading(false)
         }

         async function loadCompletedTasksRegular() {
            const res = await api.get(`taskCompleted?type=regular&taskName=${taskName}&initialDate=${startDateSearch}&finishDate=${endDateSearch}`)

            setTasksCompletedRegularData(res.data)
            setLoading(false)
         }

         loadCompletedTasksNow()
         loadCompletedTasksImportant()
         loadCompletedTasksRegular()
      }

   }, [taskName, startDateSearch, endDateSearch, endDate])

   const handleShowColorNow = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorNow) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorNow(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorNow) {
         setShowColorNow(false);
         setTaskIndex('');

      } else {
         setShowColorNow(true);
         setTaskIndex(index);
      }

   }, [showColorNow]);

   useEffect(() => {
      if (showColorNow) {
         document.addEventListener("click", handleShowColorNow);

         return () => document.removeEventListener("click", handleShowColorNow);
      }

   }, [showColorNow, handleShowColorNow]);

   const handleShowColorImportant = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorImportant) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorImportant(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorImportant) {
         setShowColorImportant(false);
         setTaskIndex('');

      } else {
         setShowColorImportant(true);
         setTaskIndex(index);
      }

   }, [showColorImportant]);

   useEffect(() => {
      if (showColorImportant) {
         document.addEventListener("click", handleShowColorImportant);

         return () => document.removeEventListener("click", handleShowColorImportant);
      }

   }, [showColorImportant, handleShowColorImportant]);

   const handleShowColorRegular = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorRegular) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorRegular(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorRegular) {
         setShowColorRegular(false);
         setTaskIndex('');

      } else {
         setShowColorRegular(true);
         setTaskIndex(index);
      }

   }, [showColorRegular]);

   useEffect(() => {
      if (showColorRegular) {
         document.addEventListener("click", handleShowColorRegular);

         return () => document.removeEventListener("click", handleShowColorRegular);
      }

   }, [showColorRegular, handleShowColorRegular]);


   async function loadCompletedTasksNow() {
      const res = await api.get(`taskCompleted?type=now`)

      setTasksCompletedNowData(res.data)
      setLoading(false)
   }

   async function loadCompletedTasksImportant() {
      const res = await api.get(`taskCompleted?type=important`)

      setTasksCompletedImportantData(res.data)
      setLoading(false)
   }

   async function loadCompletedTasksRegular() {
      const res = await api.get(`taskCompleted?type=regular`)

      setTasksCompletedRegularData(res.data)
      setLoading(false)
   }

   const updateOrderTasksCompletedNow = useCallback(async () => {
      for (let i = 0; i < tasksCompletedNowData.length; i++) {
         await api.put(`tasksOrder/${tasksCompletedNowData[i].id}`, { taskOrder: i })
      }
   }, [tasksCompletedNowData])

   const updateOrderTasksCompletedImportant = useCallback(async () => {
      for (let i = 0; i < tasksCompletedImportantData.length; i++) {
         await api.put(`tasksOrder/${tasksCompletedImportantData[i].id}`, { taskOrder: i })
      }
   }, [tasksCompletedImportantData])

   const updateOrderTasksCompletedRegular = useCallback(async () => {
      for (let i = 0; i < tasksCompletedRegularData.length; i++) {
         await api.put(`tasksOrder/${tasksCompletedRegularData[i].id}`, { taskOrder: i })
      }
   }, [tasksCompletedRegularData])

   useEffect(() => {
      updateOrderTasksCompletedNow()
      updateOrderTasksCompletedImportant()
      updateOrderTasksCompletedRegular()

   }, [updateOrderTasksCompletedNow, updateOrderTasksCompletedImportant, updateOrderTasksCompletedRegular])


   async function handleUpdateColor(e, taskId, cardColor) {
      e.preventDefault()

      const data = { card_color: cardColor }

      try {
         await api.put(`tasksColor/${taskId}`, data)

         loadCompletedTasksNow()
         loadCompletedTasksImportant()
         loadCompletedTasksRegular()

         setShowColorNow(false)
         setShowColorImportant(false)
         setShowColorRegular(false)
         setTaskIndex('')

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }


   function handleOpenModalTaskDetails(id) {
      setModalTaskDetails(!modalTaskDetails)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')

         setCommentContent('')

         loadCompletedTasksNow()
         loadCompletedTasksImportant()
         loadCompletedTasksRegular()
      }
   }

   const onChangeDatePicker = (dates) => {
      setDateRange(dates)
   }

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <div className='filter-period'>
                     <FiCalendar color='#676768' size={15} />
                     <span>{t('tasksCompleted.from')}</span>
                  </div>
                  :
                  <div className='filter-period'>
                     <FiCalendar color='#676768' size={15} />
                     <span>{moment(startDate).format('DD-MM-YYYY')}</span>
                  </div>
               }
            </button>

            <button
               type="button"
               onClick={onClick}
               ref={ref}
            >
               {!endDate
                  ?
                  <div className='filter-period'>
                     <FiCalendar color='#676768' size={15} />
                     <span>{t('tasksCompleted.to')}</span>
                  </div>
                  :
                  <div className='filter-period'>
                     <FiCalendar color='#676768' size={15} />
                     <span>{moment(endDate).format('DD-MM-YYYY')}</span>
                  </div>
               }
            </button>

         </div>
      )
   })

   async function handleEditTaskTypeOnDragDrop(taskId, taskType) {

      const data = { taskType }

      try {
         await api.put(`tasksType/${taskId}`, data)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const reorderSameList = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   const reorderOtherLists = (sourceList, destinationList, startIndex, endIndex) => {
      const source = Array.from(sourceList);
      const destination = Array.from(destinationList);

      const [removed] = source.splice(startIndex, 1);
      destination.splice(endIndex, 0, removed);

      return { source, destination };
   };

   const onDragEnd = async (result) => {
      const { source, destination, draggableId } = result;

      if (!destination) {
         return;
      }

      if (destination.index === source.index && destination.droppableId === source.droppableId) {
         return;
      }

      if (destination.droppableId === source.droppableId) {
         const tasksReorder = reorderSameList(
            destination.droppableId === 'now'
               ? tasksCompletedNowData
               : destination.droppableId === 'important'
                  ? tasksCompletedImportantData
                  : destination.droppableId === 'regular'
                  && tasksCompletedRegularData,
            source.index,
            destination.index
         );

         if (destination.droppableId === 'now') {
            setTasksCompletedNowData(tasksReorder)

         } else if (destination.droppableId === 'important') {
            setTasksCompletedImportantData(tasksReorder)

         } else if (destination.droppableId === 'regular') {
            setTasksCompletedRegularData(tasksReorder)
         }

      } else {
         const tasksReorder = reorderOtherLists(
            source.droppableId === 'now'
               ? tasksCompletedNowData
               : source.droppableId === 'important'
                  ? tasksCompletedImportantData
                  : tasksCompletedRegularData,
            destination.droppableId === 'now'
               ? tasksCompletedNowData
               : destination.droppableId === 'important'
                  ? tasksCompletedImportantData
                  : tasksCompletedRegularData,
            source.index,
            destination.index
         )

         if (source.droppableId === 'now') {
            setTasksCompletedNowData(tasksReorder.source)

         } else if (source.droppableId === 'important') {
            setTasksCompletedImportantData(tasksReorder.source)

         } else if (source.droppableId === 'regular') {
            setTasksCompletedRegularData(tasksReorder.source)

         }

         if (destination.droppableId === 'now') {
            setTasksCompletedNowData(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'now')

         } else if (destination.droppableId === 'important') {
            setTasksCompletedImportantData(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'important')

         } else if (destination.droppableId === 'regular') {
            setTasksCompletedRegularData(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'regular')
         }
      }
   };

   return (
      <DragDropContext
         onDragEnd={onDragEnd}
      >
         <div id='page-tasks-completed'>
            <PageHeader />

            <div className='container'>
               {loading ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-container">
                        <h1>{t('tasksCompleted.tasksCompleted')}<span>{endDate === null && ` - ${t('tasksCompleted.tenDays')}`}</span></h1>

                        <div className="filter-container">
                           <div className="input-container">
                              <BiSearchAlt2 size={20} color='#676768' />
                              <Input
                                 name='taskNameSearch'
                                 type='text'
                                 value={taskName}
                                 onChange={e => setTaskName(e.target.value)}
                              />
                           </div>

                           <DatePicker
                              startDate={startDate}
                              endDate={endDate}
                              customInput={<CalendarCustomButton />}
                              onChange={onChangeDatePicker}
                              monthsShown={2}
                              selectsRange={true}
                              todayButton={t('dashboard.today')}
                              withPortal
                           />

                        </div>
                     </div>

                     <main>
                        <div className="column">
                           <div className="types">
                              <span className='task-type'>{t('task.now')}</span>
                           </div>

                           <Droppable
                              droppableId="now"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnNow'
                                    className="column-two-parts"
                                 >

                                    {tasksCompletedNowData.length === 0 ?
                                       <div className='no-tasks'>
                                          <h1>{t('tasksCompleted.noTasks')}</h1>
                                       </div>
                                       :
                                       <>
                                          {tasksCompletedNowData.map((task, index) => {
                                             return (
                                                <Draggable
                                                   draggableId={String(task.id)}
                                                   index={index}
                                                   key={task.id}
                                                >
                                                   {(provided, snapshot) => {
                                                      return (
                                                         <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                         >
                                                            <div className="card-task" style={{ background: task.card_color }}>
                                                               <div className="card-left">
                                                                  <div className="task-name-container" onClick={() => handleOpenModalTaskDetails(task.id)}>
                                                                     <h1>{task.task_name}</h1>
                                                                  </div>

                                                                  {task.private ?
                                                                     <span>
                                                                        <FiLock size={12} color="#828282" />
                                                                        {t('task.private')}
                                                                     </span>
                                                                     :
                                                                     <span>
                                                                        {task.team_name}
                                                                     </span>
                                                                  }
                                                               </div>

                                                               <div className="card-right">
                                                                  <div className="dropdown-color">
                                                                     <button
                                                                        className='color-button'
                                                                        onClick={e => handleShowColorNow(e, index)}
                                                                     >
                                                                        <MdFormatColorFill size={17} color="#828282" />
                                                                     </button>

                                                                     <div className='color-button-space'></div>

                                                                     <DropdownColor
                                                                        dropdownColorRef={dropdownColorRef}
                                                                        showColor={showColorNow}
                                                                        taskIndex={taskIndex}
                                                                        index={index}
                                                                        handleUpdateColor={handleUpdateColor}
                                                                        taskId={task.id}
                                                                     />
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      )
                                                   }}
                                                </Draggable>
                                             )
                                          })}
                                       </>
                                    }
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </div>

                        <div className="column">
                           <div className="types">
                              <span className='task-type'>{t('task.important')}</span>
                           </div>

                           <Droppable
                              droppableId="important"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnImportant'
                                    className="column-two-parts"
                                 >

                                    {tasksCompletedImportantData.length === 0 ?
                                       <div className='no-tasks'>
                                          <h1>{t('tasksCompleted.noTasks')}</h1>
                                       </div>
                                       :
                                       <>
                                          {tasksCompletedImportantData.map((task, index) => {
                                             return (
                                                <Draggable
                                                   draggableId={String(task.id)}
                                                   index={index}
                                                   key={task.id}
                                                >
                                                   {(provided, snapshot) => {
                                                      return (
                                                         <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                         >
                                                            <div className="card-task" style={{ background: task.card_color }}>
                                                               <div className="card-left">
                                                                  <div className="task-name-container" onClick={() => handleOpenModalTaskDetails(task.id)}>
                                                                     <h1>{task.task_name}</h1>
                                                                  </div>

                                                                  {task.private ?
                                                                     <span>
                                                                        <FiLock size={12} color="#828282" />
                                                                        {t('task.private')}
                                                                     </span>
                                                                     :
                                                                     <span>
                                                                        {task.team_name}
                                                                     </span>
                                                                  }
                                                               </div>

                                                               <div className="card-right">
                                                                  <div className="dropdown-color">
                                                                     <button
                                                                        className='color-button'
                                                                        onClick={e => handleShowColorImportant(e, index)}
                                                                     >
                                                                        <MdFormatColorFill size={17} color="#828282" />
                                                                     </button>

                                                                     <div className='color-button-space'></div>

                                                                     <DropdownColor
                                                                        dropdownColorRef={dropdownColorRef}
                                                                        showColor={showColorImportant}
                                                                        taskIndex={taskIndex}
                                                                        index={index}
                                                                        handleUpdateColor={handleUpdateColor}
                                                                        taskId={task.id}
                                                                     />
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      )
                                                   }}
                                                </Draggable>
                                             )
                                          })}
                                       </>
                                    }
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </div>

                        <div className="column">
                           <div className="types">
                              <span className='task-type'>{t('task.regular')}</span>
                           </div>

                           <Droppable
                              droppableId="regular"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnRegular'
                                    className="column-two-parts"
                                 >

                                    {tasksCompletedRegularData.length === 0 ?
                                       <div className='no-tasks'>
                                          <h1>{t('tasksCompleted.noTasks')}</h1>
                                       </div>
                                       :
                                       <>
                                          {tasksCompletedRegularData.map((task, index) => {
                                             return (
                                                <Draggable
                                                   draggableId={String(task.id)}
                                                   index={index}
                                                   key={task.id}
                                                >
                                                   {(provided, snapshot) => {
                                                      return (
                                                         <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                         >
                                                            <div className="card-task" style={{ background: task.card_color }}>
                                                               <div className="card-left">
                                                                  <div className="task-name-container" onClick={() => handleOpenModalTaskDetails(task.id)}>
                                                                     <h1>{task.task_name}</h1>
                                                                  </div>

                                                                  {task.private ?
                                                                     <span>
                                                                        <FiLock size={12} color="#828282" />
                                                                        {t('task.private')}
                                                                     </span>
                                                                     :
                                                                     <span>
                                                                        {task.team_name}
                                                                     </span>
                                                                  }
                                                               </div>

                                                               <div className="card-right">
                                                                  <div className="dropdown-color">
                                                                     <button
                                                                        className='color-button'
                                                                        onClick={e => handleShowColorRegular(e, index)}
                                                                     >
                                                                        <MdFormatColorFill size={17} color="#828282" />
                                                                     </button>

                                                                     <div className='color-button-space'></div>

                                                                     <DropdownColor
                                                                        dropdownColorRef={dropdownColorRef}
                                                                        showColor={showColorRegular}
                                                                        taskIndex={taskIndex}
                                                                        index={index}
                                                                        handleUpdateColor={handleUpdateColor}
                                                                        taskId={task.id}
                                                                     />
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      )
                                                   }}
                                                </Draggable>
                                             )
                                          })}
                                       </>
                                    }
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </div>

                        <ModalTaskDetails
                           isOpen={modalTaskDetails}
                           toggle={handleOpenModalTaskDetails}
                           taskId={taskId}
                           loadTasksNow={loadCompletedTasksNow}
                           loadTasksImportant={loadCompletedTasksImportant}
                           loadTasksRegular={loadCompletedTasksRegular}
                           commentContent={commentContent}
                           setCommentContent={setCommentContent}
                        />
                     </main>
                  </>
               }
            </div>
         </div>
      </DragDropContext >
   )
}

export default TasksCompleted
