import React, { useState, useEffect, createContext, useContext } from 'react'
import { toast } from 'react-toastify'

import api from '../api'
import { userKeyStorage, userTokenStorage, userIdStorage, userAdminStorage } from '../../utils/defaultValues'

const AuthContext = createContext({})

export function AuthProvider({ children }) {
   const [loading, setLoading] = useState(true)
   const [loadingButton, setLoadingButton] = useState('default')

   const [user, setUser] = useState({})
   const [userId, setUserId] = useState('')
   const [userAdmin, setUserAdmin] = useState(null)
   const [userStorage, setUserStorage] = useState({})
   const [idStorage, setIdStorage] = useState('')
   const [adminStorage, setAdminStorage] = useState(false)
   const [tokenValid, setTokenValid] = useState(false)

   const [userColor, setUserColor] = useState('')

   const [position, setPosition] = useState('')

   useEffect(() => {
      async function loadStorageData() {
         const storageUser = localStorage.getItem(userKeyStorage)
         const storageToken = localStorage.getItem(userTokenStorage)
         const storageId = localStorage.getItem(userIdStorage)
         const storageUserAdmin = localStorage.getItem(userAdminStorage)

         if (storageUser && storageToken && storageId) {
            api.defaults.headers.Authorization = `bearer ${storageToken}`
            api.defaults.headers['x-access-id'] = Number(storageId)

            setTokenValid(true)
            setIdStorage(Number(storageId))
            setAdminStorage(storageUserAdmin)
            setUserStorage(JSON.parse(storageUser))

            validateToken(storageToken)

            setLoading(false)

         } else {
            setLoading(false)
         }
      }

      loadStorageData()
   }, [])

   useEffect(() => {
      navigator.geolocation.getCurrentPosition(function (position) {
         setPosition(position.coords);
      })

   }, []);

   useEffect(() => {
      let colors = ['#E62631', '#A61D17', '#732A24']

      setUserColor(colors[Math.floor(Math.random() * colors.length)])

   }, [user])


   async function onSignin(email, password, token, password_valid) {
      const data = { email, password, token, password_valid, platform: 'GoGoGo' }

      setLoadingButton('processing')

      try {
         const res = await api.post('signin', data)

         setUser(res.data)
         setUserId(res.data.id)
         setUserAdmin(res.data.admin)
         setTokenValid(true)

         api.defaults.headers.Authorization = `bearer ${res.data.token}`
         api.defaults.headers['x-access-id'] = res.data.id

         localStorage.setItem(userKeyStorage, JSON.stringify(res.data))
         localStorage.setItem(userTokenStorage, res.data.token)
         localStorage.setItem(userIdStorage, JSON.stringify(res.data.id))
         localStorage.setItem(userAdminStorage, JSON.stringify(res.data.admin))

      } catch (e) {
         setLoadingButton('default')

         e.response.data.errors.forEach(error => toast.error(error))

         setUser({})
         setUserId('')
         setUserAdmin(false)
         setTokenValid(false)

         localStorage.removeItem(userKeyStorage)
         localStorage.removeItem(userTokenStorage)
         localStorage.removeItem(userIdStorage)
         localStorage.removeItem(userAdminStorage)
      }
   }


   async function signInWithGoogle(response) {
      if (response.error) {
         console.debug(response)

         return
      }

      const { email, name, imageUrl } = response.profileObj

      setLoadingButton('processing')

      try {
         const data = {
            name,
            email,
            password: process.env.REACT_APP_GOOGLE_PASSWORD,
            confirmPassword: process.env.REACT_APP_GOOGLE_PASSWORD,
            avatar: imageUrl,
            latitude: String(position.latitude),
            longitude: String(position.longitude),
            platform: 'Google'
         }


         const res = await api.post('signinGoogleFacebook', data)

         setUser(res.data)
         setUserId(res.data.id)
         setUserAdmin(res.data.admin)
         setTokenValid(true)

         api.defaults.headers.Authorization = `bearer ${res.data.token}`
         api.defaults.headers['x-access-id'] = res.data.id

         localStorage.setItem(userKeyStorage, JSON.stringify(res.data))
         localStorage.setItem(userTokenStorage, res.data.token)
         localStorage.setItem(userIdStorage, JSON.stringify(res.data.id))
         localStorage.setItem(userAdminStorage, JSON.stringify(res.data.admin))

      } catch (e) {
         setLoadingButton('default')

         e.response.data.errors.forEach(error => toast.error(error))

         setUser({})
         setUserId('')
         setUserAdmin(false)
         setTokenValid(false)

         localStorage.removeItem(userKeyStorage)
         localStorage.removeItem(userTokenStorage)
         localStorage.removeItem(userIdStorage)
         localStorage.removeItem(userAdminStorage)

         return false;
      }
   };

   async function signInWithFacebook(response) {
      if (response.status === undefined) {
         return
      }

      const { email, name, picture } = response

      setLoadingButton('processing')

      try {

         const data = {
            name,
            email,
            password: process.env.REACT_APP_FACEBOOK_PASSWORD,
            confirmPassword: process.env.REACT_APP_FACEBOOK_PASSWORD,
            avatar: picture.data.url,
            latitude: String(position.latitude),
            longitude: String(position.longitude),
            platform: 'Facebook'
         }

         const res = await api.post(`signinGoogleFacebook`, data)

         setUser(res.data)
         setUserId(res.data.id)
         setUserAdmin(res.data.admin)
         setTokenValid(true)

         api.defaults.headers.Authorization = `bearer ${res.data.token}`
         api.defaults.headers['x-access-id'] = res.data.id

         localStorage.setItem(userKeyStorage, JSON.stringify(res.data))
         localStorage.setItem(userTokenStorage, res.data.token)
         localStorage.setItem(userIdStorage, JSON.stringify(res.data.id))
         localStorage.setItem(userAdminStorage, JSON.stringify(res.data.admin))

      } catch (e) {
         setLoadingButton('default')

         e.response.data.errors.forEach(error => toast.error(error))

         setUser({})
         setUserId('')
         setUserAdmin(false)
         setTokenValid(false)

         localStorage.removeItem(userKeyStorage)
         localStorage.removeItem(userTokenStorage)
         localStorage.removeItem(userIdStorage)
         localStorage.removeItem(userAdminStorage)

         return false;
      }
   }

   async function onSignOut() {
      setUser({})
      setUserId('')
      setUserAdmin(false)
      setTokenValid(false)

      setLoadingButton('default')

      localStorage.removeItem(userKeyStorage)
      localStorage.removeItem(userTokenStorage)
      localStorage.removeItem(userIdStorage)
      localStorage.removeItem(userAdminStorage)
   }

   async function validateToken(token) {
      if (token) {
         try {
            await api.post('validateToken', { token })

            setTokenValid(true)
         } catch (e) {
            toast.error(e.response.data)

            localStorage.removeItem(userKeyStorage)
            localStorage.removeItem(userTokenStorage)
            localStorage.removeItem(userIdStorage)
            localStorage.removeItem(userAdminStorage)

            setLoadingButton('default')

            setUser({})
            setUserId('')
            setUserAdmin(false)
            setTokenValid(false)
         }
      } else {
         localStorage.removeItem(userKeyStorage)
         localStorage.removeItem(userTokenStorage)
         localStorage.removeItem(userIdStorage)
         localStorage.removeItem(userAdminStorage)

         setLoadingButton('default')

         setUser({})
         setUserId('')
         setUserAdmin(false)
         setTokenValid(false)
      }
   }


   return (
      <AuthContext.Provider value={{
         tokenValid,
         user,
         userId,
         userAdmin,
         userStorage,
         userColor,
         idStorage,
         adminStorage,
         loading,
         loadingButton,
         onSignin,
         onSignOut,
         signInWithGoogle,
         signInWithFacebook
      }}>
         {children}
      </AuthContext.Provider>
   )
}

export function useAuth() {
   const context = useContext(AuthContext)

   return context
}
