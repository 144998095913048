import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'
import { FaRegTrashAlt, FaUserPlus } from 'react-icons/fa'

import Input from '../Input'

import '../../styles/components/modal/ModalAddTeams.css'

const ModalAddTeams = (props) => {

   const [t] = useTranslation()

   const keyHandlerAddTeam = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
         e.preventDefault();

         props.handleAddTeams();
      }
   };

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalAddTeams'
         centered
         size='md'
         autoFocus={false}
      >
         <ModalBody className="justify-content-middle modal-body">
            <h2 className="mt-2">{t('modal.addTeam.title')}</h2>

            <form onSubmit={props.handleAddUsers}>
               <div>
                  <fieldset>
                     <Input
                        name='teamName'
                        label={t('modal.addTeam.teamNameLabel')}
                        placeholder={t('modal.addTeam.teamNamePlaceholder')}
                        type='text'
                        value={props.teamName}
                        onChange={e => props.setTeamName(e.target.value)}
                        onKeyDown={e => keyHandlerAddTeam(e)}
                        autoComplete='off'
                        autoFocus={true}
                     />

                  </fieldset>

                  {props.teamId &&
                     <div className='input-container'>
                        <Input
                           name='email'
                           label={t('modal.addTeam.addUserLabel')}
                           placeholder={t('modal.addTeam.addUserPlaceholder')}
                           type='email'
                           value={props.email}
                           onChange={e => props.setEmail(e.target.value)}
                           autoComplete='off'
                           autoFocus={true}
                        />
                        <div className='icon-container' onClick={props.addUsers}>
                           <FaUserPlus />
                        </div>

                        {props.usersToAdd.length !== 0 &&
                           <div className="userAddContainer">
                              {props.usersToAdd.map(user => {
                                 return (
                                    <div className='emailAddedContainer' key={user}>
                                       <span>{user}</span>
                                       <FaRegTrashAlt onClick={props.removeUsers} />
                                    </div>
                                 )
                              })}
                           </div>
                        }
                     </div>
                  }
               </div>

               {props.teamId ?
                  props.loadingButton === 'default' ? (
                     <button
                        type='submit'
                        className='addTeamButton'
                     >
                        {t('modal.addTeam.doneButton')}
                     </button>
                  ) : (
                     <button
                        className='addTeamButton'
                        style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                        disabled
                     >
                        <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                        <span className="sr-only"></span>
                     </button>
                  ) :
                  props.loadingButton === 'default' ? (
                     <div>
                        <button
                           type='button'
                           onClick={props.handleAddTeams}
                           className='addTeamButton'
                        >
                           {t('modal.addTeam.addButton')}
                        </button>
                     </div>
                  ) : (
                     <button
                        className='addTeamButton'
                        style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                        disabled
                     >
                        <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                        <span className="sr-only"></span>
                     </button>
                  )
               }

            </form>

         </ModalBody>

      </Modal>
   )
}

export default ModalAddTeams
