import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import translate_pt from './locale/pt/translation.json'
import translate_en from './locale/en/translation.json'

i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      load: 'unspecific',
      // lng: navigator.language,
      fallbackLng: ['pt', 'en'],
      whitelist: ['pt', 'en'],
      debug: false,
      ns: ['translation'],
      defaultNS: 'translation',
      resources: {
         en: {
            translation: translate_en,
         },
         pt: {
            translation: translate_pt,
         }
      },
      interpolation: {
         escapeValue: false,
      },
      react: {
         wait: true
      }
   });


export default i18n
