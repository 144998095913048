import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'

import api from '../../services/api'

import '../../styles/pages/app/Notifications.css'

const Notifications = () => {

   const [loading, setLoading] = useState(true)

   const [notificationsData, setNotificationsData] = useState([])
   const [pageSizeAll, setPageSizeAll] = useState()

   const [t] = useTranslation()

   useEffect(() => {
      async function loadNotificationsReceived() {
         if (pageSizeAll) {
            const res = await api.get(`notificationsReceived?pageSize=${pageSizeAll}`)

            setNotificationsData(res.data)

         } else {
            const res = await api.get('notificationsReceived')

            setNotificationsData(res.data)
         }

         setLoading(false)
      }

      loadNotificationsReceived()

   }, [pageSizeAll])


   async function loadAllNotificationsReceived() {
      setPageSizeAll(10000)
   }

   return (
      <div id='page-notifications'>
         <PageHeader setNotificationsData={setNotificationsData} />
         <div className='container'>
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <main>
                  <div className="column">
                     <div className="header-container">
                        <h1>{t('notification.title')}</h1>
                     </div>

                     <div
                        className="column-two-parts"
                     >

                        {notificationsData.length === 0 ?
                           <div className='no-notification'>
                              <h1>{t('notification.noNotifications')}</h1>
                           </div>
                           :
                           <>
                              {notificationsData.map(notification => {
                                 return (
                                    <div key={notification.id} className="card-notification">
                                       <div className="card-content">
                                          <h1>"{(notification.motivation_phrases)}"</h1>
                                          <h3>{t('notification.motivationSentBy')} {notification.from.split(' ')[0]} {notification.from.split(' ')[1] !== undefined && notification.from.split(' ')[1]}</h3>
                                       </div>

                                       <div className="date-container">
                                          <span>{moment(notification.created_at).format('DD/MMM HH:mm')}</span>
                                       </div>
                                    </div>
                                 )
                              })}

                              {pageSizeAll === undefined && notificationsData.length === 10 &&
                                 <button className='button-load-more' onClick={loadAllNotificationsReceived}>
                                    <span>{t('notification.viewPrevious')}</span>
                                 </button>
                              }
                           </>
                        }
                     </div>

                  </div>

               </main>
            }
         </div>
      </div>
   )
}

export default Notifications
