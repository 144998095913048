import React from 'react'
import ReactLoading from 'react-loading'

import '../styles/components/Loading.css'

const Loading = (props) => {
   return (
      <div className='loading-container' style={{ height: props.height, width: props.width }}>
         <ReactLoading
            type={props.type}
            color='#E62631'
            height={50}
            width={50}
         />
      </div>

   )
}

export default Loading
