import React, { useState, useEffect } from 'react'

import Loading from '../components/Loading'
import { useAuth } from '../services/contexts/auth'

import AuthRoutes from './auth.routes'
import AppRoutes from './app.routes'
import AppAdminRoutes from './app.admin.routes'

const Routes = () => {
   const [userLoggedIsAdmin, setUserLoggedIsAdmin] = useState(false);

   const { userAdmin, adminStorage, tokenValid, loading } = useAuth();

   useEffect(() => {
      if (userAdmin) {
         setUserLoggedIsAdmin(userAdmin)

      } else {
         setUserLoggedIsAdmin(adminStorage)
      }

   }, [userAdmin, adminStorage])

   if (loading) {
      return (
         <Loading type='balls' />
      )
   }

   return (
      tokenValid ?
         userLoggedIsAdmin ?
            <AppAdminRoutes />
            :
            <AppRoutes />
         :
         <AuthRoutes />
   )
}

export default Routes
