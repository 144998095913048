import React, { useState, useEffect, useCallback, useRef, forwardRef } from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import DatePicker from 'react-datepicker'
import moment from 'moment'
// import crypto from 'crypto-js'

import { AiFillFileText } from 'react-icons/ai'
import { FiLock, FiPlus, FiSend, FiX, FiCalendar } from 'react-icons/fi'
import { HiCheckCircle } from 'react-icons/hi'
import { MdArrowDropDown } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { BiDownload } from 'react-icons/bi'

import Loading from '../Loading'
import DropdownChecklist from '../DropdownChecklist'
import ModalChangePrivateConfirm from './ModalChangePrivateConfirm'
import ModalSaveConfirmation from './ModalSaveConfirmation'

import api from '../../services/api'
import { FirstLetterUp } from '../../utils/functions'

import '../../styles/components/modal/ModalTaskDetails.css'


const ModalTaskDetails = forwardRef((props, ref) => {
   const today = new Date()
   const tomorrow = today.setDate(today.getDate() + 1)

   const [loading, setLoading] = useState(true)
   const [loadingImage, setLoadingImage] = useState(true)

   const [modalChangePrivate, setModalChangePrivate] = useState(false)
   const [modalSaveConfirmation, setModalSaveConfirmation] = useState(false)

   const [showTeamOptions, setShowTeamOptions] = useState(false)
   const [showTypeOptions, setShowTypeOptions] = useState(false)
   const [showUsersOptions, setShowUsersOptions] = useState(false)

   const [showMenuChecklistIndex, setShowMenuChecklistIndex] = useState('')
   const [indexMasterChecklist, setIndexMasterChecklist] = useState('')
   const [indexSubChecklist, setIndexSubChecklist] = useState('')

   const [taskName, setTaskName] = useState('')
   const [taskDescription, setTaskDescription] = useState('')
   const [taskType, setTaskType] = useState(null)
   const [taskPrivate, setTaskPrivate] = useState('')
   const [teamId, setTeamId] = useState('')
   const [taskTeam, setTaskTeam] = useState('')
   const [responsibleUser, setResponsibleUser] = useState('')
   const [taskCreatedUser, setTaskCreatedUser] = useState('')
   const [taskStartDate, setTaskStartDate] = useState(null)
   const [taskCompleted, setTaskCompleted] = useState(false)
   const [wasTaskPrivate, setWasTaskPrivate] = useState(false)

   const [checklistTitle, setChecklistTitle] = useState('Checklist')
   const [checklistContent, setChecklistContent] = useState([])
   const [checklistContentEdit, setChecklistContentEdit] = useState('')

   const [subChecklistInfo, setSubChecklistInfo] = useState([])

   const [teamsData, setTeamsData] = useState([])
   const [filesData, setFilesData] = useState([])
   const [checklistData, setChecklistData] = useState([])
   const [commentsData, setCommentsData] = useState([])
   const [userOnTeamSelected, setUserOnTeamSelected] = useState([])

   const [userIdSelected, setUserIdSelected] = useState(null)

   const [startDate, setStartDate] = useState(tomorrow)

   const [hadChanges, setHadChanges] = useState(false)

   const task_id = props.taskId
   // const task_id = crypto.DES.decrypt(decodeURIComponent(id), 'idTask').toString(crypto.enc.Utf8);

   const chosenDateFormated = moment(taskStartDate).format('YYYY-MM-DD')

   const [t] = useTranslation()

   const subChecklistInput = useRef(null)
   const masterChecklistInput = useRef(null)
   const dropdownMenuChecklist = useRef(null)
   const dropdownTeamOptions = useRef(null)
   const dropdownTypeOptions = useRef(null)
   const dropdownUsersOptions = useRef(null)
   const outsideModalClickRef = useRef(null)
   const othersModalRef = useRef(null)

   const loadTaskDetail = useCallback(async () => {
      if (!task_id) {
         setLoading(true)

         return false

      } else {
         setLoading(true)

         const res = await api.get(`tasks/${task_id}`)

         setTaskName(res.data.task_name)
         setTaskDescription(res.data.task_description)
         setTaskType(res.data.type)
         setTaskPrivate(res.data.private)
         setWasTaskPrivate(res.data.private)
         setTaskTeam(res.data.team_name)
         setTeamId(res.data.team_id)
         setResponsibleUser(res.data.task_responsible_by)
         setTaskCreatedUser(res.data.task_created_by)
         setTaskStartDate(res.data.task_start_on)
         setTaskCompleted(res.data.completed)

         setLoading(false)
      }
   }, [task_id])

   const loadFiles = useCallback(async () => {
      if (!task_id) {
         setLoading(true)

         return false

      } else {
         const res = await api.get(`files?task_id=${task_id}`)

         setFilesData(res.data)

         setTimeout(() => {
            setLoadingImage(false)
         }, 1000)
      }

   }, [task_id])

   const loadChecklists = useCallback(async () => {
      if (!task_id) {
         setLoading(true)

         return false

      } else {
         const res = await api.get(`checklists?task_id=${task_id}`)

         setChecklistData(res.data)
      }

   }, [task_id])

   const loadComments = useCallback(async () => {
      if (!task_id) {
         setLoading(true)

         return false

      } else {
         const res = await api.get(`comments?task_id=${task_id}`)

         setCommentsData(res.data)

         scrollToBottom()
      }

   }, [task_id])

   const loadTeams = useCallback(async () => {
      const res = await api.get(`teams`)

      setTeamsData(res.data)
   }, [])

   useEffect(() => {
      if (teamId) {
         async function loadTeamSelected() {
            const res = await api.get(`teams/${teamId}`)

            setUserOnTeamSelected(res.data.users)
         }

         loadTeamSelected()
      }

   }, [teamId])

   const handleShowInputMasterChecklist = useCallback((e) => {
      if (masterChecklistInput.current && indexMasterChecklist !== '') {
         if (!masterChecklistInput.current.contains(e.target)) {
            setIndexMasterChecklist('')
         }

      } else if (!masterChecklistInput.current && !subChecklistInput.current) {
         setIndexMasterChecklist('')
      }

   }, [indexMasterChecklist])

   const handleShowInputSubChecklist = useCallback((e) => {
      if (subChecklistInput.current && indexSubChecklist !== '') {
         if (!subChecklistInput.current.contains(e.target)) {
            setIndexSubChecklist('')
            setIndexMasterChecklist('')
         }

      } else if (!subChecklistInput.current && indexSubChecklist !== '') {
         setIndexSubChecklist('')
      }

   }, [indexSubChecklist])

   const handleShowMenuChecklist = useCallback((e) => {
      if (dropdownMenuChecklist.current && showMenuChecklistIndex !== '') {
         if (!dropdownMenuChecklist.current.contains(e.target)) {
            setShowMenuChecklistIndex('')
         }

      } else if (!dropdownMenuChecklist.current && showMenuChecklistIndex !== '') {
         setShowMenuChecklistIndex('')
      }

   }, [showMenuChecklistIndex])

   const handleShowTeamOptions = useCallback((e) => {
      if (dropdownTeamOptions.current && showTeamOptions) {
         if (!dropdownTeamOptions.current.contains(e.target)) {
            setShowTeamOptions(false)
         }

      } else if (!dropdownTeamOptions.current && showTeamOptions) {
         setShowTeamOptions(false)

      } else {
         setShowTeamOptions(true)
      }

   }, [showTeamOptions])

   const handleShowTypeOptions = useCallback((e) => {
      if (dropdownTypeOptions.current && showTypeOptions) {
         if (!dropdownTypeOptions.current.contains(e.target)) {
            setShowTypeOptions(false)
         }

      } else if (!dropdownTypeOptions.current && showTypeOptions) {
         setShowTypeOptions(false)

      } else {
         setShowTypeOptions(true)
      }

   }, [showTypeOptions])

   const handleShowUsersOptions = useCallback((e) => {
      if (dropdownUsersOptions.current && showUsersOptions) {
         if (!dropdownUsersOptions.current.contains(e.target)) {
            setShowUsersOptions(false)
         }

      } else if (!dropdownUsersOptions.current && showUsersOptions) {
         setShowUsersOptions(false)

      } else {
         setShowUsersOptions(true)
      }

   }, [showUsersOptions])

   useEffect(() => {
      loadTaskDetail()

   }, [loadTaskDetail])

   useEffect(() => {
      loadFiles()

   }, [loadFiles])

   useEffect(() => {
      loadChecklists()

   }, [loadChecklists])

   useEffect(() => {
      loadComments()

   }, [loadComments])

   useEffect(() => {
      loadTeams()

   }, [loadTeams])

   useEffect(() => {
      if (indexMasterChecklist !== '') {
         document.addEventListener("click", handleShowInputMasterChecklist)

         return () => document.removeEventListener("click", handleShowInputMasterChecklist);
      }

   }, [indexMasterChecklist, handleShowInputMasterChecklist])

   useEffect(() => {
      if (indexSubChecklist !== '') {
         document.addEventListener("click", handleShowInputSubChecklist)

         return () => document.removeEventListener("click", handleShowInputSubChecklist);
      }

   }, [indexSubChecklist, handleShowInputSubChecklist])

   useEffect(() => {
      if (showMenuChecklistIndex !== '') {
         document.addEventListener("click", handleShowMenuChecklist)

         return () => document.removeEventListener("click", handleShowMenuChecklist);
      }

   }, [showMenuChecklistIndex, handleShowMenuChecklist])

   useEffect(() => {
      if (showTeamOptions) {
         document.addEventListener("click", handleShowTeamOptions)

         return () => document.removeEventListener("click", handleShowTeamOptions);
      }

   }, [showTeamOptions, handleShowTeamOptions])

   useEffect(() => {
      if (showTypeOptions) {
         document.addEventListener("click", handleShowTypeOptions)

         return () => document.removeEventListener("click", handleShowTypeOptions);
      }

   }, [showTypeOptions, handleShowTypeOptions])

   useEffect(() => {
      if (showUsersOptions) {
         document.addEventListener("click", handleShowUsersOptions);

         return () => document.removeEventListener("click", handleShowUsersOptions);
      }

   }, [showUsersOptions, handleShowUsersOptions])

   async function handleUpdateTaskInfo(e) {
      e.preventDefault()

      const startDate = taskType === 'future' ? taskStartDate : null

      const data = { taskName, taskDescription, taskType, team_id: teamId, taskStartDate: startDate }

      try {
         await api.put(`tasks/${task_id}`, data)

         loadTaskDetail()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleSubmitComment(e) {
      e.preventDefault()

      const data = { commentContent: props.commentContent, task_id }

      try {
         await api.post('comments', data)

         props.setCommentContent('')

         loadComments()

         scrollToBottom()

      } catch (e) {
         props.setCommentContent('')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleAddChecklistMaster(e) {
      e.preventDefault()

      setChecklistTitle('Checklist')

      const data = { checklistTitle, task_id }

      try {
         await api.post('checklists', data)

         setShowMenuChecklistIndex('')

         loadChecklists()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleDeleteChecklistMaster(e, checklistId) {
      e.preventDefault()

      try {
         await api.delete(`checklists/${checklistId}`)

         setShowMenuChecklistIndex('')

         loadChecklists()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleAddChecklist(e, i, checklist_id) {
      e.preventDefault()

      const data = { checklistContent: checklistContent[i], checklist_id }

      try {
         await api.post('checklistsSub', data)

         setChecklistContent([])

         loadChecklists()

      } catch (e) {
         setChecklistContent([])

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleEditChecklistTitle(e, checklistId) {
      e.preventDefault()

      const data = { checklistTitle }

      try {
         await api.put(`checklists/${checklistId}?task_id=${task_id}`, data)

         setIndexMasterChecklist('')

         return loadChecklists()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }

   }

   async function handleEditChecklist(e, subChecklistId) {
      e.preventDefault()

      const res = await api.get(`checklistsSub/${subChecklistId}`)

      const doneChecklist = res.data.sub_checklist_done

      const data = { checklistContent: checklistContentEdit, sub_checklist_done: doneChecklist }

      try {
         if (checklistContentEdit === '') {
            await api.delete(`checklistsSub/${subChecklistId}`)

            setIndexMasterChecklist('')

            return loadChecklists()
         }

         await api.put(`checklistsSub/${subChecklistId}`, data)

         setIndexMasterChecklist('')
         setIndexSubChecklist('')

         return loadChecklists()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function handleChecklistIdConcat() {

      let checklistId = []
      const checkboxes = document.querySelectorAll('input[type="checkbox"]')

      checkboxes.forEach((checkbox) => {
         if (checkbox.value !== 'on') {
            checklistId.push([checkbox.value, checkbox.checked])
         }
      })

      setSubChecklistInfo(checklistId)
      setHadChanges(true)
   }


   async function handleChecklistDone(e) {
      e.preventDefault()

      for (let i = 0; i < subChecklistInfo.length; i++) {

         const res = await api.get(`checklistsSub/${subChecklistInfo[i][0]}`)

         const contentExist = res.data.sub_checklist_content

         const data = { checklistContent: contentExist, sub_checklist_done: subChecklistInfo[i][1] }

         try {
            await api.put(`checklistsSub/${subChecklistInfo[i][0]}`, data)

         } catch (e) {
            e.response.data.errors.forEach(error => toast.error(error))
         }
      }

      loadChecklists()
   }

   async function handleCompleteTask(e) {
      e.preventDefault()

      const completed = !taskCompleted

      const data = { completed }

      try {
         await api.put(`taskCompleted/${task_id}`, data)

         props.toggle()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }

      props.loadTasksNow()
      props.loadTasksImportant()
      props.loadTasksRegular()

   }

   const updateFieldChanged = useCallback((e, i) => {
      checklistContent[i] = e.target.value

      setChecklistContent([...checklistContent])
   }, [checklistContent])

   async function openMasterChecklistEditEvent(e, indexMaster, checklistId) {
      e.preventDefault()

      const res = await api.get(`checklists/${checklistId}`)

      setChecklistTitle(res.data.checklist_title)
      setIndexMasterChecklist(indexMaster)
   }

   async function openSubChecklistEditEvent(e, indexSub, indexMaster, subChecklistId) {
      e.preventDefault()

      const res = await api.get(`checklistsSub/${subChecklistId}`)

      setChecklistContentEdit(res.data.sub_checklist_content)
      setIndexMasterChecklist(indexMaster)
      setIndexSubChecklist(indexSub)
   }

   const keyHandlerSubmitComment = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
         e.preventDefault()

         handleSubmitComment(e)
      }
   }

   const keyHandlerSubmitAddChecklist = (e, i, checklist_id) => {
      if (e.key === 'Enter' && !e.shiftKey) {
         e.preventDefault()

         handleAddChecklist(e, i, checklist_id)

         setChecklistContent([])
      }
   }

   const keyHandlerSubmitSubChecklistEdit = (e, subChecklistId) => {
      if (e.key === 'Enter') {
         e.preventDefault()

         handleEditChecklist(e, subChecklistId)

         setIndexSubChecklist('')
         setIndexMasterChecklist('')
      }
   }

   const keyHandlerSubmitChecklistTitleEdit = (e, checklistId) => {
      if (e.key === 'Enter') {
         e.preventDefault()

         handleEditChecklistTitle(e, checklistId)

         setIndexMasterChecklist('')
      }
   }

   async function handleEditTeam(e) {
      e.preventDefault()

      const data = { taskName, taskDescription, taskType, team_id: teamId }

      try {
         await api.put(`tasks/${task_id}`, data)

         loadTaskDetail()

         setShowTeamOptions(false)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleEditTaskType(e) {
      e.preventDefault()

      const data = { taskName, taskDescription, taskType, team_id: teamId }

      try {
         await api.put(`tasks/${task_id}`, data)

         loadTaskDetail()

         setShowTypeOptions(false)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const handleSubmitFiles = useCallback((e) => {
      setLoadingImage(true)

      new Promise(function (resolve, reject) {

         if (e.target.files) {
            const selectedImages = Array.from(e.target.files)

            return resolve(selectedImages)

         } else if (e.clipboardData.files.length === 0) {
            setLoadingImage(false)
            return

         } else if (e.clipboardData.files) {
            let selectedImages = []

            for (let i = 0; i < e.clipboardData.items.length; i++) {
               var item = e.clipboardData.items[i];


               selectedImages = selectedImages.concat(item.getAsFile());
            }

            return resolve(selectedImages)

         } else {
            setLoadingImage(false)
            return
         }

      }).then(async (res) => {
         try {
            const data = new FormData()

            data.append('task_id', task_id)
            res.forEach(file => {
               data.append('files', file)
            })

            await api.post('files', data)

            loadFiles()

         } catch (e) {
            if (!e.response) {
               setLoadingImage(false)

               return toast.error(t('taskDetails.fileSizeError'))
            }

            setLoadingImage(false)
            e.response.data.errors.forEach(error => toast.error(error))
         }
      })

   }, [loadFiles, t, task_id])

   useEffect(() => {

      document.addEventListener("paste", handleSubmitFiles)

      return () => document.removeEventListener("paste", handleSubmitFiles);

   }, [handleSubmitFiles])

   async function handleDeleteFile(e, fileId) {
      e.preventDefault()

      try {
         await api.delete(`files/${fileId}`)

         loadFiles()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function openFile(file) {
      window.open(file, '_blank')
   }

   function downloadFile(fileURL, fileName) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", fileURL, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
         var urlCreator = window.URL || window.webkitURL;
         var imageUrl = urlCreator.createObjectURL(this.response);
         var tag = document.createElement('a');
         tag.href = imageUrl;
         tag.download = fileName;
         document.body.appendChild(tag);
         tag.click();
         document.body.removeChild(tag);
      }
      xhr.send();
   }

   function scrollToBottom() {
      const chat = document.getElementById("chatList")
      if (chat) {
         chat.scrollTo(0, chat.scrollHeight)
      }
   }

   async function handleUpdateResponsibleUser(e) {
      e.preventDefault()

      const data = { team_id: teamId, userIdSelected }

      if (userIdSelected !== null) {
         try {
            await api.put(`tasksResponsability/${task_id}`, data)

            loadTaskDetail()

            setShowUsersOptions(false)
            setUserIdSelected(null)

         } catch (e) {
            setUserIdSelected(null)
            e.response.data.errors.forEach(error => toast.error(error))
         }
      }
   }

   function handleOpenModalChangePrivate() {
      setModalChangePrivate(!modalChangePrivate)
   }

   async function handleSubmitAll(e) {
      e.preventDefault()

      handleUpdateTaskInfo(e)
      handleEditTeam(e)
      handleEditTaskType(e)

      handleUpdateResponsibleUser(e)

      handleChecklistDone(e)

      props.setCommentContent('')
      setHadChanges(false)

      if (modalChangePrivate) {
         handleOpenModalChangePrivate()
      }

      if (modalSaveConfirmation) {
         handleEventToOpenModalConfirmSave()
      }

      props.toggle()

      if (props.loadTasksNow) {
         await props.loadTasksNow()
         await props.loadTasksImportant()
         await props.loadTasksRegular()

      } else {
         await props.loadFutureTasks()
         await props.loadNotes()
      }
   }

   const onDrop = useCallback(acceptedFiles => {
      setLoadingImage(true)

      new Promise(function (resolve, reject) {

         if (acceptedFiles) {
            const selectedImages = Array.from(acceptedFiles)

            return resolve(selectedImages)

         } else {
            setLoadingImage(false)
            return
         }

      }).then(async (res) => {
         try {
            const data = new FormData()

            data.append('task_id', task_id)
            res.forEach(file => {
               data.append('files', file)
            })

            await api.post('files', data)

            loadFiles()

         } catch (e) {
            if (!e.response) {
               setLoadingImage(false)

               return toast.error(t('taskDetails.fileSizeError'))
            }

            setLoadingImage(false)
            e.response.data.errors.forEach(error => toast.error(error))
         }
      })

      // const files = acceptedFiles

      // const fileUrl = URL.createObjectURL(files)


   }, [loadFiles, t, task_id])

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      multiple: true
   })

   const onChangeDatePicker = (selectedDate) => {
      const currentDate = selectedDate || tomorrow
      const dateMillis = currentDate.valueOf()

      setStartDate(selectedDate)
      setTaskStartDate(moment(dateMillis).format('YYYY-MM-DD'))
      setHadChanges(true)
   }

   const CustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            onClick={onClick}
            ref={ref}
            className='button-date'
         >
            <FiCalendar color={'#333333'} size={15} />
            <span>{taskStartDate === null ? '-' : moment(chosenDateFormated).format('DD-MMM-YYYY')}</span>
         </button>
      )
   })

   function handleClickOnSaveButton(e) {

      if (wasTaskPrivate && !taskPrivate) {
         handleOpenModalChangePrivate()

      } else if (taskType === 'future' && taskStartDate === null) {
         toast.error(t('taskDetails.infoToastFutureTasks'))

      } else {
         handleSubmitAll(e)
      }
   }

   const handleEventToOpenModalConfirmSave = useCallback(() => {
      setModalSaveConfirmation(!modalSaveConfirmation)
   }, [modalSaveConfirmation])

   function handleOpenModalConfirmIfSaveOrNot() {
      if (modalSaveConfirmation) {
         handleEventToOpenModalConfirmSave()
      }

      props.toggle()
   }

   useEffect(() => {
      const handleOutsideClick = (event) => {
         if (!outsideModalClickRef.current.contains(event.target)) {
            handleEventToOpenModalConfirmSave()
         }
      };

      if (props.isOpen && !modalChangePrivate && hadChanges) {
         document.addEventListener('mousedown', handleOutsideClick)
      }

      return () => {
         document.removeEventListener('mousedown', handleOutsideClick)
      };

   }, [props.isOpen, modalChangePrivate, hadChanges, handleEventToOpenModalConfirmSave])

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         onClosed={() => setStartDate(tomorrow)}
         id='modal-tasks-details'
         centered
         backdrop={hadChanges ? 'static' : true}
         size='lg'
      >
         <div ref={outsideModalClickRef}>
            <ModalBody className="modal-body">
               {loading ?
                  <Loading
                     type='balls'
                  />
                  :
                  <div className='container'>
                     <main>
                        <div className='title-button'>
                           <div className="title">
                              <input
                                 id='title'
                                 name='title'
                                 value={taskName}
                                 onChange={e => {
                                    setTaskName(e.target.value)
                                    setHadChanges(true)
                                 }}
                              // onBlur={e => handleUpdateTaskInfo(e)}
                              />

                              <button
                                 onClick={e => handleClickOnSaveButton(e)}
                                 type='button'
                                 className='save-task-button'
                              >
                                 {t('taskDetails.saveTasksButton')}
                              </button>
                           </div>

                           <div className="user-created-container">
                              <span>{t('taskDetails.userTaskCreated')}</span>

                              <div className="user-created-pill">
                                 <span>{taskCreatedUser}</span>
                              </div>
                           </div>
                        </div>

                        <div className="team-type-and-user-container">
                           <div className="team-and-type-container">

                              <div className="dropdown">
                                 <div className='team-container-general'>
                                    <span className='team-title'>{t('taskDetails.team')}</span>

                                    {taskPrivate ?
                                       <div className="private-container" onClick={handleShowTeamOptions}>
                                          <FiLock />
                                          <span>{t('task.private')}</span>
                                          <MdArrowDropDown size={20} />
                                       </div>
                                       :
                                       <div className="pill" onClick={handleShowTeamOptions}>
                                          <span>{taskTeam}</span>
                                          <MdArrowDropDown size={20} color='#FFFFFF' />
                                       </div>
                                    }
                                    <div ref={dropdownTeamOptions} className='menu-select-team'>
                                       {showTeamOptions &&
                                          <div className="select-container">
                                             <div
                                                className={taskTeam === '' || taskTeam === undefined ? "item-team-selected" : "items-team"}
                                                onClick={() => {
                                                   setTaskPrivate(true)
                                                   setTaskTeam('')
                                                   setTeamId(undefined)
                                                   setShowTeamOptions(false)
                                                   setWasTaskPrivate(true)
                                                   setHadChanges(true)
                                                }}
                                             >
                                                <span>{t('task.private')}</span>
                                             </div>

                                             {teamsData.map(team => {
                                                return (
                                                   <div
                                                      onClick={() => {
                                                         teamId === '' || teamId === undefined ? setWasTaskPrivate(true) : setWasTaskPrivate(false)
                                                         setTaskPrivate(false)
                                                         setTaskTeam(team.team_name)
                                                         setTeamId(team.id)
                                                         setShowTeamOptions(false)
                                                         setHadChanges(true)
                                                      }}
                                                      className={taskTeam === team.team_name ? "item-team-selected" : "items-team"}
                                                      key={team.id}
                                                   >
                                                      {team.team_name}
                                                   </div>
                                                )
                                             })}

                                          </div>
                                       }
                                    </div>
                                 </div>
                              </div>

                              <div className="dropdown">
                                 <div className="type-container">
                                    <span>{t('taskDetails.type')}</span>

                                    <div className="type-pill" onClick={handleShowTypeOptions}>
                                       <span>
                                          {taskType === 'future' ?
                                             t('futureNotes.futureTasks')
                                             : taskType === 'notes' ?
                                                t('futureNotes.notes')
                                                : taskType}
                                       </span>
                                       <MdArrowDropDown size={20} />
                                    </div>
                                 </div>


                                 <div ref={dropdownTypeOptions} className='menu-select-type'>
                                    <div className="select-container">
                                       {showTypeOptions &&
                                          <>
                                             <div
                                                onClick={() => {
                                                   setTaskType('now')
                                                   setShowTypeOptions(false)
                                                   setHadChanges(true)
                                                }}
                                                className={taskType === 'now' ? "item-type-selected" : "items-type"}
                                             >
                                                {'now'}
                                             </div>

                                             <div
                                                onClick={() => {
                                                   setTaskType('important')
                                                   setShowTypeOptions(false)
                                                   setHadChanges(true)
                                                }}
                                                className={taskType === 'important' ? "item-type-selected" : "items-type"}
                                             >
                                                {'important'}
                                             </div>

                                             <div
                                                onClick={() => {
                                                   setTaskType('regular')
                                                   setShowTypeOptions(false)
                                                   setHadChanges(true)
                                                }}
                                                className={taskType === 'regular' ? "item-type-selected" : "items-type"}
                                             >
                                                {'regular'}
                                             </div>

                                             <div
                                                onClick={() => {
                                                   setTaskType('future')
                                                   setShowTypeOptions(false)
                                                   setHadChanges(true)
                                                }}
                                                className={taskType === 'future' ? "item-type-selected" : "items-type"}
                                             >
                                                {t('futureNotes.futureTasks')}
                                             </div>

                                             <div
                                                onClick={() => {
                                                   setTaskType('notes')
                                                   setShowTypeOptions(false)
                                                   setHadChanges(true)
                                                }}
                                                className={taskType === 'notes' ? "item-type-selected" : "items-type"}
                                             >
                                                {t('futureNotes.notes')}
                                             </div>
                                          </>
                                       }
                                    </div>
                                 </div>
                              </div>

                           </div>



                           <div className="dropdown">
                              <div className="responsible-container">
                                 <span>{t('taskDetails.responsibleUser')}</span>

                                 <div className="user-pill" onClick={handleShowUsersOptions}>
                                    <span>{responsibleUser}</span>
                                    <MdArrowDropDown size={20} />
                                 </div>
                              </div>


                              <div ref={dropdownUsersOptions} className='menu-select-user'>
                                 {showUsersOptions && !taskPrivate &&
                                    <div className="select-container">
                                       {userOnTeamSelected.map(user => {
                                          return (
                                             <div
                                                onClick={() => {
                                                   setResponsibleUser(user.name)
                                                   setUserIdSelected(user.user_id)
                                                   setShowUsersOptions(false)
                                                   setHadChanges(true)
                                                }}
                                                className={responsibleUser === user.name ? "item-user-selected" : "items-user"}
                                                key={user.user_id}
                                             >
                                                {user.name}
                                             </div>
                                          )
                                       })}
                                    </div>
                                 }
                              </div>
                           </div>
                        </div>

                        {taskType === 'future' &&
                           <div className="estimated-date-container">
                              <div className="date-container">
                                 <span>{t('taskDetails.estimatedDateToStart')}</span>

                                 <div className="date-pill">
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeDatePicker}
                                       customInput={<CustomButton />}
                                       showPopperArrow={false}
                                       minDate={tomorrow}
                                    />
                                 </div>
                              </div>
                           </div>
                        }

                        <div  {...getRootProps({ onClick: event => event.stopPropagation() })} ref={ref}>
                           <div className="input-block-details">
                              <h3>{t('taskDetails.description')}</h3>

                              <div className="input-container">
                                 <textarea
                                    placeholder={t('taskDetails.descriptionPlaceholder')}
                                    rows='5'
                                    name='taskDescription'
                                    type='text'
                                    value={taskDescription === null ? '' : FirstLetterUp(taskDescription)}
                                    onChange={e => {
                                       setTaskDescription(e.target.value)
                                       setHadChanges(true)
                                    }}
                                 // onBlur={e => handleUpdateTaskInfo(e)}
                                 />

                                 <HiCheckCircle onClick={handleUpdateTaskInfo} size={25} color='#BDBDBD' className='update-description' />
                              </div>
                           </div>

                           <div className="input-block-details">
                              <h3>{t('taskDetails.attachments')}</h3>

                              <div className="images-container">
                                 {loadingImage ?
                                    <Loading
                                       height='100%'
                                       width={650}
                                       type='spokes'
                                    />
                                    :
                                    <>
                                       {filesData.map(file => {
                                          return (
                                             <div className="image-border" key={file.file}>
                                                <div className="delete-button" onClick={e => handleDeleteFile(e, file.id)}>
                                                   <FiX color='#6B2722' size={15} />
                                                </div>
                                                {file.type.trim().split('/')[0] === 'image' ?
                                                   <img src={file.file_url} alt={file.file} rel="noopener noreferrer" target='_blank' onClick={() => openFile(file.file_url)} />
                                                   :
                                                   <div className="file-info" rel="noopener noreferrer" target='_blank' onClick={() => openFile(file.file_url)}>
                                                      <AiFillFileText size={30} color='#6B2722' />
                                                      <span>{file.file.trim().split('-')[1]}</span>
                                                   </div>
                                                }
                                                <div className="upload-button" rel="noopener noreferrer" target='_blank' onClick={() => downloadFile(file.file_url, file.file)}>
                                                   <BiDownload color='#6B2722' size={15} />
                                                </div>
                                             </div>
                                          )
                                       })}

                                       <label htmlFor='file[]' className="new-image">
                                          <FiPlus size={24} color="#6B2722" />
                                       </label>
                                    </>
                                 }
                              </div>

                           </div>

                           <input
                              multiple
                              onChange={e => handleSubmitFiles(e)}
                              type='file'
                              id='file[]'
                              contentEditable="true"
                              onPaste={e => handleSubmitFiles(e)}
                              {...getInputProps()}
                           />

                           <div className="input-block-details">
                              <h3>{t('taskDetails.checklists')}</h3>

                              {checklistData.length === 0 ?
                                 <div className="new-image" onClick={handleAddChecklistMaster}>
                                    <FiPlus size={24} color="#6B2722" />
                                 </div>
                                 :
                                 <div className="checklist-master-container">
                                    {checklistData.map((checklist, indexMaster) => {
                                       return (
                                          <div key={checklist.id} className="checklist-master">
                                             <div className="checklist-title-container">
                                                <div className="checklist-title" onClick={e => openMasterChecklistEditEvent(e, indexMaster, checklist.id)}>
                                                   {indexMaster === indexMasterChecklist && indexSubChecklist === '' ?
                                                      <input
                                                         ref={masterChecklistInput}
                                                         className='input-master-checklist-edit'
                                                         id={checklist.id}
                                                         name={checklist.id}
                                                         value={checklistTitle}
                                                         onChange={e => setChecklistTitle(e.target.value)}
                                                         onBlur={e => handleEditChecklistTitle(e, checklist.id)}
                                                         onKeyDown={e => keyHandlerSubmitChecklistTitleEdit(e, checklist.id)}
                                                      />
                                                      :
                                                      <label>{checklist.checklist_title}</label>
                                                   }
                                                </div>
                                                <div className="dropdown">
                                                   <BsThreeDots onClick={e => setShowMenuChecklistIndex(indexMaster)} />

                                                   <DropdownChecklist
                                                      checklistId={checklist.id}
                                                      showMenuChecklistIndex={showMenuChecklistIndex}
                                                      indexMaster={indexMaster}
                                                      dropdownMenuChecklist={dropdownMenuChecklist}
                                                      handleAddChecklistMaster={handleAddChecklistMaster}
                                                      handleDeleteChecklistMaster={handleDeleteChecklistMaster}
                                                   />
                                                </div>
                                             </div>
                                             <ul id='checklist'>
                                                {checklist.sub_checklists[0].content !== null &&
                                                   checklist.sub_checklists.map((sub, indexSub) => {
                                                      return (
                                                         <li key={sub.sub_checklist_id}>
                                                            <input
                                                               type="checkbox"
                                                               value={[sub.sub_checklist_id]}
                                                               id={sub.sub_checklist_id}
                                                               onChange={e => handleChecklistIdConcat(e.target.value)}
                                                               defaultChecked={sub.done}
                                                            />

                                                            <div className="checklist-content-container" onClick={e => openSubChecklistEditEvent(e, indexSub, indexMaster, sub.sub_checklist_id)}>
                                                               {indexSub === indexSubChecklist && indexMaster === indexMasterChecklist ?
                                                                  <input
                                                                     ref={subChecklistInput}
                                                                     className='input-checklist-edit'
                                                                     id={sub.sub_checklist_id}
                                                                     name={sub.sub_checklist_id}
                                                                     value={checklistContentEdit}
                                                                     onChange={e => setChecklistContentEdit(e.target.value)}
                                                                     onBlur={e => handleEditChecklist(e, sub.sub_checklist_id)}
                                                                     onKeyDown={e => keyHandlerSubmitSubChecklistEdit(e, sub.sub_checklist_id)}
                                                                  />
                                                                  :
                                                                  <span>{sub.content}</span>
                                                               }
                                                            </div>
                                                         </li>
                                                      )
                                                   })
                                                }

                                                <div className="input-container-checklist">
                                                   <input
                                                      placeholder={t('taskDetails.addChecklistPlaceholder')}
                                                      className='input-checklist'
                                                      type='text'
                                                      name={indexMaster}
                                                      id={indexMaster}
                                                      value={checklistContent.length === 0 ? '' : checklistContent[indexMaster]}
                                                      onChange={e => updateFieldChanged(e, indexMaster)}
                                                      onKeyDown={e => keyHandlerSubmitAddChecklist(e, indexMaster, checklist.id)}
                                                   />
                                                   <FiPlus
                                                      onClick={e => handleAddChecklist(e, indexMaster, checklist.id)}
                                                      size={15}
                                                      color='#000'
                                                      className='add-checklist' />
                                                </div>
                                             </ul>
                                          </div>
                                       )
                                    })}
                                 </div>
                              }


                           </div>

                           <div className="input-block-details">
                              <h3>{t('taskDetails.comments')}</h3>

                              {commentsData.length !== 0 && (
                                 <div className="comments-content" id='chatList'>

                                    <div className="comments-content-inside">
                                       {commentsData.map(comment => {
                                          return (
                                             <div key={comment.id} className="comment-ballon">
                                                <span><strong><span className='user'>{comment.comment_created_by}</span></strong>: {comment.comment_content}</span>
                                                <div className="comment-date">
                                                   <span>{moment(comment.created_at).format("DD/MMM/YY HH:mm")}</span>
                                                </div>
                                             </div>
                                          )
                                       })}

                                    </div>
                                 </div>
                              )}

                              <div className="input-container">
                                 <textarea
                                    id='comment-input'
                                    placeholder={t('taskDetails.commentPlaceholder')}
                                    rows='2'
                                    value={props.commentContent}
                                    onChange={e => props.setCommentContent(e.target.value)}
                                    onKeyDown={e => keyHandlerSubmitComment(e)}
                                 />

                                 <FiSend onClick={handleSubmitComment} size={25} color='#BDBDBD' className='send-message' />
                              </div>
                           </div>
                        </div>
                     </main>

                     <footer>
                        <button
                           onClick={handleCompleteTask}
                           type='button'
                           className='complete-task-button'
                        >
                           {taskCompleted ? t('taskDetails.returnTask') : t('taskDetails.completeTask')}
                        </button>
                     </footer>
                  </div>
               }

            </ModalBody>

            <ModalChangePrivateConfirm
               isOpen={modalChangePrivate}
               toggle={handleOpenModalChangePrivate}
               teamName={taskTeam}
               handleSubmitAll={handleSubmitAll}
               othersModalRef={othersModalRef}
            />

            <ModalSaveConfirmation
               isOpen={modalSaveConfirmation}
               handleOpenModalConfirmIfSaveOrNot={handleOpenModalConfirmIfSaveOrNot}
               handleClickOnSaveButton={handleClickOnSaveButton}
            />
         </div>
      </Modal>
   )
})

export default ModalTaskDetails
