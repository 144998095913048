import React from 'react'
import { useTranslation } from 'react-i18next'
import { Draggable } from 'react-beautiful-dnd'
import { FiLock } from 'react-icons/fi'
import { MdFormatColorFill } from 'react-icons/md'

import DropdownColor from './DropdownColor'

import gogogoLogo from '../assets/logo.svg'

const TaskCard = (props) => {

   const [t] = useTranslation()

   return (
      <Draggable
         draggableId={String(props.task.id)}
         index={props.index}
      >
         {(provided, snapshot) => {
            return (
               <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
               >
                  <div
                     className="card-task"
                     style={{ backgroundColor: props.task.card_color }}
                  >
                     <div className="card-left">
                        <div className="task-name-container" onClick={() => props.handleOpenModalTaskDetails(props.task.id)}>
                           <h1>{props.task.task_name}</h1>
                        </div>

                        <div className="icons-left">

                           {props.task.private ?
                              <span>
                                 <FiLock size={12} color="#828282" />
                                 {t('task.private')}
                              </span>
                              :
                              <span>
                                 {props.task.team_name}
                              </span>
                           }
                        </div>
                     </div>

                     <div className="card-right">
                        <div className="dropdown-color">
                           <button
                              className='color-button'
                              onClick={e => props.handleShowColor(e, props.index)}
                           >
                              <MdFormatColorFill size={17} color="#828282" />
                           </button>

                           <div className='color-button-space'></div>

                           <DropdownColor
                              dropdownColorRef={props.dropdownColorRef}
                              showColor={props.showColor}
                              taskIndex={props.taskIndex}
                              index={props.index}
                              handleUpdateColor={props.handleUpdateColor}
                              taskId={props.task.id}
                           />
                        </div>
                        <button
                           className='gogogo-button'
                           onClick={props.task.gogogo ? null : () => props.handleOpenModalGoGoGo(props.task.id)}
                        >
                           <img src={gogogoLogo} alt="GoGoGo" />
                        </button>
                     </div>
                  </div>
               </div>
            )
         }}
      </Draggable>
   )
}

export default TaskCard
