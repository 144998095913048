import React from 'react'
import Lottie from 'react-lottie'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import DataAnalysisAnimation from '../assets/data-analysis.json'

import '../styles/components/FocusAnimation.css'

const FocusAnimation = (props) => {
   // const [isStopped, setIsStopped] = useState(false)
   // const [isPaused, setIsPaused] = useState(false)

   const [t] = useTranslation()

   const lottieDefaultOptions = {
      loop: true,
      autoplay: true,
      animationData: DataAnalysisAnimation,
      rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice'
      }
   };

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         centered
         size='md'
      >
         <ModalBody id='focus-animation'>
            <div className='animation-container'>
               <h1>{t('focusAnimation.title')}</h1>
               <Lottie
                  options={lottieDefaultOptions}
                  height={400}
                  width={400}
               // isStopped={isStopped}
               // isPaused={isPaused}
               />
            </div>
         </ModalBody>
      </Modal>
   )
}

export default FocusAnimation
