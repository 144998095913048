import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import Login from '../pages/auth/Login'
import Signup from '../pages/auth/Signup'
import ForgotPassword from '../pages/auth/ForgotPassword'
import ResetPassword from '../pages/auth/ResetPassword'

// const Login = React.lazy(() => import(/* webpackChunkName: "login" */ '../pages/auth/Login'))
// const Signup = React.lazy(() => import(/* webpackChunkName: "signup" */ '../pages/auth/Signup'))
// const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "forgotPassword" */ '../pages/auth/ForgotPassword'))
// const ResetPassword = React.lazy(() => import(/* webpackChunkName: "resetPassword" */ '../pages/auth/ResetPassword'))

function AuthRoutes() {
   return (
      <Switch>
         <Redirect exact from='/' to='/login' />
         <Route exact path='/login' component={Login} />
         <Route path='/login/:token' component={Login} />
         <Route exact path='/signup' component={Signup} />
         <Route path='/signup/:token' component={Signup} />
         <Route path='/forgotPassword' component={ForgotPassword} />
         <Route path='/resetPassword/:token' component={ResetPassword} />
         <Redirect to='/' />
      </Switch>
   )
}

export default AuthRoutes
