import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import AdminPage from '../pages/app/AdminPage'

import Tasks from '../pages/app/Tasks'
import Teams from '../pages/app/Teams'
import TeamTasks from '../pages/app/TeamTasks'
import Dashboard from '../pages/app/Dashboard'
import DashboardTeam from '../pages/app/DashboardTeam'
import FutureNotes from '../pages/app/FutureNotes'
import Notifications from '../pages/app/Notifications'
import Config from '../pages/app/Config'
import TasksCompleted from '../pages/app/TasksCompleted'

import ResetPassword from '../pages/auth/ResetPassword'

function AppAdminRoutes() {
   return (
      <Switch>
         <Redirect exact from='/' to='/app/myTasks' />

         <Route exact path='/app/adminPage' component={AdminPage} />

         <Route exact path='/app/myTasks' component={Tasks} />
         <Route path='/app/myTeams' component={Teams} />
         <Route path='/app/teamTasks/:id' component={TeamTasks} />
         <Route path='/app/myDashboard' component={Dashboard} />
         <Route path='/app/dashboardTeam' component={DashboardTeam} />
         <Route path='/app/myFutureNotes' component={FutureNotes} />
         <Route path='/app/notifications' component={Notifications} />
         <Route path='/app/config' component={Config} />
         <Route path='/app/tasksCompleted' component={TasksCompleted} />

         <Route path='/resetPassword/:token' component={ResetPassword} />
         <Redirect to='/' />
      </Switch>
   );
};

export default AppAdminRoutes;
