import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import Input from '../../components/Input'
import FlagsLanguage from '../../components/FlagsLanguage'
import api from '../../services/api'

import logo from '../../assets/logo.svg'

import '../../styles/pages/auth/ForgotPassword.css'

const ForgotPassword = () => {
   const [loading, setLoading] = useState('default')

   const [email, setEmail] = useState('')

   const [t] = useTranslation()

   const history = useHistory()

   async function handleForgotPassword(e) {
      e.preventDefault()

      setLoading('processing')

      const data = { email }

      try {
         const res = await api.post('forgotPassword', data)

         toast.success(res.data.message)

         history.push('/login')

      } catch (e) {
         setLoading('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }


   return (
      <div id="page-forgotPassword">

         <div className="image-logo">
            <img src={logo} alt="GoGoGo" />
            <span className="enjoy">Enjoy, Living & Flying !</span>
         </div>

         <div className="flags-container">
            <FlagsLanguage />
         </div>

         <div id="page-forgotPassword-content">
            <div className="content">
               <h1>{t('forgotPassword.title')}</h1>
               <h4>{t('forgotPassword.subTitle')}</h4>

               <main>
                  <form onSubmit={handleForgotPassword}>
                     <fieldset>
                        <Input
                           name='email'
                           label={t('common.emailLabel')}
                           type='email'
                           placeholder={t('common.emailPlaceholder')}
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                        />

                        {loading === 'default' ?
                           <button
                              type='submit'
                              className='enter'
                           >
                              {t('common.sendButton')}
                           </button>
                           :
                           <button
                              className='enter'
                              style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        }
                     </fieldset>
                  </form>

               </main>
            </div>
         </div>
      </div>
   )
}

export default ForgotPassword
