import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import '../../styles/components/modal/ModalSendMotivation.css'

const ModalSendMotivation = (props) => {


   const [t] = useTranslation()

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalSendMotivation'
         centered
         size='md'
         autoFocus={false}
      >
         <ModalBody className="justify-content-middle modal-body">

            {!props.isPersonalizedIncentive ?
               <div className='inside-modal'>
                  <h2 className="mt-2">{t('modal.motivations.titleToSent')}</h2>

                  <button
                     type="button"
                     className='btn-incentives'
                     onClick={e => props.handleToSendMotivation(e, t('modal.motivations.first'))}
                  >
                     {t('modal.motivations.first')}
                  </button>

                  <button
                     type="button"
                     className='btn-incentives'
                     onClick={e => props.handleToSendMotivation(e, t('modal.motivations.second'))}
                  >
                     {t('modal.motivations.second')}
                  </button>

                  <button
                     type="button"
                     className='btn-incentives'
                     onClick={e => props.handleToSendMotivation(e, t('modal.motivations.third'))}
                  >
                     {t('modal.motivations.third')}
                  </button>

                  <button
                     type="button"
                     className='btn-incentives'
                     onClick={e => props.handleToSendMotivation(e, t('modal.motivations.fourth'))}
                  >
                     {t('modal.motivations.fourth')}
                  </button>

                  <button
                     type="button"
                     className='btn-incentive-personalized'
                     onClick={props.handlePersonalizedIncentive}
                  >
                     {t('modal.motivations.titlePersonalized')}
                  </button>
               </div>
               :
               <fieldset>
                  <h2 className="mt-2">{t('modal.motivations.titlePersonalized')}</h2>

                  <div className="input-block">
                     <textarea
                        placeholder={t('modal.motivations.placeholder')}
                        rows='7'
                        name='personalizedIncentive'
                        type='text'
                        value={props.personalizedIncentive}
                        onChange={e => props.setPersonalizedIncentive(e.target.value)}
                     />
                  </div>

                  {props.loadingButton === 'default' ? (
                     <button
                        type='button'
                        className='btn-send-personalized'
                        onClick={e => props.handleToSendMotivation(e, props.personalizedIncentive)}
                        disabled={props.personalizedIncentive === '' ? true : false}
                     >
                        {t('modal.motivations.send')}
                     </button>
                  ) : (
                     <button
                        className='btn-send-personalized'
                        style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                        disabled
                     >
                        <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                        <span className="sr-only"></span>
                     </button>
                  )}
               </fieldset>
            }

         </ModalBody>

      </Modal>
   )
}

export default ModalSendMotivation
