import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { FiLock, FiPlusSquare, FiArrowRightCircle } from 'react-icons/fi'
import { MdFormatColorFill } from 'react-icons/md'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import moment from 'moment'

import { useAuth } from '../../services/contexts/auth'

import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import DropdownColor from '../../components/DropdownColor'
import ModalTaskDetails from '../../components/modal/ModalTaskDetails'
import ModalAddFutureNotes from '../../components/modal/ModalAddFutureNotes'
import ModalFutureTasksChangeType from '../../components/modal/ModalFutureTasksChangeType'

import api from '../../services/api'

import '../../styles/pages/app/FutureNotes.css'

const FutureNotes = () => {
   const today = new Date()
   const tomorrow = today.setDate(today.getDate() + 1)

   const [loading, setLoading] = useState(true)
   const [loadingButtonAdd, setLoadingButtonAdd] = useState('default')
   const [loadingButtonAddWithDetails, setLoadingButtonAddWithDetails] = useState('default')

   const [modalTaskDetails, setModalTaskDetails] = useState(false)
   const [modalAddFutureNotes, setModalAddFutureNotes] = useState(false)
   const [modalChangeType, setModalChangeType] = useState(false)

   const [showColorFutureTasks, setShowColorFutureTasks] = useState(false)
   const [showColorNotes, setShowColorNotes] = useState(false)
   const [taskIndex, setTaskIndex] = useState('')

   const [idUser, setIdUser] = useState()
   const [taskId, setTaskId] = useState('')

   const [futureTasksData, setFutureTasksData] = useState([])
   const [notesData, setNotesData] = useState([])
   const [teamsData, setTeamsData] = useState([])

   const [taskName, setTaskName] = useState('')
   const [taskType, setTaskType] = useState('')
   const [team_id, setTeamId] = useState(null)
   const [teamName, setTeamName] = useState(null)
   const [userIdSelected, setUserIdSelected] = useState()

   const [commentContent, setCommentContent] = useState('')

   const [startDate, setStartDate] = useState(tomorrow)
   const [chosenDate, setChosenDate] = useState(moment(startDate).format('YYYY-MM-DD'))

   const { userId, idStorage } = useAuth()

   const dropdownColorRef = useRef(null)

   const [t] = useTranslation()

   useEffect(() => {
      if (userId) {
         setIdUser(userId)
         setUserIdSelected(userId)

      } else {
         setIdUser(idStorage)
         setUserIdSelected(idStorage)
      }

   }, [userId, idStorage])

   const loadFutureTasks = useCallback(async () => {
      const res = await api.get(`tasks?type=future&gogogo=false&completed=false`)

      setFutureTasksData(res.data)

   }, [])

   const loadNotes = useCallback(async () => {
      const res = await api.get(`tasks?type=notes&gogogo=false&completed=false`)

      setNotesData(res.data)

   }, [])

   const loadAll = useCallback(async () => {
      await loadFutureTasks()
      await loadNotes()

      setLoading(false)

   }, [loadFutureTasks, loadNotes])

   useEffect(() => {
      loadAll()

   }, [loadAll])

   const updateOrderFutureTasks = useCallback(async () => {
      for (let i = 0; i < futureTasksData.length; i++) {
         await api.put(`tasksOrder/${futureTasksData[i].id}`, { taskOrder: i })
      }
   }, [futureTasksData])

   const updateOrderNotes = useCallback(async () => {
      for (let i = 0; i < notesData.length; i++) {
         await api.put(`tasksOrder/${notesData[i].id}`, { taskOrder: i })
      }
   }, [notesData])

   useEffect(() => {
      updateOrderFutureTasks()
      updateOrderNotes()

   }, [updateOrderFutureTasks, updateOrderNotes])

   useEffect(() => {
      if (team_id !== null) {
         async function loadTeamName() {
            const res = await api.get(`teams/${team_id}`)

            setTeamName(res.data.team_name)
         }

         loadTeamName()
      }

   }, [team_id])

   const handleShowColorFutureTasks = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorFutureTasks) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorFutureTasks(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorFutureTasks) {
         setShowColorFutureTasks(false);
         setTaskIndex('');

      } else {
         setShowColorFutureTasks(true);
         setTaskIndex(index);
      }

   }, [showColorFutureTasks]);

   useEffect(() => {
      if (showColorFutureTasks) {
         document.addEventListener("click", handleShowColorFutureTasks);

         return () => document.removeEventListener("click", handleShowColorFutureTasks);
      }

   }, [showColorFutureTasks, handleShowColorFutureTasks]);

   const handleShowColorNotes = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorNotes) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorNotes(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorNotes) {
         setShowColorNotes(false);
         setTaskIndex('');

      } else {
         setShowColorNotes(true);
         setTaskIndex(index);
      }

   }, [showColorNotes]);

   useEffect(() => {
      if (showColorNotes) {
         document.addEventListener("click", handleShowColorNotes);

         return () => document.removeEventListener("click", handleShowColorNotes);
      }

   }, [showColorNotes, handleShowColorNotes]);

   async function loadTeams() {
      const res = await api.get('teams')

      setTeamsData(res.data)
   }

   async function handleAddTask(e) {
      e.preventDefault()

      setLoadingButtonAdd('processing')

      const taskStartDate = taskType === 'future' ? chosenDate : null

      const data = { taskName, taskType, team_id, userIdSelected, taskStartDate }

      try {
         const res = await api.post('tasks', data)

         toast.success(res.data.message)

         setTaskName('')
         setTeamId(null)
         setTeamName(null)
         setUserIdSelected(idUser)

         setModalAddFutureNotes(false)

         if (taskType === 'future') {
            await loadFutureTasks()
            setLoadingButtonAdd('default')

            return scrollToBottomFutureTasks()
         }

         if (taskType === 'notes') {
            await loadNotes()
            setLoadingButtonAdd('default')

            return scrollToBottomNotes()
         }

      } catch (e) {
         setLoadingButtonAdd('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleAddTaskWithDetails(e) {
      e.preventDefault()

      setLoadingButtonAddWithDetails('processing')

      const taskStartDate = taskType === 'future' ? chosenDate : null

      const data = { taskName, taskType, team_id, userIdSelected, taskStartDate }

      try {
         const res = await api.post('tasks', data)

         toast.success(res.data.message)

         setTaskName('')
         setTeamId(null)
         setTeamName(null)
         setUserIdSelected(idUser)

         setModalAddFutureNotes(false)

         if (taskType === 'future') {
            await loadFutureTasks()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomFutureTasks()
         }

         if (taskType === 'notes') {
            await loadNotes()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomNotes()
         }

         return handleOpenModalTaskDetails(res.data.taskCreated[0].id)

      } catch (e) {
         setLoadingButtonAddWithDetails('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleUpdateColor(e, taskId, cardColor) {
      e.preventDefault()

      const data = { card_color: cardColor }

      try {
         await api.put(`tasksColor/${taskId}`, data)

         loadFutureTasks()
         loadNotes()

         setShowColorFutureTasks(false)
         setShowColorNotes(false)
         setTaskIndex('')

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function handleOpenModalAddFutureNotes(taskType) {
      setModalAddFutureNotes(!modalAddFutureNotes)

      setTaskType(taskType)
      setTaskName('')
      setTeamId(null)
      setTeamName(null)

      loadTeams()
   }

   function handleOpenModalTaskDetails(id) {
      setModalTaskDetails(!modalTaskDetails)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')

         setCommentContent('')

         loadFutureTasks()
         loadNotes()
      }
   }

   function handleOpenModalChangeType(id) {
      setModalChangeType(!modalChangeType)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')

         loadFutureTasks()
         loadNotes()
      }
   }

   function scrollToBottomFutureTasks() {
      const column = document.getElementById("columnFutureTasks")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   function scrollToBottomNotes() {
      const column = document.getElementById("columnNotes")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   async function handleEditTaskTypeOnDragDrop(taskId, taskType) {

      const data = { taskType }

      try {
         await api.put(`tasksType/${taskId}`, data)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const reorderSameList = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   const reorderOtherLists = (sourceList, destinationList, startIndex, endIndex) => {
      const source = Array.from(sourceList);
      const destination = Array.from(destinationList);

      const [removed] = source.splice(startIndex, 1);
      destination.splice(endIndex, 0, removed);

      return { source, destination };
   };


   const onDragEnd = async (result) => {
      const { source, destination, draggableId } = result;

      if (!destination) {
         return;
      }

      if (destination.index === source.index && destination.droppableId === source.droppableId) {
         return;
      }

      if (destination.droppableId === source.droppableId) {
         const tasksReorder = reorderSameList(
            destination.droppableId === 'future'
               ? futureTasksData
               : destination.droppableId === 'notes'
               && notesData,
            source.index,
            destination.index
         );

         if (destination.droppableId === 'future') {
            setFutureTasksData(tasksReorder)

         } else if (destination.droppableId === 'notes') {
            setNotesData(tasksReorder)

         }

      } else {
         const tasksReorder = reorderOtherLists(
            source.droppableId === 'future'
               ? futureTasksData
               : notesData,
            destination.droppableId === 'future'
               ? futureTasksData
               : notesData,
            source.index,
            destination.index
         )

         if (source.droppableId === 'future') {
            setFutureTasksData(tasksReorder.source)

         } else if (source.droppableId === 'notes') {
            setNotesData(tasksReorder.source)

         }

         if (destination.droppableId === 'future') {
            setFutureTasksData(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'future')

         } else if (destination.droppableId === 'notes') {
            setNotesData(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'notes')

         }
      }
   };


   return (
      <DragDropContext
         onDragEnd={onDragEnd}
      >
         <div id="page-future-notes">
            <PageHeader />

            <div className='container'>
               {loading ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <main>
                        <div className="column">
                           <div className="types">
                              <span className='task-type'>{t('futureNotes.futureTasks')}</span>
                           </div>

                           <Droppable
                              droppableId="future"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnFutureTasks'
                                    className="column-two-parts"
                                 >
                                    {futureTasksData.map((task, index) => (
                                       <Draggable
                                          draggableId={String(task.id)}
                                          index={index}
                                          key={task.id}
                                       >
                                          {(provided, snapshot) => {
                                             return (
                                                <div
                                                   ref={provided.innerRef}
                                                   {...provided.draggableProps}
                                                   {...provided.dragHandleProps}
                                                >
                                                   <div
                                                      className="card-task"
                                                      style={{ backgroundColor: task.card_color }}
                                                   >
                                                      <div className="card-left">
                                                         <div className="task-name-container" onClick={() => handleOpenModalTaskDetails(task.id)}>
                                                            <h1>{task.task_name}</h1>
                                                         </div>

                                                         <div className="icons-left">

                                                            {task.private ?
                                                               <span>
                                                                  <FiLock size={12} color="#828282" />
                                                                  {t('task.private')}
                                                               </span>
                                                               :
                                                               <span>
                                                                  {task.team_name}
                                                               </span>
                                                            }
                                                         </div>
                                                      </div>

                                                      <div className="card-right">
                                                         <div className="dropdown-color">
                                                            <button
                                                               className='color-button'
                                                               onClick={e => handleShowColorFutureTasks(e, index)}
                                                            >
                                                               <MdFormatColorFill size={17} color="#828282" />
                                                            </button>

                                                            <div className='color-button-space'></div>

                                                            <DropdownColor
                                                               dropdownColorRef={dropdownColorRef}
                                                               showColor={showColorFutureTasks}
                                                               taskIndex={taskIndex}
                                                               index={index}
                                                               handleUpdateColor={handleUpdateColor}
                                                               taskId={task.id}
                                                            />
                                                         </div>

                                                         <div className="button-container">
                                                            <span>{t('futureNotes.sendToTask')}</span>
                                                            <button
                                                               className='send-button'
                                                               onClick={() => handleOpenModalChangeType(task.id)}
                                                            >
                                                               <FiArrowRightCircle size={25} color='#4F4F4F' />
                                                            </button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             )
                                          }}
                                       </Draggable>
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddFutureNotes('future')}>
                              <FiPlusSquare size={20} color='#F2F2F2' />
                              <span>{t('futureNotes.buttonNewTask')}</span>
                           </button>
                        </div>

                        <div className="column">
                           <div className="types">
                              <span className='task-type'>{t('futureNotes.notes')}</span>
                           </div>

                           <Droppable
                              droppableId="notes"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columNotes'
                                    className="column-two-parts"
                                 >
                                    {notesData.map((task, index) => (
                                       <Draggable
                                          draggableId={String(task.id)}
                                          index={index}
                                          key={task.id}
                                       >
                                          {(provided, snapshot) => {
                                             return (
                                                <div
                                                   ref={provided.innerRef}
                                                   {...provided.draggableProps}
                                                   {...provided.dragHandleProps}
                                                >
                                                   <div
                                                      className="card-task"
                                                      style={{ backgroundColor: task.card_color }}
                                                   >
                                                      <div className="card-left">
                                                         <div className="task-name-container" onClick={() => handleOpenModalTaskDetails(task.id)}>
                                                            <h1>{task.task_name}</h1>
                                                         </div>

                                                         <div className="icons-left">

                                                            {task.private ?
                                                               <span>
                                                                  <FiLock size={12} color="#828282" />
                                                                  {t('task.private')}
                                                               </span>
                                                               :
                                                               <span>
                                                                  {task.team_name}
                                                               </span>
                                                            }
                                                         </div>
                                                      </div>

                                                      <div className="card-right">
                                                         <div className="dropdown-color">
                                                            <button
                                                               className='color-button'
                                                               onClick={e => handleShowColorNotes(e, index)}
                                                            >
                                                               <MdFormatColorFill size={17} color="#828282" />
                                                            </button>

                                                            <div className='color-button-space'></div>

                                                            <DropdownColor
                                                               dropdownColorRef={dropdownColorRef}
                                                               showColor={showColorNotes}
                                                               taskIndex={taskIndex}
                                                               index={index}
                                                               handleUpdateColor={handleUpdateColor}
                                                               taskId={task.id}
                                                            />
                                                         </div>

                                                         <div className="button-container">
                                                            <span>{t('futureNotes.sendToTask')}</span>
                                                            <button
                                                               className='send-button'
                                                               onClick={() => handleOpenModalChangeType(task.id)}
                                                            >
                                                               <FiArrowRightCircle size={25} color='#4F4F4F' />
                                                            </button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             )
                                          }}
                                       </Draggable>
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddFutureNotes('notes')}>
                              <FiPlusSquare size={20} color='#F2F2F2' />
                              <span>{t('futureNotes.buttonNewTask')}</span>
                           </button>
                        </div>
                     </main>

                     <ModalTaskDetails
                        isOpen={modalTaskDetails}
                        toggle={handleOpenModalTaskDetails}
                        taskId={taskId}
                        loadFutureTasks={loadFutureTasks}
                        loadNotes={loadNotes}
                        commentContent={commentContent}
                        setCommentContent={setCommentContent}
                     />

                     <ModalAddFutureNotes
                        isOpen={modalAddFutureNotes}
                        toggle={handleOpenModalAddFutureNotes}
                        taskName={taskName}
                        setTaskName={setTaskName}
                        taskType={taskType}
                        setTaskType={setTaskType}
                        team_id={team_id}
                        setTeamId={setTeamId}
                        teamName={teamName}
                        idUser={idUser}
                        setUserIdSelected={setUserIdSelected}
                        handleAddTask={handleAddTask}
                        handleAddTaskWithDetails={handleAddTaskWithDetails}
                        teamsData={teamsData}
                        loadingButtonAdd={loadingButtonAdd}
                        loadingButtonAddWithDetails={loadingButtonAddWithDetails}
                        tomorrow={tomorrow}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        chosenDate={chosenDate}
                        setChosenDate={setChosenDate}
                     />

                     <ModalFutureTasksChangeType
                        isOpen={modalChangeType}
                        toggle={handleOpenModalChangeType}
                        setModalChangeType={setModalChangeType}
                        taskId={taskId}
                        loadFutureTasks={loadFutureTasks}
                        loadNotes={loadNotes}
                     />
                  </>
               }
            </div>
         </div>
      </DragDropContext>
   )
}

export default FutureNotes
