import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GoReply } from 'react-icons/go'

import logo from '../assets/logo.svg'

import '../styles/components/PageHeaderTeamTask.css'

const PageHeaderTeamTask = (props) => {

   const history = useHistory()
   const [t] = useTranslation()

   function handleGoBack() {
      history.goBack()
   }

   return (
      <header id="page-header-team-task">
         <div className="top-bar-container">

            <Link to='/app/myTasks'>
               <img className='logo' src={logo} alt="GoGoGo" />
            </Link>


            <div className="top-bar-right">
               <div className="link-container">
                  <div className='link-selected'>
                     <span>{props.teamName ? `${t('teamTask.team')} ${props.teamName}` : ''}</span>
                  </div>
                  <div className={'line-selected'}></div>
               </div>

               <button onClick={handleGoBack} className='buttonBack'>
                  <GoReply size={25} color='#FFF' />
               </button>

            </div>
         </div>
      </header>
   )
}

export default PageHeaderTeamTask
