import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import ThumbnailLetters from './ThumbnailLetters'
import { useAuth } from '../services/contexts/auth'

import '../styles/pages/app/Config.css'

const DropzoneArea = ({ onDrop, avatarLoaded, selectedFileDropzone, checkAvatarNull, userName }) => {

   const [t] = useTranslation()

   const { userColor } = useAuth()

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: false
   })

   return (
      <div className='avatar' style={{ backgroundColor: userColor }}  {...getRootProps()}>
         <input {...getInputProps()} multiple={false} />

         {selectedFileDropzone
            ?
            <img src={selectedFileDropzone} alt={selectedFileDropzone.name} className='file' />
            :
            isDragActive
               ?
               <span className='drop-here'>{t('common.dropHere')}</span>
               :
               <div className='info'>
                  {checkAvatarNull === null || checkAvatarNull === undefined
                     ?
                     <ThumbnailLetters rounded text={userName} className="m-auto" />
                     :
                     <>
                        {avatarLoaded.substring(avatarLoaded.lastIndexOf('.') + 1).toLowerCase() === 'gif' ||
                           avatarLoaded.substring(avatarLoaded.lastIndexOf('.') + 1).toLowerCase() === "png" ||
                           avatarLoaded.substring(avatarLoaded.lastIndexOf('.') + 1).toLowerCase() === "bmp" ||
                           avatarLoaded.substring(avatarLoaded.lastIndexOf('.') + 1).toLowerCase() === "jpeg" ||
                           avatarLoaded.substring(avatarLoaded.lastIndexOf('.') + 1).toLowerCase() === "jpg"
                           ?
                           <img src={avatarLoaded} alt={avatarLoaded.name} />
                           :
                           <ThumbnailLetters rounded text={userName} className="m-auto" />
                        }
                     </>
                  }
               </div>
         }
      </div>
   )
}

export default DropzoneArea
