import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import { AllFirstLettersUp } from '../../utils/functions'

import '../../styles/components/modal/ModalChangePrivateConfirm.css'

const ModalChangePrivateConfirm = (props) => {

   const [t] = useTranslation()

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalChangePrivateConfirm'
         centered
         size='md'
         ref={props.othersModalRef}
      >
         <ModalBody className="justify-content-middle text-center p-5 modal-body">
            <h2 className="mt-2">{t('modal.changePrivateConfirm.title')}</h2>
            <div className="warning-container">
               <p className='mt-5'>{t('modal.changePrivateConfirm.warning.your')} <strong>
                  {t('modal.changePrivateConfirm.warning.tasks')}</strong> {t('modal.changePrivateConfirm.warning.complement1')} <strong>
                     {t('modal.changePrivateConfirm.warning.public')} </strong> {t('modal.changePrivateConfirm.warning.complement2')} <strong>
                     {t('modal.changePrivateConfirm.warning.all')}</strong>  {t('modal.changePrivateConfirm.warning.complement3')} <strong>
                     {t('modal.changePrivateConfirm.warning.team')} {props.teamName && AllFirstLettersUp(props.teamName)}</strong> {t('modal.changePrivateConfirm.warning.complement4')}.</p>

               <p className='confirm'>{t('modal.changePrivateConfirm.warningConfirm')}</p>
            </div>

            <div className="buttonContainer">
               <button className="buttonConfirm" onClick={props.handleSubmitAll}>{t('modal.changePrivateConfirm.buttonChangeTask')}</button>
               <button className="buttonCancel" onClick={props.toggle}>{t('modal.changePrivateConfirm.buttonCancel')}</button>
            </div>

         </ModalBody>
      </Modal>
   )
}

export default ModalChangePrivateConfirm
