import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import Input from '../Input'

import '../../styles/components/modal/ModalEditTeamName.css'

const ModalEditTeamName = (props) => {

   const [t] = useTranslation()

   const team_id = props.teamId

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalEditTeamName'
         centered
         size='md'
         autoFocus={false}
      >
         <ModalBody className="justify-content-middle modal-body">
            <h2 className="mt-2">{t('modal.editTeam.title')}</h2>

            <form onSubmit={e => props.handleTeamNameEdit(e, team_id)}>
               <fieldset>
                  <Input
                     name='teamName'
                     label={t('modal.editTeam.teamNameLabel')}
                     placeholder={t('modal.editTeam.teamNamePlaceholder')}
                     type='text'
                     value={props.teamName}
                     onChange={e => props.setTeamName(e.target.value)}
                     autoComplete='off'
                     autoFocus={true}
                  />

               </fieldset>

               {props.loadingButton === 'default' ? (
                  <button
                     type='submit'
                     className='addTeamButton'
                  >
                     {t('modal.editTeam.doneButton')}
                  </button>
               ) : (
                  <button
                     className='addTeamButton'
                     style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                     disabled
                  >
                     <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                     <span className="sr-only"></span>
                  </button>
               )}

            </form>

         </ModalBody>

      </Modal>
   )
}

export default ModalEditTeamName
