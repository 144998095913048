import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import '../../styles/components/modal/ModalDeleteConfirm.css'

const ModalDeleteConfirm = (props) => {

   const [t] = useTranslation()

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalDeleteConfirm'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle text-center p-5 modal-body">
            <h2 className="mt-2">{t('modal.deleteConfirm.title')}</h2>
            <p className='mt-5'><strong>{t('modal.deleteConfirm.warning.all')}</strong> {t('modal.deleteConfirm.warning.as')} <strong>{t('modal.deleteConfirm.warning.tasks')}
            </strong> {t('modal.deleteConfirm.warning.complement')} <strong>{t('modal.deleteConfirm.warning.removed')}</strong>.</p>

            <p>{t('modal.deleteConfirm.warningConfirm')}</p>

            <div className="buttonContainer">
               <button className="buttonConfirm" onClick={props.handleDelete}>{t('modal.deleteConfirm.buttonRemove')}</button>
               <button className="buttonCancel" onClick={props.toggle}>{t('modal.deleteConfirm.buttonCancel')}</button>
            </div>

         </ModalBody>
      </Modal>
   )
}

export default ModalDeleteConfirm
