import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import Input from '../../components/Input'
import FlagsLanguage from '../../components/FlagsLanguage'
import api from '../../services/api'

import logo from '../../assets/logo.svg'

import '../../styles/pages/auth/ResetPassword.css'

const ResetPassword = () => {
   const [loading, setLoading] = useState('default')

   const [password, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')

   const history = useHistory()

   const [t] = useTranslation()

   function useQuery() {
      return new URLSearchParams(useLocation().search)
   }

   const query = useQuery()
   const email = query.get("email")
   const { token } = useParams()


   async function handleResetPassword(e) {
      e.preventDefault()

      setLoading('processing')

      const data = { email, token, password, confirmPassword }

      try {
         const res = await api.post('resetPassword', data)

         toast.success(res.data.message)

         history.push('/login')

      } catch (e) {
         setLoading('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }


   return (
      <div id="page-resetPassword">

         <div className="image-logo">
            <img src={logo} alt="GoGoGo" />
            <span className="enjoy">Enjoy, Living & Flying !</span>
         </div>

         <div className="flags-container">
            <FlagsLanguage />
         </div>

         <div id="page-resetPassword-content">
            <div className="content">
               <h1>{t('resetPassword.title')}</h1>
               <h4>{t('resetPassword.subTitle')}</h4>

               <main>
                  <form onSubmit={handleResetPassword}>
                     <fieldset>
                        <Input
                           name='password'
                           label={t('common.passwordLabel')}
                           type='password'
                           placeholder={t('common.passwordPlaceholder')}
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                        />

                        <Input
                           name='confirmPassword'
                           label={t('common.confirmPasswordLabel')}
                           type='password'
                           placeholder={t('common.confirmPasswordPlaceholder')}
                           value={confirmPassword}
                           onChange={e => setConfirmPassword(e.target.value)}
                        />

                        {loading === 'default' ?
                           <button
                              type='submit'
                              className='enter'
                           >
                              {t('common.registerButton')}
                           </button>
                           :
                           <button
                              className='enter'
                              style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        }
                     </fieldset>
                  </form>

               </main>
            </div>
         </div>
      </div>
   )
}

export default ResetPassword
