import React, { useState, useEffect, forwardRef } from 'react'
import { Modal, ModalBody } from "reactstrap"
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { FiCalendar } from 'react-icons/fi'

import Input from '../Input'
import api from '../../services/api'

import '../../styles/components/modal/ModalAddTasks.css'

const ModalAddFutureNotes = (props) => {
   const [userOnTeamSelected, setUserOnTeamSelected] = useState([])
   const [userLogged, setUserLogged] = useState('')

   const [t] = useTranslation()

   const chosenDateFormated = moment(props.chosenDate).format('YYYY-MM-DD')

   useEffect(() => {
      if (props.idUser) {
         async function loadUserLogged() {
            const res = await api.get(`users/${props.idUser}`)

            setUserLogged(res.data.name)
         }

         loadUserLogged()
      }

   }, [props.idUser])

   useEffect(() => {
      async function loadTeamSelected() {
         const res = await api.get(`teams/${props.team_id}`)

         setUserOnTeamSelected(res.data.users)
      }

      if (props.team_id !== null) {
         loadTeamSelected()
      }

   }, [props.team_id])

   const optionsTeams = props.teamsData.map(team => {
      return (
         { value: team.id, label: team.team_name }
      )
   })

   const optionsUsersOnTeams = userOnTeamSelected.map(user => {
      return (
         { value: user.user_id, label: user.name }
      )
   })

   const taskTypesItems = [
      { label: t('futureNotes.futureTasks'), value: 'future' },
      { label: t('futureNotes.notes'), value: 'notes' },
   ]


   const customSelectStyles = {
      option: (provided, state) => ({
         ...provided,
         padding: 20,
         color: state.isSelected ? '#FFF' : '#000',
         backgroundColor: state.isSelected ? '#6B2722' : '#FFF',
         borderColor: state.isSelected ? '#6B2722' : '#FFF',
         borderWidth: 2,
         borderStyle: 'solid',
         "&:hover": {
            borderColor: state.isFocused ? '#6B2722' : '#FFF',
            borderWidth: state.isFocused ? 2 : 2,
         },
      }),
      control: () => ({
         width: "100%",
      }),
      singleValue: (provided, state) => {
         const opacity = state.isDisabled ? 0.5 : 1;
         const transition = 'opacity 300ms';

         return { ...provided, opacity, transition };
      }
   }

   const onChangeDatePicker = (selectedDate) => {
      const currentDate = selectedDate || props.tomorrow
      const dateMillis = currentDate.valueOf()

      props.setStartDate(selectedDate)
      props.setChosenDate(moment(dateMillis).format('YYYY-MM-DD'))
   }

   const CustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            onClick={onClick}
            ref={ref}
         >
            <FiCalendar color={'#333333'} size={15} />
            <span>{moment(chosenDateFormated).format('DD-MMM-YYYY')}</span>
         </button>
      )
   })

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalAddTasks'
         centered
         size='md'
         autoFocus={false}
      >
         <ModalBody className="justify-content-middle modal-body">
            <h2 className="mt-2">
               {t('futureNotes.adding')} {props.taskType === 'future' ? t('futureNotes.futureTasks') : t('futureNotes.notes')}
            </h2>

            <fieldset>
               <Input
                  label={t('modal.addTask.taskNameLabel')}
                  name='taskName'
                  type='text'
                  id='taskName'
                  value={props.taskName}
                  onChange={e => props.setTaskName(e.target.value)}
                  autoComplete="off"
                  autoFocus={true}
               />

               <div className="input-block">
                  <label htmlFor='team'>{t('modal.addTask.privateOrTeam')}</label>
                  <Select
                     isSearchable={false}
                     styles={customSelectStyles}
                     options={optionsTeams}
                     className='react-select-container'
                     classNamePrefix="react-select"
                     name="team"
                     id="team"
                     placeholder={props.teamName === null ? t('modal.addTask.privatePlaceholder') : props.teamName}
                     defaultValue={props.team_id}
                     onChange={e => props.setTeamId(e.value)}
                  />
               </div>

               <div className="input-block">
                  <label htmlFor='teamUser'>{t('modal.addTask.userResponsible')}</label>
                  <Select
                     isSearchable={false}
                     styles={customSelectStyles}
                     options={optionsUsersOnTeams}
                     className='react-select-container'
                     classNamePrefix="react-select"
                     name="teamUser"
                     id="teamUser"
                     placeholder={userLogged}
                     defaultValue={props.idUser}
                     onChange={e => { props.setUserIdSelected(e.value) }}
                  />
               </div>

               <div className="input-block">
                  <label htmlFor='taskType'>{t('modal.addTask.taskType')}</label>
                  <Select
                     isSearchable={false}
                     styles={customSelectStyles}
                     options={taskTypesItems}
                     className='react-select-container'
                     classNamePrefix="react-select"
                     name="taskType"
                     id="taskType"
                     placeholder={
                        props.taskType === 'future' ? t('futureNotes.futureTasks') :
                           props.taskType === 'notes' && t('futureNotes.notes')
                     }
                     defaultValue={props.taskType}
                     onChange={e => props.setTaskType(e.value)}
                  />

               </div>

               {props.taskType === 'future' &&
                  < div className="input-block">
                     <label htmlFor='taskType'>{t('modal.addTask.dateToStart')}</label>
                     <DatePicker
                        selected={props.startDate}
                        onChange={onChangeDatePicker}
                        customInput={<CustomButton />}
                        showPopperArrow={false}
                        minDate={props.tomorrow}
                     />
                  </div>
               }

            </fieldset>

            {props.loadingButtonAdd === 'default' ? (
               <button
                  type='button'
                  className={props.taskName === '' ? 'addTaskButtonDisabled' : 'addTaskButton'}
                  onClick={props.handleAddTask}
                  disabled={props.taskName === '' ? true : false}
               >
                  {t('modal.addTask.buttonAdd')}
               </button>
            ) : (
               <button
                  className='addTaskButton'
                  style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                  disabled
               >
                  <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                  <span className="sr-only"></span>
               </button>
            )}

            {props.loadingButtonAddWithDetails === 'default' ? (
               <button
                  type='submit'
                  className={props.taskName === '' ? 'addTaskButtonWithDetailsDisabled' : 'addTaskButtonWithDetails'}
                  onClick={props.handleAddTaskWithDetails}
                  disabled={props.taskName === '' ? true : false}
               >
                  {t('modal.addTask.buttonAddWithDetails')}
               </button>
            ) : (
               <button
                  className='addTaskButtonWithDetails'
                  style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                  disabled
               >
                  <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                  <span className="sr-only"></span>
               </button>
            )}

         </ModalBody>

      </Modal>
   )
}

export default ModalAddFutureNotes
