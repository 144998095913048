import React from 'react'
import { useTranslation } from 'react-i18next'

import '../styles/components/modal/ModalTaskDetails.css'


function DropdownChecklist(props) {

   const checklistId = props.checklistId

   const [t] = useTranslation()

   return (
      <>
         {props.showMenuChecklistIndex === props.indexMaster ? (
            <div className="checklist-menu" ref={props.dropdownMenuChecklist}>
               <button
                  type='button'
                  onClick={props.handleAddChecklistMaster}
               >
                  {t('checklist.newChecklist')}
               </button>

               <button
                  type='button'
                  onClick={e => props.handleDeleteChecklistMaster(e, checklistId)}
               >
                  {t('checklist.deleteChecklist')}
               </button>
            </div>
         )
            :
            null
         }
      </>
   );
}

export default DropdownChecklist
