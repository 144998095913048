import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { FiUser } from 'react-icons/fi'
import { AiOutlineMail } from 'react-icons/ai'

import { useAuth } from '../../services/contexts/auth'
import ModalUserDetails from '../../components/modal/ModalUserDetails'
import PageHeaderAdmin from '../../components/PageHeaderAdmin'
import Loading from '../../components/Loading'
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll'

import api from '../../services/api'

import '../../styles/pages/app/AdminPage.css'
import { AllFirstLettersUp } from '../../utils/functions'

const AdminPage = () => {
   const [loading, setLoading] = useState(true)
   const [adminUser, setAdminUser] = useState()

   const [modalUserDetails, setModalUserDetails] = useState(false)

   const [loadingMorePages, setLoadingMorePages] = useState(true)
   const [pageUsers, setPageUsers] = useState(1)
   const [usersQuantity, setUsersQuantity] = useState(0)
   const [searchName, setSearchName] = useState('')
   const [searchEmail, setSearchEmail] = useState('')
   const [filterLostFocus, setFilterLostFocus] = useState(false)
   const [filterBy, setFilterBy] = useState('name')

   const [allUsers, setAllUsers] = useState([])

   const [userId, setUserId] = useState(null)

   const { userAdmin, adminStorage } = useAuth()

   const [t] = useTranslation()

   useEffect(() => {
      if (userAdmin !== null) {
         setAdminUser(userAdmin)

      } else {
         setAdminUser(adminStorage)
      }

   }, [userAdmin, adminStorage])


   const usersInformationsConfigurated = useCallback((users) => {

      const usersInfo = users.map(user => {
         return {
            id: user.id,
            name: user.name,
            email: user.email,
            cellphone: user.cellphone,
            avatar: user.avatar,
            platform: user.platform,
            admin: user.admin ? t('common.yes') : t('common.no'),
            blocked: user.blocked ? t('common.yes') : t('common.no'),
            passwordValid: user.password_valid ? t('common.yes') : t('common.no'),
            motivationPermission: user.motivation_permission ? t('common.yes') : t('common.no'),
            createdAt: moment(user.created_at).format('DD-MMM-YYYY'),
            userAvatarUrl: user.user_avatar_url,
            userAvatarUri: user.user_avatar_uri
         }
      })

      return usersInfo

   }, [t])

   const loadUsers = useCallback(async () => {

      try {
         const { data } = await api.get(`users/admin?currentPage=${pageUsers}`)

         const users = usersInformationsConfigurated(data)

         if (pageUsers > 1) {
            setAllUsers(oldValue => [...new Map([...oldValue, ...users].map(user => [user['id'], user])).values()])

         } else {
            setAllUsers(users)
         }

         const quantity = await api.get('users/quantity/admin');

         setUsersQuantity(quantity.data);

         setLoading(false)

      } catch (e) {
         setLoading(false)

         e.response.data.errors.forEach(error => toast.error(error))
      }

   }, [pageUsers, usersInformationsConfigurated])

   const loadUsersWithFilter = useCallback(async () => {
      try {
         const { data } = await api.get(`users/admin?currentPage=1&pageSize=50&filterName=${searchName}&filterEmail=${searchEmail}`)

         const users = usersInformationsConfigurated(data)

         setAllUsers(users)

      } catch (e) {
         setLoading(false)

         e.response.data.errors.forEach(error => toast.error(error))
      }

   }, [usersInformationsConfigurated, searchName, searchEmail])

   useEffect(() => {
      if (adminUser) {
         if (searchName !== '' || searchEmail !== '') {
            loadUsersWithFilter()

         } else {
            loadUsers()
         }
      }

   }, [adminUser, loadUsers, loadUsersWithFilter, searchName, searchEmail])

   useEffect(() => {
      if (filterLostFocus) {
         setPageUsers(1)
         setLoadingMorePages(true)
      }

   }, [filterLostFocus])

   function handleFetchMore() {
      if (allUsers.length >= Number(usersQuantity)) {
         setLoadingMorePages(false)
         return
      }

      setTimeout(() => {
         setPageUsers(oldValue => oldValue + 1)

         loadUsers()
      }, 1500)
   }

   function handleOpenModalUserDetails(id) {
      setModalUserDetails(!modalUserDetails)

      if (Number(id)) {
         setUserId(id)

      } else {
         setUserId(null)
         setSearchName('')
         setSearchEmail('')
         loadUsers()
      }
   }

   function handleChangeFilter() {
      if (filterBy === 'name') {
         setFilterBy('email')
      } else {
         setFilterBy('name')
      }
   }

   return (
      <div id='page-admin'>
         <PageHeaderAdmin />

         <div className='container'>
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <main>
                  <div className="header">
                     <h1>{t('adminPage.title')}</h1>

                     {filterBy === 'name' ?
                        <div className="filter">
                           <input
                              id='searchName'
                              type="text"
                              value={searchName}
                              onChange={e => setSearchName(e.target.value)}
                              onFocus={() => setFilterLostFocus(false)}
                              onBlur={() => setFilterLostFocus(true)}
                              placeholder={t('adminPage.seachNamePlaceholder')}
                           />

                           <div className="icon-container">
                              <AiOutlineMail
                                 size={22}
                                 color="#828282"
                                 onClick={handleChangeFilter}
                              />
                              <div className="tooltip">{t('adminPage.changeSearchToEmail')}</div>
                           </div>
                        </div>
                        :
                        <div className="filter">
                           <input
                              id='searchEmail'
                              type="text"
                              value={searchEmail}
                              onChange={e => setSearchEmail(e.target.value)}
                              onFocus={() => setFilterLostFocus(false)}
                              onBlur={() => setFilterLostFocus(true)}
                              placeholder={t('adminPage.seachEmailPlaceholder')}
                           />

                           <div className="icon-container">
                              <FiUser
                                 size={22}
                                 color="#828282"
                                 onClick={handleChangeFilter}
                              />
                              <div className="tooltip">{t('adminPage.changeSearchToName')}</div>
                           </div>
                        </div>
                     }


                  </div>

                  {allUsers.length === 0 ?
                     <div className="no-users">
                        <span>
                           {t('adminPage.noUsers')}
                        </span>
                     </div>
                     :
                     <div className="users-list">
                        <div className="users-list-header">

                           <div className="user-name">
                              <label htmlFor="name">{t('adminPage.name')}</label>
                           </div>

                           <div className="user-email">
                              <label htmlFor="email">{t('adminPage.email')}</label>
                           </div>

                           <div className="user-cellphone">
                              <label htmlFor="cellphone">{t('adminPage.cellphone')}</label>
                           </div>

                           <div className="user-platform">
                              <label htmlFor="platform">{t('adminPage.platform')}</label>
                           </div>

                           <div className="user-yesNo">
                              <label htmlFor="motivation">{t('adminPage.motivationPermission')}</label>
                           </div>

                           <div className="user-yesNo">
                              <label htmlFor="admin">{t('adminPage.admin')}</label>
                           </div>

                           <div className="user-yesNo">
                              <label htmlFor="passwordValid">{t('adminPage.passwordValid')}</label>
                           </div>

                           <div className="user-yesNo">
                              <label htmlFor="blocked">{t('adminPage.blocked')}</label>
                           </div>

                           <div className="user-createdAt">
                              <label htmlFor="createdAt">{t('adminPage.createdAt')}</label>
                           </div>
                        </div>

                        <InfiniteScroll
                           dataLength={allUsers.length}
                           next={handleFetchMore}
                           hasMore={loadingMorePages}
                           height='50rem'
                           className='infinite'
                           loader={
                              <LoadingInfiniteScroll
                                 height={30}
                                 width='100%'
                                 type='spin'
                              />
                           }
                           scrollThreshold={0.9}
                        >
                           {allUsers.map(user => {
                              return (
                                 <button
                                    className='user-info'
                                    onClick={() => handleOpenModalUserDetails(user.id)}
                                    key={user.id}
                                 >
                                    <span className='user-name'>{AllFirstLettersUp(user.name)}</span>
                                    <span className='user-email'>{user.email}</span>
                                    <span className='user-cellphone'>{user.cellphone ? user.cellphone : t('userDetails.cellphonePlaceholder')}</span>
                                    <span className='user-platform'>{user.platform}</span>
                                    <span className='user-yesNo'>{user.motivationPermission}</span>
                                    <span className='user-yesNo'>{user.admin}</span>
                                    <span className='user-yesNo'>{user.passwordValid}</span>
                                    <span className='user-yesNo'>{user.blocked}</span>
                                    <span className='user-createdAt'>{user.createdAt}</span>
                                 </button>
                              )
                           })}
                        </InfiniteScroll>
                     </div>
                  }
               </main>
            }

            <ModalUserDetails
               isOpen={modalUserDetails}
               toggle={handleOpenModalUserDetails}
               userId={userId}
               setPageUsers={setPageUsers}
               setLoadingMorePages={setLoadingMorePages}
            />
         </div>
      </div>
   )
}

export default AdminPage
