import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'
import { FaRegTrashAlt, FaUserPlus } from 'react-icons/fa'

import Input from '../Input'

import '../../styles/components/modal/ModalAddUsers.css'

const ModalAddUsers = (props) => {

   const [t] = useTranslation()

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalAddUsers'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle modal-body">
            <h2 className="mt-2">{t('modal.addUser.title')}</h2>

            <form onSubmit={props.handleAddUsers}>
               <div>
                  <fieldset>

                     <div className='input-container'>
                        <Input
                           name='email'
                           placeholder={t('modal.addUser.addUserPlaceholder')}
                           type='email'
                           value={props.email}
                           onChange={e => props.setEmail(e.target.value)}
                        />
                        <div className='icon-container' onClick={props.addUsers}>
                           <FaUserPlus />
                        </div>

                        {props.usersToAdd.length !== 0 &&
                           <div
                              className="userAddContainer"
                           >
                              {props.usersToAdd.map(user => {
                                 return (
                                    <div className='emailAddedContainer' key={user}>
                                       <span>{user}</span>
                                       <FaRegTrashAlt onClick={props.removeUsers} />
                                    </div>
                                 )
                              })}
                           </div>
                        }
                     </div>
                  </fieldset>
               </div>

               {props.loadingButton === 'default' ? (
                  <button
                     type='submit'
                     className='addUsersButton'
                  >
                     {t('modal.addUser.doneButton')}
                  </button>
               ) : (
                  <button
                     className='addUsersButton'
                     style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                     disabled
                  >
                     <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                     <span className="sr-only"></span>
                  </button>
               )}

            </form>

         </ModalBody>

      </Modal>
   )
}

export default ModalAddUsers
