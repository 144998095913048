import React from 'react'
import ReactLoading from 'react-loading'

import '../styles/components/Loading.css'

const LoadingInfiniteScroll = (props) => {
   return (
      <div className='loading-container' style={{ height: props.height, width: props.width }}>
         <ReactLoading
            type={props.type}
            color='#6B2722'
            height={25}
            width={25}
         />
      </div>

   )
}

export default LoadingInfiniteScroll
