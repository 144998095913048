import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import api from '../../services/api'

import '../../styles/components/modal/ModalTaskInGoGoGo.css'

const ModalTaskInGoGoGo = (props) => {
   const [gogogoCommentsData, setGogogoCommentsData] = useState([])

   const [t] = useTranslation()

   useEffect(() => {
      async function loadGoGoGoComments() {
         const res = await api.get('gogogo')

         setGogogoCommentsData(res.data)
      }

      loadGoGoGoComments()
   }, [])

   const gogogoCommentsOptions = gogogoCommentsData.map(comment => {
      return (
         { value: comment.id, label: comment.gogogo_fixed_comment }
      )
   })

   const customSelectStyles = {
      option: (provided, state) => ({
         ...provided,
         padding: 20,
         color: state.isSelected ? '#FFF' : '#000',
         backgroundColor: state.isSelected ? '#6B2722' : '#FFF',
         borderColor: state.isSelected ? '#6B2722' : '#FFF',
         borderWidth: 2,
         borderStyle: 'solid',
         "&:hover": {
            borderColor: state.isFocused ? '#6B2722' : '#FFF',
            borderWidth: state.isFocused ? 2 : 2,
         },
      }),
      control: () => ({
         width: "100%",
      }),
      singleValue: (provided, state) => {
         const opacity = state.isDisabled ? 0.5 : 1;
         const transition = 'opacity 300ms';

         return { ...provided, opacity, transition };
      }
   }

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalTasksInGoGoGo'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle modal-body">
            <h2 className="mt-2">GoGoGo</h2>
            <p className='subtitle'>{t('modal.taskInGoGoGo.subtitle')}</p>

            <form onSubmit={(e) => props.handleTaskGoGoGo(e, props.taskId)}>
               <fieldset>
                  <div className="input-block">
                     <label htmlFor='comments'>{t('modal.taskInGoGoGo.commentsOptionsLabel')}</label>
                     <Select
                        isSearchable={false}
                        styles={customSelectStyles}
                        options={gogogoCommentsOptions}
                        className='react-select-container'
                        classNamePrefix="react-select"
                        name="comments"
                        id="comments"
                        placeholder={t('modal.taskInGoGoGo.commentsOptionsPlaceholder')}
                        defaultValue={props.gogogo_comment_id}
                        onChange={e => props.setGoGoGoCommentId(e.value)}
                     />

                  </div>

                  <div className="input-block">
                     <label htmlFor='comments'>{t('modal.taskInGoGoGo.additionalCommentsLabel')}</label>
                     <textarea
                        placeholder={t('modal.taskInGoGoGo.additionalCommentsPlaceholder')}
                        rows='5'
                        name='additionalComment'
                        type='text'
                        value={props.additionalComment}
                        onChange={e => props.setAdditionalComment(e.target.value)}
                     />
                  </div>

               </fieldset>

               {props.loadingButton === 'default' ? (
                  <button type='submit' className='gogogoButton'>Go Go Go</button>
               ) : (
                  <button
                     className='gogogoButton'
                     style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                     disabled
                  >
                     <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                     <span className="sr-only"></span>
                  </button>
               )}
            </form>

         </ModalBody>

      </Modal>
   )
}

export default ModalTaskInGoGoGo
