import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'

import Input from '../../components/Input'
import FlagsLanguage from '../../components/FlagsLanguage'
import api from '../../services/api'

import logo from '../../assets/logo.svg'

import '../../styles/pages/auth/Signup.css'

const Signup = () => {
   const [loading, setLoading] = useState('default')

   const [name, setName] = useState('')
   const [email, setEmail] = useState('')
   const [cellphone, setCellphone] = useState('')
   const [password, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')

   const [reCaptchaToken, setReCaptchaToken] = useState()

   const [position, setPosition] = useState({ latitude: 0, longitude: 0 })

   const history = useHistory()

   const [t] = useTranslation()

   const reRef = useRef()

   function useQuery() {
      return new URLSearchParams(useLocation().search)
   }

   const query = useQuery()
   const emailQuery = query.get("email")
   const { token } = useParams()

   useEffect(() => {
      navigator.geolocation.getCurrentPosition(function (position) {
         setPosition(position.coords)
      })
   }, [])

   function onSubmitReCaptcha() {
      const token = reRef.current.getValue()

      setReCaptchaToken(token)
   }

   async function handleSignup(e) {
      e.preventDefault()

      setLoading('processing')

      if (emailQuery && token) {
         const data = {
            name,
            cellphone,
            password,
            confirmPassword,
            latitude: position.latitude,
            longitude: position.longitude,
            reCaptchaToken
         }

         try {
            const res = await api.post(`signup?email=${emailQuery}&token=${token}&isMobile=false`, data)

            toast.success(res.data.message)

            history.push('/login')

         } catch (e) {
            setLoading('default')

            e.response.data.errors.forEach(error => toast.error(error))
         }

      } else {

         const data = {
            name,
            email,
            cellphone,
            password,
            confirmPassword,
            latitude: position.latitude,
            longitude: position.longitude,
            reCaptchaToken
         }

         try {
            const res = await api.post('signup?isMobile=false', data)

            toast.success(res.data.message)

            history.push('/login')

         } catch (e) {
            setLoading('default')

            reRef.current.reset()

            e.response.data.errors.forEach(error => toast.error(error))
         }
      }

   }



   return (
      <div id="page-signup">

         <div className="image-logo">
            <div className="logo-container">
               <img src={logo} alt="GoGoGo" />
               <span className="enjoy">Enjoy, Living & Flying !</span>
            </div>
         </div>

         <div id="page-signup-content">

            <div className="flags-container">
               <FlagsLanguage />
            </div>

            <div className="content">
               <h1>{t('signup.title')}</h1>
               <h4>{t('signup.subTitle')}<Link to='/login'>Login</Link>.</h4>

               <main>
                  <form onSubmit={handleSignup}>
                     <fieldset>
                        <Input
                           name='name'
                           label={t('common.nameLabel')}
                           type='text'
                           placeholder={t('common.namePlaceholder')}
                           value={name}
                           onChange={e => setName(e.target.value)}
                        />

                        <Input
                           name='email'
                           label={t('common.emailLabel')}
                           type='email'
                           placeholder={t('common.emailPlaceholder')}
                           value={emailQuery ? emailQuery : email}
                           onChange={e => setEmail(e.target.value)}
                           readOnly={emailQuery ? true : false}
                        />

                        <div className="input-block">
                           <label htmlFor='cellphone'>{t('common.cellphoneLabel')}</label>
                           <NumberFormat
                              id='cellphone'
                              name='cellphone'
                              type='text'
                              placeholder={t('common.cellphonePlaceholder')}
                              format='(0##) # ####-####'
                              value={cellphone}
                              onChange={e => setCellphone(e.target.value)}
                           />
                        </div>

                        <Input
                           name='password'
                           label={t('common.passwordLabel')}
                           type='password'
                           placeholder={t('common.passwordPlaceholder')}
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                        />

                        <Input
                           name='confirmPassword'
                           label={t('common.confirmPasswordLabel')}
                           type='password'
                           placeholder={t('common.confirmPasswordPlaceholder')}
                           value={confirmPassword}
                           onChange={e => setConfirmPassword(e.target.value)}
                        />

                        <div className="reCaptcha">
                           <ReCAPTCHA
                              sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                              ref={reRef}
                              onChange={onSubmitReCaptcha}
                              theme='light'
                              size='normal'
                           />
                        </div>

                        {loading === 'default' ?
                           <button
                              type='submit'
                              className='enter'
                              disabled={reCaptchaToken ? false : true}
                           >
                              {t('common.registerButton')}
                           </button>
                           :
                           <button
                              className='enter-loading'
                              style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        }
                     </fieldset>
                  </form>

               </main>
            </div>
         </div>
      </div>
   )
}

export default Signup
