export function FirstLetterUp(value) {
    const obj = value.split(" ")

    for (let a = 0; a < obj.length; a++) {
        let w = obj[0];

        if (w[0] !== undefined) {
            obj[0] = w[0].toUpperCase() + w.slice(1);
        }
    }
    return obj.join(" ");
}

export function AllFirstLettersUp(value) {
    const name = value.toLowerCase().split(" ");

    for (let a = 0; a < name.length; a++) {
        let w = name[a];

        if (w[0] !== undefined) {
            name[a] = w[0].toUpperCase() + w.slice(1);
        }
    }

    return name.join(" ");
}

export function AllLettersUp(value) {
    const name = value.toLowerCase().split(" ");

    for (let a = 0; a < name.length; a++) {
        let w = name[a];

        if (w[0] !== undefined) {
            name[a] = w.toUpperCase();
        }
    }

    return name.join(" ");
}

