import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { BsInfoSquare, BsCardChecklist } from 'react-icons/bs'
import { FaUnlock } from 'react-icons/fa'
import { ImExit } from 'react-icons/im'
import { GrUserAdmin } from 'react-icons/gr'

import { useAuth } from '../../services/contexts/auth'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import Dropzone from '../../components/DropzoneArea'
import FlagsLanguage from '../../components/FlagsLanguage'

import api from '../../services/api'

import '../../styles/pages/app/Config.css'

const Config = () => {
   const [loadingAvatar, setLoadingAvatar] = useState(true)
   const [checkAvatarNull, setCheckAvatarNull] = useState(null)

   const [idUser, setIdUser] = useState()
   const [userName, setUserName] = useState('')
   const [userEmail, setUserEmail] = useState('')
   const [userAdmin, setUserAdmin] = useState(false)

   const [selectedAvatar, setSelectedAvatar] = useState('')
   const [selectedFileDropzone, setSelectedFileDropzone] = useState('')

   const { onSignOut, userId, idStorage } = useAuth()

   const [t] = useTranslation()

   useEffect(() => {
      if (userId) {
         setIdUser(userId)

      } else {
         setIdUser(idStorage)
      }

   }, [userId, idStorage])

   const onDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles[0]

      const fileUrl = URL.createObjectURL(file)

      setSelectedFileDropzone(fileUrl)
      setSelectedAvatar(file)

   }, [setSelectedAvatar])

   useEffect(() => {
      if (idUser) {
         async function loadUser() {
            const res = await api.get(`users/${idUser}`)

            setUserName(res.data.name)
            setUserEmail(res.data.email)
            setSelectedAvatar(res.data.user_avatar_url)
            setCheckAvatarNull(res.data.avatar)
            setUserAdmin(res.data.admin)
         }

         loadUser()

         setTimeout(() => {
            setLoadingAvatar(false)
         }, 1000)
      }

   }, [idUser])


   async function loadUser() {
      if (idUser) {
         const res = await api.get(`users/${idUser}`)

         setUserName(res.data.name)
         setUserEmail(res.data.email)
         setSelectedAvatar(res.data.user_avatar_url)
         setCheckAvatarNull(res.data.avatar)
         setUserAdmin(res.data.admin)
      }
   }

   async function handleChangeAvatar(e) {
      setLoadingAvatar(true)

      if (!selectedAvatar) {
         return
      }

      try {
         const data = new FormData()

         data.append('name', userName)
         data.append('avatar', selectedAvatar)

         await api.put(`users/${idUser}`, data)

         setSelectedFileDropzone('')
         loadUser()

         setTimeout(() => {
            setLoadingAvatar(false)
         }, 1000)

      } catch (e) {
         setLoadingAvatar(false)

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }


   async function handleChangePassword(e) {
      e.preventDefault()

      const data = { email: userEmail }

      try {
         const res = await api.post('forgotPassword', data)

         toast.success(res.data.message)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }


   function handleSignOut(e) {
      e.preventDefault()

      onSignOut()
   }

   return (
      <div id='page-config'>
         <PageHeader />

         <div className='container'>
            <div className="flags-container">
               <FlagsLanguage />
            </div>

            <div className='main-container'>
               <div className="header-name">
                  <h1>{userName}</h1>
               </div>

               <div className="content">
                  <div className="avatar-container">
                     {loadingAvatar ?
                        <Loading
                           height={150}
                           width={200}
                           type='spokes'
                        />
                        :
                        <Dropzone
                           onDrop={onDrop}
                           avatarLoaded={selectedAvatar}
                           selectedFileDropzone={selectedFileDropzone}
                           checkAvatarNull={checkAvatarNull}
                           userName={userName}
                        />
                     }

                     {selectedFileDropzone !== '' &&
                        <button className="button-save-avatar" onClick={handleChangeAvatar}>
                           <span>{t('config.saveAvatar')}</span>
                        </button>
                     }

                     <span className='change-avatar-text'>{t('config.clickToChangeAvatar')}</span>
                  </div>

                  <div className="options-container">
                     <a
                        className="options"
                        href='http://ajuda.gogogoyou.com.br/'
                        target='_blank'
                        rel="noopener noreferrer"
                     >
                        <BsInfoSquare size={20} color='#2E2E2E' />
                        <span>{t('config.help')}</span>
                     </a>

                     <Link to='/app/tasksCompleted' className="options">
                        <BsCardChecklist size={20} color='#2E2E2E' />
                        <span>{t('config.tasksCompleted')}</span>
                     </Link>

                     <button className="options" onClick={handleChangePassword}>
                        <FaUnlock size={20} color='#2E2E2E' />
                        <span>{t('config.changePassword')}</span>
                     </button>

                     {userAdmin &&
                        <Link to='/app/adminPage' className="options">
                           <GrUserAdmin size={20} color='#2E2E2E' />
                           <span>{t('config.adminPage')}</span>
                        </Link>
                     }

                     <button className="options-logout" onClick={handleSignOut}>
                        <ImExit size={20} color='#999898' />
                        <span className="logout">{t('config.logout')}</span>
                     </button>
                  </div>
               </div>

               <div className="footer">
                  <span>GoGoGo App - Beta Version by naitpe.com</span>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Config
