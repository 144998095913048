import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'

import brazilianFlag from 'svg-country-flags/svg/br.svg'
import usaFlag from 'svg-country-flags/svg/us.svg'

import '../styles/components/FlagsLanguage.css'

class FlagsLanguage extends Component {
   render() {
      const { i18n } = this.props

      return (
         <>
            <button onClick={() => i18n.changeLanguage('pt')}>
               <img src={brazilianFlag} alt='Brazilian flag' className='brFlag' />
            </button>

            <button onClick={() => i18n.changeLanguage('en')}>
               <img src={usaFlag} alt='USA flag' className='usFlag' />
            </button>
         </>
      )
   }
}

export default withTranslation()(FlagsLanguage)
