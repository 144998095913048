import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Switch from "react-switch"
import { FiPlusSquare } from 'react-icons/fi'
import { GiSandsOfTime } from 'react-icons/gi'
import { MdArrowDropDown } from 'react-icons/md'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { useAuth } from '../../services/contexts/auth'

import TaskCard from '../../components/TaskCard'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import RocketAnimation from '../../components/RocketAnimation'
import FocusAnimation from '../../components/FocusAnimation'
import ModalTaskDetails from '../../components/modal/ModalTaskDetails'
import ModalAddTasks from '../../components/modal/ModalAddTasks'
import ModalTaskInGoGoGo from '../../components/modal/ModalTaskInGoGoGo'

import api from '../../services/api'
import { AllFirstLettersUp } from '../../utils/functions'

import '../../styles/pages/app/Tasks.css'

let myTimeout

const Tasks = () => {
   const [loading, setLoading] = useState(true)
   const [loadingButtonAdd, setLoadingButtonAdd] = useState('default')
   const [loadingButtonAddWithDetails, setLoadingButtonAddWithDetails] = useState('default')

   const [rocketAnimationIsOpen, setRocketAnimationIsOpen] = useState(false)
   const [checkAnimationIsOpen, setCheckAnimationIsOpen] = useState(false)

   const [status, setStatus] = useState('')
   const [statusBackgroundColor, setStatusBackgroundColor] = useState('')
   const [statusColor, setStatusColor] = useState('')

   const [modalTaskDetails, setModalTaskDetails] = useState(false)
   const [modalAddTasks, setModalAddTasks] = useState(false)
   const [modalGoGoGo, setModalGoGoGo] = useState(false)

   const [showColorNow, setShowColorNow] = useState(false)
   const [showColorImportant, setShowColorImportant] = useState(false)
   const [showColorRegular, setShowColorRegular] = useState(false)
   const [taskIndex, setTaskIndex] = useState('')

   const [showTeamOptions, setShowTeamOptions] = useState(false)
   const [teamSelected, setTeamSelected] = useState([])

   const [showGogogo, setShowGoGoGo] = useState(false)

   const [idUser, setIdUser] = useState()
   const [taskId, setTaskId] = useState('')

   const [taskName, setTaskName] = useState('')
   const [taskType, setTaskType] = useState('')
   const [team_id, setTeamId] = useState(null)
   const [teamName, setTeamName] = useState(null)
   const [userIdSelected, setUserIdSelected] = useState()

   const [gogogo_comment_id, setGoGoGoCommentId] = useState('')
   const [additionalComment, setAdditionalComment] = useState('')

   const [tasksDataNow, setTasksDataNow] = useState([])
   const [tasksDataImportant, setTasksDataImportant] = useState([])
   const [tasksDataRegular, setTasksDataRegular] = useState([])
   const [teamsData, setTeamsData] = useState([])

   const [quantityTasksNow, setQuantityTasksNow] = useState()
   const [quantityTasksNowGoGoGo, setQuantityTasksNowGoGoGo] = useState()
   const [quantityTasksImportant, setQuantityTasksImportant] = useState()
   const [quantityTasksImportantGoGoGo, setQuantityTasksImportantGoGoGo] = useState()
   const [quantityTasksRegular, setQuantityTasksRegular] = useState()
   const [quantityTasksRegularGoGoGo, setQuantityTasksRegularGoGoGo] = useState()

   const [commentContent, setCommentContent] = useState('')

   const [focusIsStarted, setFocusIsStarted] = useState(false)
   const [time, setTime] = useState(20 * 60)
   const [timeFinish, setTimeFinish] = useState()
   const [timeBar, setTimeBar] = useState(0)

   const { userId, idStorage } = useAuth()

   const dropdownColorRef = useRef(null)
   const dropdownTeamOptions = useRef(null)

   const [t] = useTranslation()

   const minutes = Math.floor(time / 60)
   const seconds = time % 60

   useEffect(() => {
      if (focusIsStarted && time > 0) {
         let firstDelta = timeFinish - Date.now()

         setTime(Math.floor(firstDelta / 1000))

         myTimeout = setTimeout(() => {
            let delta = timeFinish - Date.now()

            setTime(Math.floor(delta / 1000))
            setTimeBar(100 - (((Math.floor(delta / 1000)) * 100) / (20 * 60)))

         }, 1000)

      } else if (focusIsStarted && time === 0) {
         setCheckAnimationIsOpen(true)

         setTimeout(() => {
            setFocusIsStarted(false)
            setTime(20 * 60)
            setTimeFinish()
            setTimeBar(0)
            clearTimeout(myTimeout)

            setCheckAnimationIsOpen(false)

         }, 6000)
      }
   }, [focusIsStarted, time, timeFinish])


   useEffect(() => {
      if (userId) {
         setIdUser(userId)
         setUserIdSelected(userId)

      } else {
         setIdUser(idStorage)
         setUserIdSelected(idStorage)
      }

   }, [userId, idStorage])

   const loadQuantityTasksNow = useCallback(async () => {
      const totalTasksNoGoGoGo = await api.get(`tasks?type=now&gogogo=false&completed=false`)
      const totalTasksInGoGoGo = await api.get(`tasks?type=now&gogogo=true&completed=false`)

      const totalTasks = totalTasksNoGoGoGo.data.length + totalTasksInGoGoGo.data.length

      setQuantityTasksNow(totalTasks)
      setQuantityTasksNowGoGoGo(totalTasksInGoGoGo.data.length)

   }, [])

   const loadQuantityTasksImportant = useCallback(async () => {
      const totalTasksNoGoGoGo = await api.get(`tasks?type=important&gogogo=false&completed=false`)
      const totalTasksInGoGoGo = await api.get(`tasks?type=important&gogogo=true&completed=false`)

      const totalTasks = totalTasksNoGoGoGo.data.length + totalTasksInGoGoGo.data.length

      setQuantityTasksImportant(totalTasks)
      setQuantityTasksImportantGoGoGo(totalTasksInGoGoGo.data.length)

   }, [])

   const loadQuantityTasksRegular = useCallback(async () => {
      const totalTasksNoGoGoGo = await api.get(`tasks?type=regular&gogogo=false&completed=false`)
      const totalTasksInGoGoGo = await api.get(`tasks?type=regular&gogogo=true&completed=false`)

      const totalTasks = totalTasksNoGoGoGo.data.length + totalTasksInGoGoGo.data.length

      setQuantityTasksRegular(totalTasks)
      setQuantityTasksRegularGoGoGo(totalTasksInGoGoGo.data.length)

   }, [])

   const loadTasksNow = useCallback(async () => {
      const res = await api.get(`tasks?type=now&gogogo=${showGogogo}&completed=false&team=${teamSelected}`)

      setTasksDataNow(res.data)
      await loadQuantityTasksNow()
      await loadStatus()

   }, [showGogogo, loadQuantityTasksNow, teamSelected])

   const loadTasksImportant = useCallback(async () => {
      const res = await api.get(`tasks?type=important&gogogo=${showGogogo}&completed=false&team=${teamSelected}`)

      setTasksDataImportant(res.data)
      await loadQuantityTasksImportant()
      await loadStatus()

   }, [showGogogo, loadQuantityTasksImportant, teamSelected])

   const loadTasksRegular = useCallback(async () => {
      const res = await api.get(`tasks?type=regular&gogogo=${showGogogo}&completed=false&team=${teamSelected}`)

      setTasksDataRegular(res.data)
      await loadQuantityTasksRegular()
      await loadStatus()

   }, [showGogogo, loadQuantityTasksRegular, teamSelected])

   const loadTeams = useCallback(async () => {
      const res = await api.get('teams')

      const adjustingTeamsData = res.data.map(team => {
         return {
            id: team.id,
            status_backgroundColor: team.status_backgroundColor,
            status_color: team.status_color,
            team_name: AllFirstLettersUp(team.team_name),
            team_status: team.team_status,
            users: team.users
         }
      })

      setTeamsData(adjustingTeamsData)

   }, [])

   const loadAll = useCallback(async () => {
      await loadTasksNow()
      await loadTasksImportant()
      await loadTasksRegular()
      await loadTeams()

      setLoading(false)

   }, [loadTasksNow, loadTasksImportant, loadTasksRegular, loadTeams])

   useEffect(() => {
      loadAll()

   }, [loadAll])

   const updateOrderTasksNow = useCallback(async () => {
      for (let i = 0; i < tasksDataNow.length; i++) {
         await api.put(`tasksOrder/${tasksDataNow[i].id}`, { taskOrder: i })
      }
   }, [tasksDataNow])

   const updateOrderTasksImportant = useCallback(async () => {
      for (let i = 0; i < tasksDataImportant.length; i++) {
         await api.put(`tasksOrder/${tasksDataImportant[i].id}`, { taskOrder: i })
      }
   }, [tasksDataImportant])

   const updateOrderTasksRegular = useCallback(async () => {
      for (let i = 0; i < tasksDataRegular.length; i++) {
         await api.put(`tasksOrder/${tasksDataRegular[i].id}`, { taskOrder: i })
      }
   }, [tasksDataRegular])

   useEffect(() => {
      updateOrderTasksNow()
      updateOrderTasksImportant()
      updateOrderTasksRegular()

   }, [updateOrderTasksNow, updateOrderTasksImportant, updateOrderTasksRegular])


   useEffect(() => {
      if (team_id !== null) {
         async function loadTeamName() {
            const res = await api.get(`teams/${team_id}`)

            setTeamName(res.data.team_name)
         }

         loadTeamName()
      }

   }, [team_id])

   const handleShowColorNow = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorNow) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorNow(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorNow) {
         setShowColorNow(false);
         setTaskIndex('');

      } else {
         setShowColorNow(true);
         setTaskIndex(index);
      }

   }, [showColorNow]);

   useEffect(() => {
      if (showColorNow) {
         document.addEventListener("click", handleShowColorNow);

         return () => document.removeEventListener("click", handleShowColorNow);
      }

   }, [showColorNow, handleShowColorNow]);

   const handleShowColorImportant = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorImportant) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorImportant(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorImportant) {
         setShowColorImportant(false);
         setTaskIndex('');

      } else {
         setShowColorImportant(true);
         setTaskIndex(index);
      }

   }, [showColorImportant]);

   useEffect(() => {
      if (showColorImportant) {
         document.addEventListener("click", handleShowColorImportant);

         return () => document.removeEventListener("click", handleShowColorImportant);
      }

   }, [showColorImportant, handleShowColorImportant]);

   const handleShowColorRegular = useCallback((e, index) => {
      if (dropdownColorRef.current && showColorRegular) {
         if (!dropdownColorRef.current.contains(e.target)) {
            setShowColorRegular(false);
            setTaskIndex('');
         }

      } else if (!dropdownColorRef.current && showColorRegular) {
         setShowColorRegular(false);
         setTaskIndex('');

      } else {
         setShowColorRegular(true);
         setTaskIndex(index);
      }

   }, [showColorRegular]);

   useEffect(() => {
      if (showColorRegular) {
         document.addEventListener("click", handleShowColorRegular);

         return () => document.removeEventListener("click", handleShowColorRegular);
      }

   }, [showColorRegular, handleShowColorRegular]);


   const handleShowTeamOptions = useCallback((e) => {

      if (dropdownTeamOptions.current && showTeamOptions) {
         if (!dropdownTeamOptions.current.contains(e.target)) {
            setShowTeamOptions(false)

         }

      } else if (!dropdownTeamOptions.current && showTeamOptions) {
         setShowTeamOptions(false)

      } else {
         setShowTeamOptions(true)
      }

   }, [showTeamOptions]);

   useEffect(() => {

      if (showTeamOptions) {
         document.addEventListener("click", handleShowTeamOptions)

         return () => document.removeEventListener("click", handleShowTeamOptions);
      }

   }, [showTeamOptions, handleShowTeamOptions])

   async function loadStatus() {
      const res = await api.get('myStatusDaily')

      setStatus(res.data[0].name)
      setStatusBackgroundColor(res.data[0].backgroundColor)
      setStatusColor(res.data[0].color)
   }

   async function handleAddTask(e) {
      e.preventDefault()

      setLoadingButtonAdd('processing')

      const data = { taskName, taskType, team_id, userIdSelected }

      try {
         const res = await api.post('tasks', data)

         toast.success(res.data.message)

         setTaskName('')
         setTeamId(null)
         setTeamName(null)
         setUserIdSelected(idUser)

         setModalAddTasks(false)

         if (taskType === 'now') {
            await loadTasksNow()
            setLoadingButtonAdd('default')

            return scrollToBottomNow()
         }

         if (taskType === 'important') {
            await loadTasksImportant()
            setLoadingButtonAdd('default')

            return scrollToBottomImportant()
         }

         if (taskType === 'regular') {
            await loadTasksRegular()
            setLoadingButtonAdd('default')

            return scrollToBottomRegular()
         }

      } catch (e) {
         setLoadingButtonAdd('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleAddTaskWithDetails(e) {
      e.preventDefault()

      setLoadingButtonAddWithDetails('processing')

      const data = { taskName, taskType, team_id, userIdSelected }

      try {
         const res = await api.post('tasks', data)

         toast.success(res.data.message)

         setTaskName('')
         setTeamId(null)
         setTeamName(null)
         setUserIdSelected(idUser)

         setModalAddTasks(false)

         if (taskType === 'now') {
            await loadTasksNow()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomNow()
         }

         if (taskType === 'important') {
            await loadTasksImportant()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomImportant()
         }

         if (taskType === 'regular') {
            await loadTasksRegular()
            setLoadingButtonAddWithDetails('default')

            scrollToBottomRegular()
         }

         return handleOpenModalTaskDetails(res.data.taskCreated[0].id)

      } catch (e) {
         setLoadingButtonAddWithDetails('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleTaskGoGoGo(e, id) {
      e.preventDefault()

      setLoadingButtonAdd('processing')

      const data = { gogogo_comment_id, additionalComment }

      try {
         await api.put(`gogogo/${id}`, data)

         setGoGoGoCommentId('')
         setAdditionalComment('')

         loadTasksNow()
         loadTasksImportant()
         loadTasksRegular()

         setModalGoGoGo(false)
         setLoadingButtonAdd('default')
         setRocketAnimationIsOpen(true)

         setTimeout(() => {
            setRocketAnimationIsOpen(false)
         }, 1500)

      } catch (e) {
         setLoadingButtonAdd('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function toggleSwitch() {
      setShowGoGoGo(!showGogogo)
   }

   function handleOpenModalAddTasks(taskType) {
      setModalAddTasks(!modalAddTasks)

      setTaskType(taskType)
      setTaskName('')
      setTeamId(null)
      setTeamName(null)

      loadTeams()
   }

   function handleOpenModalGoGoGo(id) {
      setModalGoGoGo(!modalGoGoGo)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')
      }
   }

   function handleOpenModalTaskDetails(id) {
      setModalTaskDetails(!modalTaskDetails)

      if (Number(id)) {
         setTaskId(id)

      } else {
         setTaskId('')

         setCommentContent('')

         loadTasksNow()
         loadTasksImportant()
         loadTasksRegular()
      }
   }

   function scrollToBottomNow() {
      const column = document.getElementById("columnNow")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   function scrollToBottomImportant() {
      const column = document.getElementById("columnImportant")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   function scrollToBottomRegular() {
      const column = document.getElementById("columnRegular")
      if (column) {
         column.scrollTo(0, column.scrollHeight)
      }
   }

   async function handleUpdateColor(e, taskId, cardColor) {
      e.preventDefault()

      const data = { card_color: cardColor }

      try {
         await api.put(`tasksColor/${taskId}`, data)

         loadTasksNow()
         loadTasksImportant()
         loadTasksRegular()

         setShowColorNow(false)
         setShowColorImportant(false)
         setShowColorRegular(false)
         setTaskIndex('')

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function handleStartFocus() {
      setFocusIsStarted(true)

      setTimeFinish(Date.now() + ((20 * 60 * 1000) + 500))
   }

   function handleResetFocus() {
      clearTimeout(myTimeout)
      setFocusIsStarted(false)
      setTime(20 * 60)
      setTimeFinish()
      setTimeBar(0)
   }

   async function handleEditTaskTypeOnDragDrop(taskId, taskType) {

      const data = { taskType }

      try {
         await api.put(`tasksType/${taskId}`, data)

         loadStatus()

         await loadQuantityTasksNow()
         await loadQuantityTasksImportant()
         await loadQuantityTasksRegular()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const reorderSameList = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   const reorderOtherLists = (sourceList, destinationList, startIndex, endIndex) => {
      const source = Array.from(sourceList);
      const destination = Array.from(destinationList);

      const [removed] = source.splice(startIndex, 1);
      destination.splice(endIndex, 0, removed);

      return { source, destination };
   };


   const onDragEnd = async (result) => {
      const { source, destination, draggableId } = result;

      if (!destination) {
         return;
      }

      if (destination.index === source.index && destination.droppableId === source.droppableId) {
         return;
      }

      if (destination.droppableId === source.droppableId) {
         const tasksReorder = reorderSameList(
            destination.droppableId === 'now'
               ? tasksDataNow
               : destination.droppableId === 'important'
                  ? tasksDataImportant
                  : destination.droppableId === 'regular'
                  && tasksDataRegular,
            source.index,
            destination.index
         );

         if (destination.droppableId === 'now') {
            setTasksDataNow(tasksReorder)

         } else if (destination.droppableId === 'important') {
            setTasksDataImportant(tasksReorder)

         } else if (destination.droppableId === 'regular') {
            setTasksDataRegular(tasksReorder)

         }

      } else {
         const tasksReorder = reorderOtherLists(
            source.droppableId === 'now'
               ? tasksDataNow
               : source.droppableId === 'important'
                  ? tasksDataImportant
                  : tasksDataRegular,
            destination.droppableId === 'now'
               ? tasksDataNow
               : destination.droppableId === 'important'
                  ? tasksDataImportant
                  : tasksDataRegular,
            source.index,
            destination.index
         )

         if (source.droppableId === 'now') {
            setTasksDataNow(tasksReorder.source)

         } else if (source.droppableId === 'important') {
            setTasksDataImportant(tasksReorder.source)

         } else if (source.droppableId === 'regular') {
            setTasksDataRegular(tasksReorder.source)

         }

         if (destination.droppableId === 'now') {
            setTasksDataNow(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'now')

         } else if (destination.droppableId === 'important') {
            setTasksDataImportant(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'important')

         } else if (destination.droppableId === 'regular') {
            setTasksDataRegular(tasksReorder.destination)
            await handleEditTaskTypeOnDragDrop(Number(draggableId), 'regular')
         }
      }
   };

   function handleSelectTeam(id) {
      const checkboxes = document.getElementById('all');

      checkboxes.checked = false

      const alreadySelected = teamSelected.findIndex(item => item === id)

      if (alreadySelected >= 0) {
         const filteredTeam = teamSelected.filter(item => item !== id)

         setTeamSelected(filteredTeam)

      } else {
         setTeamSelected([...teamSelected, id])
      }
   }

   function handleSelectAll() {
      setTeamSelected([])
   }

   return (
      <DragDropContext
         onDragEnd={onDragEnd}
      >
         <div
            id='page-tasks'
            style={focusIsStarted ? { backgroundColor: '#6B2722' } : { backgroundColor: '#262626' }}
         >
            <PageHeader
               focusIsStarted={focusIsStarted}
               minutes={minutes}
               seconds={seconds}
               timeBar={timeBar}
            />

            <div className='container'>
               {loading ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <RocketAnimation
                        isOpen={rocketAnimationIsOpen}
                     />

                     <FocusAnimation
                        isOpen={checkAnimationIsOpen}
                     />

                     <div className="header-container">
                        <div className="status-container" style={{ background: statusBackgroundColor }}>
                           <span style={{ color: statusColor }} >{t('task.youAre')}: {status}</span>
                        </div>

                        {!focusIsStarted ? (
                           <div className="focus-container">
                              <span>{t('task.startFocus')}</span>
                              <button
                                 type='button'
                                 className='focus-button'
                                 onClick={handleStartFocus}
                              >
                                 <GiSandsOfTime size={15} color='#FFF' />
                              </button>
                           </div>
                        ) : (
                           <div className="focus-container">
                              <span>{t('task.stopFocus')}</span>
                              <button
                                 type='button'
                                 className='focus-button-actived'
                                 onClick={handleResetFocus}
                              >
                                 <GiSandsOfTime size={15} color='#6B2722' />
                              </button>
                           </div>
                        )}

                        <div className="filter-container">
                           <div className="filter">
                              {showGogogo ?
                                 <span>{t('task.showNotGoGoGo')}</span>
                                 :
                                 <span>{t('task.showGoGoGo')}</span>
                              }
                              <Switch
                                 checked={showGogogo}
                                 onChange={toggleSwitch}
                                 offColor='#B1B1B1'
                                 onColor='#6B2722'
                                 offHandleColor='#6B2722'
                                 onHandleColor='#B1B1B1'
                                 uncheckedIcon={false}
                                 checkedIcon={false}
                                 height={16}
                                 width={39}
                              />
                           </div>
                        </div>

                        <div className="dropdown">
                           <div className="filter-team-container" onClick={handleShowTeamOptions}>
                              <span>{t('task.filterByTeam')}</span>
                              <MdArrowDropDown size={20} color='#111111' />
                           </div>

                           <div ref={dropdownTeamOptions} className='menu-select-team'>
                              {showTeamOptions &&
                                 <div className="select-container">
                                    <div
                                       className='team-select'
                                    >
                                       {t('task.ALL')}

                                       <input
                                          type="checkbox"
                                          value={[]}
                                          id='all'
                                          onChange={() => handleSelectAll()}
                                       />
                                    </div>
                                    {teamsData.map(team => {
                                       let isChecked = (teamSelected.indexOf(team.id) >= 0);

                                       return (
                                          <div
                                             key={team.id}
                                             className='team-select'
                                          >
                                             {team.team_name}

                                             <input
                                                type="checkbox"
                                                value={team.id}
                                                id={team.id}
                                                onChange={e => handleSelectTeam(team.id)}
                                                checked={isChecked}
                                             />
                                          </div>
                                       )
                                    })}
                                 </div>
                              }
                           </div>

                        </div>
                     </div>

                     <main>

                        <div className={showGogogo ? "column-gogogo" : "column"}>
                           <div className="types">
                              <span className='task-type'>{t('task.now')}</span>
                              <span className='counter'>{quantityTasksNowGoGoGo}/{quantityTasksNow}</span>
                           </div>

                           <Droppable
                              droppableId="now"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnNow'
                                    className="column-two-parts"
                                 >
                                    {tasksDataNow.map((task, index) => (
                                       <TaskCard
                                          key={task.id}
                                          task={task}
                                          index={index}
                                          handleOpenModalTaskDetails={handleOpenModalTaskDetails}
                                          handleShowColor={handleShowColorNow}
                                          dropdownColorRef={dropdownColorRef}
                                          showColor={showColorNow}
                                          taskIndex={taskIndex}
                                          handleUpdateColor={handleUpdateColor}
                                          handleOpenModalGoGoGo={handleOpenModalGoGoGo}
                                          taskType='now'
                                       />
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddTasks('now')}>
                              <FiPlusSquare size={20} color='#636363' />
                              <span>{t('task.buttonNewTask')}</span>
                           </button>
                        </div>

                        <div className={showGogogo ? "column-gogogo" : "column"}>
                           <div className="types">
                              <span className='task-type'>{t('task.important')}</span>
                              <span className='counter'>{quantityTasksImportantGoGoGo}/{quantityTasksImportant}</span>
                           </div>

                           <Droppable
                              droppableId="important"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id='columnImportant'
                                    className="column-two-parts"
                                 >
                                    {tasksDataImportant.map((task, index) => (
                                       <TaskCard
                                          key={task.id}
                                          task={task}
                                          index={index}
                                          handleOpenModalTaskDetails={handleOpenModalTaskDetails}
                                          handleShowColor={handleShowColorImportant}
                                          dropdownColorRef={dropdownColorRef}
                                          showColor={showColorImportant}
                                          taskIndex={taskIndex}
                                          handleUpdateColor={handleUpdateColor}
                                          handleOpenModalGoGoGo={handleOpenModalGoGoGo}
                                          taskType='important'
                                       />
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddTasks('important')}>
                              <FiPlusSquare size={20} color='#636363' />
                              <span>{t('task.buttonNewTask')}</span>
                           </button>
                        </div>

                        <div className={showGogogo ? "column-gogogo" : "column"}>
                           <div className="types">
                              <span className='task-type'>{t('task.regular')}</span>
                              <span className='counter'>{quantityTasksRegularGoGoGo}/{quantityTasksRegular}</span>
                           </div>

                           <Droppable
                              droppableId="regular"
                              direction='vertical'
                              ignoreContainerClipping
                           >
                              {(provided, snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="column-two-parts"
                                    id='columnRegular'
                                 >
                                    {tasksDataRegular.map((task, index) => (
                                       <TaskCard
                                          key={task.id}
                                          task={task}
                                          index={index}
                                          handleOpenModalTaskDetails={handleOpenModalTaskDetails}
                                          handleShowColor={handleShowColorRegular}
                                          dropdownColorRef={dropdownColorRef}
                                          showColor={showColorRegular}
                                          taskIndex={taskIndex}
                                          handleUpdateColor={handleUpdateColor}
                                          handleOpenModalGoGoGo={handleOpenModalGoGoGo}
                                          taskType='regular'
                                       />
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}

                           </Droppable>

                           <button className="new-task" onClick={() => handleOpenModalAddTasks('regular')}>
                              <FiPlusSquare size={20} color='#636363' />
                              <span>{t('task.buttonNewTask')}</span>
                           </button>
                        </div>
                     </main>

                     <ModalTaskDetails
                        isOpen={modalTaskDetails}
                        toggle={handleOpenModalTaskDetails}
                        taskId={taskId}
                        loadTasksNow={loadTasksNow}
                        loadTasksImportant={loadTasksImportant}
                        loadTasksRegular={loadTasksRegular}
                        commentContent={commentContent}
                        setCommentContent={setCommentContent}
                     />

                     <ModalAddTasks
                        isOpen={modalAddTasks}
                        toggle={handleOpenModalAddTasks}
                        taskName={taskName}
                        setTaskName={setTaskName}
                        taskType={taskType}
                        setTaskType={setTaskType}
                        team_id={team_id}
                        setTeamId={setTeamId}
                        teamName={teamName}
                        idUser={idUser}
                        setUserIdSelected={setUserIdSelected}
                        handleAddTask={handleAddTask}
                        handleAddTaskWithDetails={handleAddTaskWithDetails}
                        teamsData={teamsData}
                        loadingButtonAdd={loadingButtonAdd}
                        loadingButtonAddWithDetails={loadingButtonAddWithDetails}
                     />

                     <ModalTaskInGoGoGo
                        isOpen={modalGoGoGo}
                        toggle={handleOpenModalGoGoGo}
                        taskId={taskId}
                        gogogo_comment_id={gogogo_comment_id}
                        setGoGoGoCommentId={setGoGoGoCommentId}
                        additionalComment={additionalComment}
                        setAdditionalComment={setAdditionalComment}
                        handleTaskGoGoGo={handleTaskGoGoGo}
                        loadingButton={loadingButtonAdd}
                     />
                  </>
               }
            </div>
         </div >
      </DragDropContext>
   )
}

export default Tasks
