import React from 'react'

const DropdownColor = (props) => {

   return (
      <div ref={props.dropdownColorRef} className='menu-select'>
         {props.showColor && props.taskIndex === props.index &&
            <div className="select-container-color">
               <button
                  className='color'
                  style={{ background: '#EB1400' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#EB1400')}
               />

               <button
                  className='color'
                  style={{ background: '#F59A47' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#F59A47')}
               />

               <button
                  className='color'
                  style={{ background: '#EB8178' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#EB8178')}
               />

               <button
                  className='color'
                  style={{ background: '#60EB9E' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#60EB9E')}
               />

               <button
                  className='color'
                  style={{ background: '#746CEB' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#746CEB')}
               />

               <button
                  className='color'
                  style={{ background: '#EB00BA' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#EB00BA')}
               />

               <button
                  className='color'
                  style={{ background: '#EB78D2' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#EB78D2')}
               />

               <button
                  className='color'
                  style={{ background: '#7CEB60' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#7CEB60')}
               />

               <button
                  className='color'
                  style={{ background: '#6CABEB' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#6CABEB')}
               />

               <button
                  className='color'
                  style={{ background: '#FCFCFF' }}
                  onClick={e => props.handleUpdateColor(e, props.taskId, '#FCFCFF')}
               />

            </div>
         }
      </div>


   )
}

export default DropdownColor
