import React from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'

import '../../styles/components/modal/ModalSaveConfirmation.css'

const ModalSaveConfirmation = (props) => {

   const [t] = useTranslation()

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modalSaveConfirmation'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle text-center p-5 modal-body">
            <h2 className="mt-2">{t('modal.saveConfirmation.title')}</h2>
            <div className="warning-container">
               <p className='mt-5 phrase'><strong>{t('modal.saveConfirmation.warning')}</strong></p>

               <p className='confirm'>{t('modal.saveConfirmation.warningConfirm')}</p>
            </div>

            <div className="buttonContainer">
               <button className="buttonSave" onClick={props.handleClickOnSaveButton}>{t('modal.saveConfirmation.buttonExitAndSave')}</button>
               <button className="buttonClose" onClick={props.handleOpenModalConfirmIfSaveOrNot}>{t('modal.saveConfirmation.buttonExitWithoutSave')}</button>
            </div>

         </ModalBody>
      </Modal>
   )
}

export default ModalSaveConfirmation
