import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { HiOutlineCog } from 'react-icons/hi'
import { FaTasks, FaChartPie } from 'react-icons/fa'
import crypto from 'crypto-js'

import ModalDeleteConfirm from './modal/ModalDeleteConfirm'

import api from '../services/api'

import '../styles/pages/app/Teams.css'


function ContextAwareToggle({ children, eventKey, callback, teamId, setShowConfig, showConfig,
   loadTeams, handleOpenModalAddUsers, handleOpenModalEditTeamName, handleShowMenu, dropdownMenu }) {

   const [modalDelete, setModalDelete] = useState(false)

   const [t] = useTranslation()

   const team_id = teamId

   const currentEventKey = useContext(AccordionContext);

   const openTeamConfigButton = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
   );

   const isCurrentEventKey = currentEventKey === eventKey

   async function handleDeleteTeam(e) {
      e.preventDefault()

      try {
         await api.delete(`teams/${team_id}`)

         loadTeams()
         setModalDelete(false)
         setShowConfig(false)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function handleModalDeleteVisible() {
      setModalDelete(!modalDelete)
   }

   return (
      <div
         className="card-team"
      >
         <div className="card-small-team" onClick={openTeamConfigButton}>
            {children}
         </div>

         <Link className="link-team-tasks" to={`/app/teamTasks/${encodeURIComponent(crypto.DES.encrypt(String(team_id), 'idTeam').toString())}`}>
            <FaTasks size={18} color="#676768" />
         </Link>

         <Link
            to={{
               pathname: '/app/dashboardTeam',
               state: team_id,
            }}
            className="link-team-dashboard"
         >
            <FaChartPie size={18} color="#676768" />
         </Link>

         {
            isCurrentEventKey ?
               <div className="dropdown">
                  <button type="button" className="cogButton" onClick={handleShowMenu}>
                     <HiOutlineCog />
                  </button>

                  {showConfig
                     ? (
                        <div className="menu" ref={dropdownMenu}>
                           <button
                              type='button'
                              onClick={() => handleOpenModalAddUsers(team_id)}
                           >
                              {t('modal.teamConfig.addNewUser')}
                           </button>

                           <button
                              type='button'
                              onClick={() => handleOpenModalEditTeamName(team_id)}
                           >
                              {t('modal.teamConfig.changeTeamName')}
                           </button>

                           <button
                              type='button'
                              onClick={handleModalDeleteVisible}
                           >
                              {t('modal.teamConfig.removeTeam')}
                           </button>

                           <ModalDeleteConfirm
                              isOpen={modalDelete}
                              toggle={handleModalDeleteVisible}
                              handleDelete={handleDeleteTeam}
                           />

                        </div>
                     )
                     :
                     null
                  }
               </div>
               :
               <div onClick={openTeamConfigButton} className='cogButton'>
                  <MdKeyboardArrowDown />
               </div>
         }
      </div >
   );
}

export default ContextAwareToggle
