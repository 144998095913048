import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Accordion, AccordionCollapse, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { BsThreeDots } from 'react-icons/bs'
import { FaPlus } from 'react-icons/fa'
import { FiSend } from 'react-icons/fi'

import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import ThumbnailLetters from '../../components/ThumbnailLetters'
import ContextAwareToggle from '../../components/ContextAwareToggle'
import ModalAddTeams from '../../components/modal/ModalAddTeams'
import ModalAddUsers from '../../components/modal/ModalAddUsers'
import ModalEditTeamName from '../../components/modal/ModalEditTeamName'
import ModalSendMotivation from '../../components/modal/ModalSendMotivation'

import api from '../../services/api'

import '../../styles/pages/app/Teams.css'


const Teams = () => {
   const [loading, setLoading] = useState(true)
   const [loadingButton, setLoadingButton] = useState('default')

   const [showConfig, setShowConfig] = useState(false)
   const [indexUserOnTeamConfig, setIndexUserOnTeamConfig] = useState('')

   const [modalAddTeams, setModalAddTeams] = useState(false)
   const [modalAddUsers, setModalAddUsers] = useState(false)
   const [modalEditTeamName, setModalEditTeamName] = useState(false)
   const [modalMotivationIsOpen, setModalMotivationIsOpen] = useState(false)

   const [isPersonalizedIncentive, setIsPersonalizedIncentive] = useState(false)
   const [personalizedIncentive, setPersonalizedIncentive] = useState('')

   const [teamId, setTeamId] = useState('')
   const [teamName, setTeamName] = useState('')

   const [userId, setUserId] = useState('')
   const [userAdmin, setUserAdmin] = useState(false)

   const [usersOnTeamSelectedData, setUsersOnTeamSelectedData] = useState([])

   const [usersToAdd, setUsersToAdd] = useState([])
   const [email, setEmail] = useState('')

   const [teamsData, setTeamsData] = useState([])

   const [t] = useTranslation()

   const dropdownMenu = useRef(null)
   const dropdownUserOnTeamConfig = useRef(null)


   const handleShowMenu = useCallback((e) => {
      if (dropdownMenu.current && showConfig) {
         if (!dropdownMenu.current.contains(e.target)) {
            setShowConfig(false)
         }

      } else if (!dropdownMenu.current && showConfig) {
         setShowConfig(false)
      } else {
         setShowConfig(true)
      }

   }, [showConfig])

   const handleShowUserOnTeamConfig = useCallback((e) => {
      if (dropdownUserOnTeamConfig.current && indexUserOnTeamConfig !== '') {
         if (!dropdownUserOnTeamConfig.current.contains(e.target)) {
            setIndexUserOnTeamConfig('')
         }

      } else if (!dropdownUserOnTeamConfig.current && indexUserOnTeamConfig !== '') {
         setIndexUserOnTeamConfig('')

      }

   }, [indexUserOnTeamConfig])

   const loadTeams = useCallback(async () => {
      const res = await api.get('teams')

      setTeamsData(res.data)
   }, [])

   const loadAll = useCallback(async () => {
      await loadTeams()

      setLoading(false)

   }, [loadTeams])

   useEffect(() => {
      loadAll()

   }, [loadAll])

   useEffect(() => {

      if (showConfig) {
         document.addEventListener("click", handleShowMenu)

         return () => document.removeEventListener("click", handleShowMenu);
      }

   }, [showConfig, handleShowMenu])

   useEffect(() => {

      if (indexUserOnTeamConfig !== '') {
         document.addEventListener("click", handleShowUserOnTeamConfig)

         return () => document.removeEventListener("click", handleShowUserOnTeamConfig);
      }

   }, [indexUserOnTeamConfig, handleShowUserOnTeamConfig])

   async function handleAddTeams() {
      const data = { teamName }

      try {
         const res = await api.post('teams', data)

         setTeamId(res.data.team_id)

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function addUsers(e) {
      e.preventDefault()

      if (email === '') {
         return
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
         return toast.error('O endereço de e-mail é inválido.')
      }

      setUsersToAdd([
         ...usersToAdd,
         email
      ])

      setEmail('')
   }

   function removeUsers(name, i) {
      let user = usersToAdd.slice();
      user.splice(i, 1);
      setUsersToAdd(user)
   }

   async function handleAddUsers(e) {
      e.preventDefault()

      setLoadingButton('processing')

      if (usersToAdd.length === 0) {
         await loadTeams()

         setTeamName('')

         setModalAddTeams(false)
         setModalAddUsers(false)
         setShowConfig(false)

         setLoadingButton('default')
      }

      async function addAllUsers(element, index) {
         try {
            await api.post(`teamMembers/${teamId}`, {
               email: element
            })

            await loadTeams()

            setModalAddTeams(false)
            setModalAddUsers(false)
            setShowConfig(false)
            setLoadingButton('default')

         } catch (e) {
            setLoadingButton('default')

            e.response.data.errors.forEach(error => toast.error(error))
         }
      }

      usersToAdd.forEach(addAllUsers)
      setUsersToAdd([])
      setTeamName('')
      setTeamId('')

      await loadTeams()

      setModalAddTeams(false)
      setModalAddUsers(false)
      setShowConfig(false)
   }

   async function handleTeamNameEdit(e, teamId) {
      e.preventDefault()

      setLoadingButton('processing')

      const data = { teamName }

      try {
         await api.put(`teams/${teamId}`, data)

         setTeamName('')
         setTeamId('')

         await loadTeams()

         setModalEditTeamName(false)
         setShowConfig(false)

         setLoadingButton('default')

      } catch (e) {
         setLoadingButton('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleUserAdmin(e) {
      e.preventDefault()

      const data = { user_id: userId, team_admin: !userAdmin }

      try {
         await api.put(`teamMembers/${teamId}`, data)

         setIndexUserOnTeamConfig('')

         await loadTeams()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleDeleteUser(e) {
      e.preventDefault()

      try {
         await api.delete(`teamMembers/${teamId}?user_id=${userId}`)

         await loadTeams()
         setIndexUserOnTeamConfig('')

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   function avoidDeleteUserAlone() {
      return toast.error('Usuário não pode ser excluído, ele é o único da equipe.')
   }

   function handleOpenModalAddTeams() {
      setTeamId('')
      setTeamName('')
      setModalAddTeams(!modalAddTeams)

      setUsersToAdd([])
   }

   function handleOpenModalAddUsers(teamId) {
      setTeamId(teamId)
      setModalAddUsers(!modalAddUsers)

      setUsersToAdd([])
   }

   function handleOpenModalEditTeamName(teamId) {
      async function loadTeamInfo() {
         const res = await api.get(`teams/${teamId}`)

         setTeamName(res.data.team_name)
      }

      setTeamId(teamId)

      loadTeamInfo()

      setModalEditTeamName(!modalEditTeamName)
   }

   async function handleOpenUserOnTeamConfig(e, user_id, team_id, index) {
      e.preventDefault()

      if (indexUserOnTeamConfig !== '') {
         return setIndexUserOnTeamConfig('')
      }

      const resUsers = await api.get(`teams/${team_id}`)

      setUsersOnTeamSelectedData(resUsers.data.users)

      const resAdmin = await api.get(`teamMembers/${team_id}?user_id=${user_id}`)

      setUserAdmin(resAdmin.data.team_admin)

      setUserId(user_id)
      setTeamId(team_id)
      setIndexUserOnTeamConfig(index)
   }

   async function handleToSendMotivation(e, motivationPhrase) {
      e.preventDefault()

      setLoadingButton('processing')

      const data = { motivation_phrases: motivationPhrase, to_user_id: userId }

      try {
         const res = await api.post('notifications', data)

         toast.success(res.data.message)

         setModalMotivationIsOpen(false)
         setIsPersonalizedIncentive(false)

         setPersonalizedIncentive('')

         setLoadingButton('default')

      } catch (e) {
         setLoadingButton('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleOpenMotivationModal(id) {
      setModalMotivationIsOpen(!modalMotivationIsOpen)

      if (Number(id)) {
         setUserId(id)

      } else {
         setUserId('')

         setIsPersonalizedIncentive(false)
         setPersonalizedIncentive('')
      }
   }

   function handlePersonalizedIncentive() {
      setIsPersonalizedIncentive(true)
   }


   return (
      <div id='page-teams'>
         <PageHeader />

         <div className='container'>
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <>
                  <div className="header">
                     <h1>{t('team.title')}</h1>

                     <button
                        type="button"
                        className='button-add-team'
                        onClick={handleOpenModalAddTeams}
                     >
                        <FaPlus size={15} color='#FFF' />
                        <span>{t('team.buttonNewTeam')}</span>
                     </button>
                  </div>

                  <main>
                     <div id='scroll' className='scroll'>
                        {teamsData.length === 0 ?
                           <div className='no-teams'>
                              <h1>{t('team.noTeam')}</h1>
                           </div>
                           :
                           <Accordion className='accordionContainer' defaultActiveKey="0">
                              {teamsData.map(team => {
                                 return (
                                    <div key={team.id}>
                                       <ContextAwareToggle
                                          as={Card.Header}
                                          eventKey={team.id}
                                          teamId={team.id}
                                          handleOpenModalEditTeamName={handleOpenModalEditTeamName}
                                          handleOpenModalAddUsers={handleOpenModalAddUsers}
                                          loadTeams={loadTeams}
                                          handleShowMenu={handleShowMenu}
                                          setShowConfig={setShowConfig}
                                          showConfig={showConfig}
                                          dropdownMenu={dropdownMenu}
                                       >
                                          <h1>{team.team_name}</h1>
                                          <div className='pill-team-status' style={{ backgroundColor: team.status_backgroundColor, color: team.status_color }}>
                                             <span>{team.team_status}</span>
                                          </div>
                                       </ContextAwareToggle>

                                       <AccordionCollapse className='accordionCollapse' eventKey={team.id}>
                                          <>
                                             {team.users.map((user, index) => {
                                                return (
                                                   <div key={user.user_id} className='contentAccordion' >

                                                      <div className='avatarNameStatusContainer'>
                                                         {user.user_avatar_url === null || user.user_avatar_url === undefined ?
                                                            <div className='noAvatar' style={{ backgroundColor: '#A61D17' }} >
                                                               <ThumbnailLetters rounded text={user.name} className="m-auto" />
                                                            </div>
                                                            :
                                                            <>
                                                               {user.user_avatar_url.substring(user.user_avatar_url.lastIndexOf('.') + 1).toLowerCase() === 'gif' ||
                                                                  user.user_avatar_url.substring(user.user_avatar_url.lastIndexOf('.') + 1).toLowerCase() === "png" ||
                                                                  user.user_avatar_url.substring(user.user_avatar_url.lastIndexOf('.') + 1).toLowerCase() === "bmp" ||
                                                                  user.user_avatar_url.substring(user.user_avatar_url.lastIndexOf('.') + 1).toLowerCase() === "jpeg" ||
                                                                  user.user_avatar_url.substring(user.user_avatar_url.lastIndexOf('.') + 1).toLowerCase() === "jpg" ?
                                                                  <div className="avatar-container">
                                                                     <img src={user.user_avatar_url} className='avatar' alt='avatar' />
                                                                  </div>
                                                                  :
                                                                  <div className='noAvatar' style={{ backgroundColor: '#A61D17' }} >
                                                                     <ThumbnailLetters rounded text={user.name} className="m-auto" />
                                                                  </div>
                                                               }
                                                            </>
                                                         }

                                                         <div className='nameStatusContainer'>
                                                            <h2>{user.name}</h2>
                                                            <div className={user.status === 'Go Late' ? 'statusGoLate'
                                                               : user.status === 'Go Up Late' ? 'statusGoUpLate'
                                                                  : user.status === 'Go Target' ? 'statusGoTarget'
                                                                     : user.status === 'Go Top' ? 'statusGoTop'
                                                                        : user.status === 'Go Up Target' ? 'statusGoUpTarget'
                                                                           : user.status === 'Go Up Top' ? 'statusGoUpTop'
                                                                              : null}>
                                                               <span>{user.status && user.status.trim().split("Go")}</span>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div className="motivation-container">
                                                         <button className='send' onClick={() => handleOpenMotivationModal(user.user_id)}>
                                                            <FiSend size={15} color='#828282' />
                                                         </button>
                                                      </div>

                                                      <div className="configContainer">
                                                         <div className="dropdown">
                                                            <button className='dots' onClick={e => handleOpenUserOnTeamConfig(e, user.user_id, team.id, index)}>
                                                               <BsThreeDots />
                                                            </button>

                                                            {indexUserOnTeamConfig === index &&
                                                               <div ref={dropdownUserOnTeamConfig} className='menu-dots'>
                                                                  <button
                                                                     type='button'
                                                                     onClick={handleUserAdmin}
                                                                  >
                                                                     {userAdmin ?
                                                                        <span>{t('modal.userOnTeamConfig.removeAdm')}</span>
                                                                        :
                                                                        <span>{t('modal.userOnTeamConfig.giveAdm')}</span>
                                                                     }
                                                                  </button>

                                                                  <button
                                                                     type='button'
                                                                     onClick={usersOnTeamSelectedData === undefined || usersOnTeamSelectedData.length === 1 ? avoidDeleteUserAlone : handleDeleteUser}
                                                                  >
                                                                     {t('modal.userOnTeamConfig.removeUserOnTeam')}
                                                                  </button>
                                                               </div>
                                                            }
                                                         </div>

                                                         {user.admin &&
                                                            <div className='adminContainer'>
                                                               <div className='adminPill'>
                                                                  <span className='admin'>{t('team.adm')}</span>
                                                               </div>
                                                            </div>
                                                         }
                                                      </div>
                                                   </div>
                                                )
                                             })}
                                          </>
                                       </AccordionCollapse>
                                    </div>
                                 )
                              })
                              }
                           </Accordion>
                        }

                     </div>
                  </main>

                  <ModalAddTeams
                     isOpen={modalAddTeams}
                     toggle={handleOpenModalAddTeams}
                     teamName={teamName}
                     setTeamName={setTeamName}
                     email={email}
                     setEmail={setEmail}
                     teamId={teamId}
                     handleAddTeams={handleAddTeams}
                     usersToAdd={usersToAdd}
                     handleAddUsers={handleAddUsers}
                     addUsers={addUsers}
                     removeUsers={removeUsers}
                     loadingButton={loadingButton}
                  />

                  <ModalAddUsers
                     isOpen={modalAddUsers}
                     toggle={handleOpenModalAddUsers}
                     usersToAdd={usersToAdd}
                     handleAddUsers={handleAddUsers}
                     addUsers={addUsers}
                     removeUsers={removeUsers}
                     email={email}
                     setEmail={setEmail}
                     loadingButton={loadingButton}
                  />

                  <ModalEditTeamName
                     isOpen={modalEditTeamName}
                     toggle={handleOpenModalEditTeamName}
                     teamId={teamId}
                     teamName={teamName}
                     setTeamName={setTeamName}
                     handleTeamNameEdit={handleTeamNameEdit}
                     loadingButton={loadingButton}
                  />

                  <ModalSendMotivation
                     isOpen={modalMotivationIsOpen}
                     toggle={handleOpenMotivationModal}
                     personalizedIncentive={personalizedIncentive}
                     setPersonalizedIncentive={setPersonalizedIncentive}
                     handleToSendMotivation={handleToSendMotivation}
                     isPersonalizedIncentive={isPersonalizedIncentive}
                     handlePersonalizedIncentive={handlePersonalizedIncentive}
                     loadingButton={loadingButton}
                  />
               </>
            }
         </div>
      </div >
   )
}

export default Teams
