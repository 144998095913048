import React, { useState, useEffect, useCallback, useRef } from 'react'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'
import Switch from "react-switch"
import { Modal, ModalBody } from "reactstrap"
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import Loading from '../Loading'
import ModalSaveConfirmation from './ModalSaveConfirmation'

import api from '../../services/api'
import { FirstLetterUp } from '../../utils/functions'

import '../../styles/components/modal/ModalUserDetails.css'


const ModalUserDetails = (props) => {
   const [loading, setLoading] = useState(true)

   const [modalSaveConfirmation, setModalSaveConfirmation] = useState(false)
   const [hadChanges, setHadChanges] = useState(false)

   const [userName, setUserName] = useState('')
   const [userEmail, setUserEmail] = useState('')
   const [userCellphone, setUserCellphone] = useState('')
   const [userPlatform, setUserPlatform] = useState('')
   const [userMotivationPermission, setUserMotivationPermission] = useState(null)
   const [userAdmin, setUserAdmin] = useState(null)
   const [userPasswordValidated, setUserPasswordValidated] = useState(null)
   const [userBlocked, setUserBlocked] = useState(null)
   const [userCreatedAt, setUserCreatedAt] = useState('null')

   const user_id = props.userId

   const [t] = useTranslation()

   const outsideModalClickRef = useRef(null)

   const loadUserDetails = useCallback(async () => {

      if (!user_id) {
         setLoading(true)

         return false

      } else {

         try {
            const { data } = await api.get(`users/${user_id}`)

            setUserName(data.name)
            setUserEmail(data.email)
            setUserCellphone(data.cellphone)
            setUserPlatform(data.platform)
            setUserMotivationPermission(data.motivation_permission)
            setUserAdmin(data.admin)
            setUserPasswordValidated(data.password_valid)
            setUserBlocked(data.blocked)
            setUserCreatedAt(data.created_at)

            setLoading(false)

         } catch (e) {
            e.response.data.errors.forEach(error => toast.error(error))
         }
      }

   }, [user_id])

   useEffect(() => {
      loadUserDetails()

   }, [loadUserDetails])

   async function handleUpdateUserInfo(e) {
      e.preventDefault()

      const data = {
         name: userName,
         cellphone: userCellphone,
         admin: userAdmin,
         blocked: userBlocked,
         passwordValid: userPasswordValidated,
         motivationPermission: userMotivationPermission
      }

      try {
         await api.put(`users/admin/${user_id}`, data)

         props.setPageUsers(1)
         props.setLoadingMorePages(true)
         setHadChanges(false)

         if (modalSaveConfirmation) {
            handleEventToOpenModalConfirmSave()
         }

         props.toggle()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   async function handleUserBlocked(e) {
      e.preventDefault()

      try {
         await api.delete(`users/admin/${user_id}`)

         props.setPageUsers(1)
         props.setLoadingMorePages(true)
         setHadChanges(false)

         props.toggle()

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const handleEventToOpenModalConfirmSave = useCallback(() => {
      setModalSaveConfirmation(!modalSaveConfirmation)
   }, [modalSaveConfirmation])

   function handleOpenModalConfirmIfSaveOrNot() {
      if (modalSaveConfirmation) {
         handleEventToOpenModalConfirmSave()
      }

      props.toggle()
      setHadChanges(false)
   }

   useEffect(() => {
      const handleOutsideClick = (event) => {
         if (!outsideModalClickRef.current.contains(event.target)) {
            handleEventToOpenModalConfirmSave()
         }
      }

      if (props.isOpen && hadChanges) {
         document.addEventListener('mousedown', handleOutsideClick)
      }

      return () => {
         document.removeEventListener('mousedown', handleOutsideClick)
      }

   }, [props.isOpen, hadChanges, handleEventToOpenModalConfirmSave])

   function toggleSwitchMotivation() {
      setUserMotivationPermission(!userMotivationPermission)
      setHadChanges(true)
   }

   function toggleSwitchAdmin() {
      setUserAdmin(!userAdmin)
      setHadChanges(true)
   }

   function toggleSwitchPasswordValidated() {
      setUserPasswordValidated(!userPasswordValidated)
      setHadChanges(true)
   }

   function toggleSwitchBlocked() {
      setUserBlocked(!userBlocked)
      setHadChanges(true)
   }

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modal-users-details'
         centered
         backdrop={hadChanges ? 'static' : true}
         size='lg'
      >
         <div ref={outsideModalClickRef}>
            <ModalBody className="modal-body">
               {loading ?
                  <Loading
                     type='balls'
                  />
                  :
                  <div className='container'>
                     <main>
                        <h1>{t('userDetails.title')}</h1>

                        <div className="info-container">
                           <label htmlFor="name">{t('userDetails.name')}</label>
                           <input
                              id='userName'
                              name='userName'
                              value={FirstLetterUp(userName)}
                              onChange={e => {
                                 setUserName(e.target.value)
                                 setHadChanges(true)
                              }}
                           />
                        </div>

                        <div className="info-container">
                           <label htmlFor="email">{t('userDetails.email')}</label>
                           <input
                              id='userEmail'
                              name='userEmail'
                              value={userEmail}
                              readOnly
                           />
                        </div>

                        <div className="info-container">
                           <label htmlFor="cellphone">{t('userDetails.cellphone')}</label>
                           <NumberFormat
                              id='userCellphone'
                              name='userCellphone'
                              type='text'
                              placeholder={t('userDetails.cellphonePlaceholder')}
                              format='(##) # ####-####'
                              value={userCellphone}
                              onChange={e => {
                                 setUserCellphone(e.target.value)
                                 setHadChanges(true)
                              }}
                           />
                        </div>

                        <div className="info-readonly-container">
                           <label htmlFor="platform">{t('userDetails.platform')}</label>
                           <span>{userPlatform}</span>
                        </div>

                        <div className="info-readonly-container">
                           <label htmlFor="createdAt">{t('userDetails.createdAt')}</label>
                           <span>{moment(userCreatedAt).format('DD-MMM-YYYY')}</span>
                        </div>

                        <div className="switch-container">
                           <div>
                              <label htmlFor="motivationPermission">{t('userDetails.motivationPermission')}</label>
                              {userMotivationPermission ?
                                 <span>{t('common.yes')}</span>
                                 :
                                 <span>{t('common.no')}</span>
                              }
                           </div>
                           <Switch
                              checked={userMotivationPermission}
                              onChange={toggleSwitchMotivation}
                              offColor='#B1B1B1'
                              onColor='#6B2722'
                              offHandleColor='#6B2722'
                              onHandleColor='#B1B1B1'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={16}
                              width={39}
                           />
                        </div>

                        <div className="switch-container">
                           <div>
                              <label htmlFor="admin">{t('userDetails.admin')}</label>
                              {userAdmin ?
                                 <span>{t('common.yes')}</span>
                                 :
                                 <span>{t('common.no')}</span>
                              }
                           </div>
                           <Switch
                              checked={userAdmin}
                              onChange={toggleSwitchAdmin}
                              offColor='#B1B1B1'
                              onColor='#6B2722'
                              offHandleColor='#6B2722'
                              onHandleColor='#B1B1B1'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={16}
                              width={39}
                           />
                        </div>

                        <div className="switch-container">
                           <div>
                              <label htmlFor="admin">{t('userDetails.passwordValidated')}</label>
                              {userPasswordValidated ?
                                 <span>{t('common.yes')}</span>
                                 :
                                 <span>{t('common.no')}</span>
                              }
                           </div>
                           <Switch
                              checked={userPasswordValidated}
                              onChange={toggleSwitchPasswordValidated}
                              offColor='#B1B1B1'
                              onColor='#6B2722'
                              offHandleColor='#6B2722'
                              onHandleColor='#B1B1B1'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={16}
                              width={39}
                           />
                        </div>


                        <div className="switch-container">
                           <div>
                              <label htmlFor="admin">{t('userDetails.userBlocked')}</label>
                              {userBlocked ?
                                 <span>{t('common.yes')}</span>
                                 :
                                 <span>{t('common.no')}</span>
                              }
                           </div>
                           <Switch
                              checked={userBlocked}
                              onChange={toggleSwitchBlocked}
                              offColor='#B1B1B1'
                              onColor='#6B2722'
                              offHandleColor='#6B2722'
                              onHandleColor='#B1B1B1'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={16}
                              width={39}
                           />
                        </div>
                     </main>

                     <footer>
                        <button
                           onClick={handleUpdateUserInfo}
                           type='button'
                           className='save-update-button'
                        >
                           {t('common.save')}
                        </button>

                        {!userBlocked &&
                           <button
                              onClick={handleUserBlocked}
                              type='button'
                              className='block-button'
                           >
                              {t('userDetails.userBlockedButton')}
                           </button>
                        }
                     </footer>
                  </div>
               }

            </ModalBody>

            <ModalSaveConfirmation
               isOpen={modalSaveConfirmation}
               handleOpenModalConfirmIfSaveOrNot={handleOpenModalConfirmIfSaveOrNot}
               handleClickOnSaveButton={handleUpdateUserInfo}
            />
         </div>
      </Modal>
   )
}

export default ModalUserDetails
