import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { I18nextProvider } from "react-i18next"

import i18n from './language/i18n'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import "react-datepicker/dist/react-datepicker.css"

ReactDOM.render(
   <React.StrictMode>
      <I18nextProvider i18n={i18n}>
         <App />
      </I18nextProvider>
   </React.StrictMode>,
   document.getElementById('root')
);
