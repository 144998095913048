import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GoReply } from 'react-icons/go'

import logo from '../assets/logo.svg'

import '../styles/components/PageHeaderAdmin.css'

const PageHeaderAdmin = () => {

   const history = useHistory()
   const [t] = useTranslation()

   function handleGoBack() {
      history.goBack()
   }

   return (
      <header id="page-header-admin">
         <div className="top-bar-container">

            <Link to='/app/myTasks'>
               <img className='logo' src={logo} alt="GoGoGo" />
            </Link>


            <div className="top-bar-right">
               <div className="link-container">
                  <div className='link-selected'>
                     <a
                        className="documentation"
                        href={`${process.env.REACT_APP_API_URL}/api-docs`}
                        rel="noopener noreferrer"
                        target='_blank'
                     >
                        {t('adminPage.documentation')}
                     </a>
                  </div>
               </div>

               <button onClick={handleGoBack} className='button-back'>
                  <GoReply size={25} color='#FFF' />
               </button>

            </div>
         </div>
      </header>
   )
}

export default PageHeaderAdmin
