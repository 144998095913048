import React, { useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { GoogleLogin } from 'react-google-login'
// import FacebookLogin from 'react-facebook-auth'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

// import { FaFacebook } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'

import Input from '../../components/Input'
import FlagsLanguage from '../../components/FlagsLanguage'
import { useAuth } from '../../services/contexts/auth'

import logo from '../../assets/logo.svg'

import '../../styles/pages/auth/Login.css'

const Login = () => {
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')

   const {
      loadingButton,
      onSignin,
      signInWithGoogle,
      //  signInWithFacebook
   } = useAuth()

   const [t] = useTranslation()

   function useQuery() {
      return new URLSearchParams(useLocation().search)
   }

   const query = useQuery()
   const password_valid = query.get("password_valid")
   const { token } = useParams()

   function handleSignIn(e) {
      e.preventDefault()

      onSignin(email, password, token, password_valid)

      if (password_valid !== undefined && token !== undefined) {
         if (isMobile) {
            return toast.warning(t('login.warningMobileOnWeb'))
         }
      }
   }

   const GoogleAuth = (response) => {
      signInWithGoogle(response)
   }

   const GoogleAuthFailure = (response) => {
      signInWithGoogle(response)
   }

   // const FacebookAuth = (response) => {
   //    signInWithFacebook(response);
   // };


   // const FacebookButton = ({ onClick }) => (
   //    <button onClick={onClick} className="facebookButton">
   //       <FaFacebook color="#1877F2" size={29} />
   //       <span className='align-center'>
   //          {t('common.facebookButton')}
   //       </span>
   //    </button>
   // );

   return (
      <div id="page-login">

         <div className="image-logo">
            <img src={logo} alt="GoGoGo" />
            <span className="enjoy">Enjoy, Living & Flying !</span>
         </div>

         <div className="flags-container">
            <FlagsLanguage />
         </div>

         <div id="page-login-content">
            <div className="content">

               <h1>{t('login.title')}</h1>
               <h4>{t('login.subTitle')}</h4>

               <main>
                  <form onSubmit={handleSignIn}>
                     <fieldset>
                        <Input
                           name='email'
                           label={t('common.emailLabel')}
                           type='email'
                           placeholder={t('common.emailPlaceholder')}
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                        />

                        <Input
                           name='password'
                           label={t('common.passwordLabel')}
                           type='password'
                           placeholder={t('common.passwordPlaceholder')}
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                        />

                        {loadingButton === 'default' ?
                           <button
                              type='submit'
                              className='enter'
                           >
                              {t('common.enterButton')}
                           </button>
                           :
                           <button
                              className='enter'
                              style={{ background: '#6B2722', color: '#FFF', border: 0 }}
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        }
                     </fieldset>
                  </form>


                  {/* <FacebookLogin
                     appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                     callback={FacebookAuth}
                     component={FacebookButton}
                  /> */}

                  <GoogleLogin
                     clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                     render={renderProps => (
                        <button
                           className="googleButton"
                           onClick={renderProps.onClick}
                           disabled={renderProps.disabled}
                        >
                           <FcGoogle size={29} />
                           {t('common.googleButton')}
                        </button>
                     )}
                     buttonText={t('common.googleButton')}
                     onSuccess={GoogleAuth}
                     onFailure={GoogleAuthFailure}
                     cookiePolicy={'single_host_origin'}
                     isSignIn={true}
                  />

                  <div className="footer">
                     <Link to='/forgotPassword'>{t('login.forgetPasswordAccess')}</Link>
                     <span>{t('login.registerAcess.part1')} <Link to='/signup'><strong>{t('login.registerAcess.part2')}</strong></Link>.</span>
                  </div>
               </main>

            </div>
         </div>
      </div>
   )
}

export default Login
