import React, { useState, useEffect } from 'react'
// import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { HiOutlineChat } from 'react-icons/hi'

import api from '../services/api'
import ThumbnailLetters from './ThumbnailLetters'
import { useAuth } from '../services/contexts/auth'
// import { socketIo } from '../services/socket'
import { notificationStorage } from '../utils/defaultValues'

import logo from '../assets/logo.svg'
// import audio from '../assets/notification.mp3'

import '../styles/components/PageHeader.css'


const PageHeader = ({ setNotificationsData, focusIsStarted, minutes, seconds, timeBar }) => {
   const [notificationReaded, setNotificationReaded] = useState(localStorage.getItem(notificationStorage))

   const [linkClicked, setLinkClicked] = useState('')
   const [changeColorNotification, setChangeColorNotification] = useState(notificationReaded)

   const [idUser, setIdUser] = useState()
   const [userName, setUserName] = useState()

   const [avatar, setAvatar] = useState(null)
   const [checkAvatarNull, setCheckAvatarNull] = useState(null)

   // const [sendNotification, setSendNotification] = useState(true)
   // const [socketIsConnected, setSocketIsConnected] = useState(false)

   const { userColor, userId, idStorage } = useAuth()

   const [t] = useTranslation()

   const history = useHistory()

   const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('')
   const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('')

   // const socket = socketIo(idUser)

   // socket.connect()

   // useEffect(() => {
   //    socket.on('connect', () => {
   //       setSocketIsConnected(socket.connected)
   //    })

   //    socket.on('disconnect', (reason) => {
   //       setSocketIsConnected(socket.connected)
   //       if (reason === "io server disconnect") {
   //          // the disconnection was initiated by the server, you need to reconnect manually
   //          socket.connect();
   //       }
   //    })
   // }, [socket])

   useEffect(() => {
      Notification.requestPermission()

   }, [])

   useEffect(() => {
      if (userId) {
         setIdUser(userId)

      } else {
         setIdUser(idStorage)
      }

   }, [userId, idStorage])


   // useEffect(() => {
   //    const handleNewNotification = async (newNotification) => {

   //       setChangeColorNotification('true')
   //       setNotificationReaded('true')
   //       localStorage.setItem(notificationStorage, 'true')

   //       if (history.location.pathname === '/app/notifications') {
   //          const res = await api.get('notificationsReceived')

   //          setNotificationsData(res.data)
   //       }

   //       new Audio(audio).play()
   //       toast.warning(`${newNotification.from.split(' ')[0]} ${t('notification.sendMessage')}`)
   //    }

   //    socketIsConnected && socket.on('notification', handleNewNotification)

   //    // return () => { socket.off('notification', handleNewNotification) }

   // }, [socket, t, setNotificationsData, history.location.pathname, socketIsConnected])

   // useEffect(() => {

   //    const handleNewStatus = newStatus => {

   //       if (newStatus.permission && sendNotification) {
   //          new Audio(audio).play()
   //          toast.warning(newStatus.phrase)

   //          setSendNotification(false)
   //       }
   //    }

   //    socketIsConnected && socket.on('dailyStatus', handleNewStatus)

   // }, [socket, sendNotification, socketIsConnected])

   useEffect(() => {
      if (idUser) {
         async function loadUser() {
            const res = await api.get(`users/${idUser}`)

            setUserName(res.data.name)
            setAvatar(res.data.user_avatar_url)
            setCheckAvatarNull(res.data.avatar)
         }

         loadUser()
      }

   }, [idUser])

   useEffect(() => {
      setLinkClicked(history.location.pathname)

   }, [history.location.pathname])


   return (
      <header
         id="page-header"
         style={focusIsStarted ? { backgroundColor: '#6B2722' } : { backgroundColor: '#262626' }}
      >
         <div className="top-bar-container">

            <div className="logo-and-focus-container">
               <Link to='/app/myTasks'>
                  <img className='logo' src={logo} alt="GoGoGo" />
               </Link>

               {focusIsStarted && (
                  <div className="focus-actived-container">
                     <div className="clock-container">
                        <span>{minuteLeft}</span>
                        <span>{minuteRight}</span>
                        <span>:</span>
                        <span>{secondLeft}</span>
                        <span>{secondRight}</span>
                     </div>

                     <div className="progress-container">
                        <div className="progress" style={{ width: `${timeBar}%` }}></div>
                     </div>
                  </div>
               )}
            </div>

            {focusIsStarted ? (
               <div className="top-bar-right-on-focus">
                  <div className="link-container">
                     <Link
                        to='/app/myTasks'
                        className={linkClicked === '/app/myTasks' ? 'link-selected' : 'link'}
                     >
                        {t('header.tasks')}
                     </Link>

                     <div className={linkClicked === '/app/myTasks' ? 'line-selected' : 'line'}></div>
                  </div>

                  <Link to='/app/config' style={{ textDecoration: 'none' }}>
                     <div style={{ backgroundColor: userColor }} className="avatar">
                        {checkAvatarNull === null || checkAvatarNull === undefined
                           ?
                           <ThumbnailLetters rounded text={userName} className="m-auto" />
                           :
                           <>
                              {avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === 'gif' ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "png" ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "bmp" ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "jpeg" ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "jpg"
                                 ?
                                 <img src={avatar} alt="Avatar" />
                                 :
                                 <ThumbnailLetters rounded text={userName} className="m-auto" />
                              }
                           </>
                        }
                     </div>
                  </Link>
               </div>
            ) : (
               <div className="top-bar-right">
                  <div className="link-container">
                     <Link
                        to='/app/myTasks'
                        className={linkClicked === '/app/myTasks' ? 'link-selected' : 'link'}
                     >
                        {t('header.tasks')}
                     </Link>

                     <div className={linkClicked === '/app/myTasks' ? 'line-selected' : 'line'}></div>
                  </div>

                  <div className="link-container" >
                     <Link
                        to='/app/myTeams'
                        className={linkClicked === '/app/myTeams' ? 'link-selected' : 'link'}
                     >
                        {t('header.teams')}
                     </Link>
                     <div className={linkClicked === '/app/myTeams' ? 'line-selected' : 'line'}></div>
                  </div>

                  <div className="link-container">
                     <Link
                        to='/app/myFutureNotes'
                        className={linkClicked === '/app/myFutureNotes' ? 'link-selected' : 'link'}
                     >
                        {t('header.futureNotes')}
                     </Link>
                     <div className={linkClicked === '/app/myFutureNotes' ? 'line-selected' : 'line'}></div>
                  </div>

                  <div className="link-container">
                     <Link
                        to='/app/myDashboard'
                        className={linkClicked === '/app/myDashboard' ? 'link-selected' : 'link'}
                     >
                        {t('header.dashboard')}
                     </Link>
                     <div className={linkClicked === '/app/myDashboard' ? 'line-selected' : 'line'}></div>
                  </div>

                  <Link
                     to='/app/notifications'
                     className='notification'
                     onClick={
                        focusIsStarted ?
                           (event) => event.preventDefault()
                           :
                           () => {
                              setChangeColorNotification('false')
                              setNotificationReaded('false')
                              localStorage.setItem(notificationStorage, 'false')
                           }}>
                     <HiOutlineChat color={changeColorNotification === 'true' ? '#E62631' : '#FFF'} />
                  </Link>

                  <Link to='/app/config' style={{ textDecoration: 'none' }}>
                     <div style={{ backgroundColor: userColor }} className="avatar">
                        {checkAvatarNull === null || checkAvatarNull === undefined
                           ?
                           <ThumbnailLetters rounded text={userName} className="m-auto" />
                           :
                           <>
                              {avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === 'gif' ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "png" ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "bmp" ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "jpeg" ||
                                 avatar.substring(avatar.lastIndexOf('.') + 1).toLowerCase() === "jpg"
                                 ?
                                 <img src={avatar} alt="Avatar" />
                                 :
                                 <ThumbnailLetters rounded text={userName} className="m-auto" />
                              }
                           </>
                        }
                     </div>
                  </Link>
               </div>
            )}
         </div >
      </header >
   )
}

export default PageHeader
